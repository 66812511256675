import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { BoonjiMain } from './BoonjiMain/BoonjiMain'
import { BoonjiTraitLine } from './BoonjiTraitLine/BoonjiTraitLine'

import './BoonjiCard.css'

export const BoonjiCard = props => {
    const {boonji} = props;
  return (
    <div className='boonjiCard'>
        <Grid
            container
            direction="column"
            justifyContent="center"
            paddingBottom={2}
        >
            <Grid item>
                <BoonjiMain boonji={boonji}/>
            </Grid>
            <Grid item>
                <div>
                    {boonji.name}
                </div>
            </Grid>
            <Grid item>
                <BoonjiTraitLine boonji={boonji}/>
            </Grid>
        </Grid>
    </div>
  )
}


BoonjiCard.propTypes = {
    boonji: PropTypes.object.isRequired
}
