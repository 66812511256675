import React from "react";

import './SocialLogos.css'

const SocialLogos = props=>{

    
    return(
        <div className="logos-container">          

            <a href="https://discord.com/invite/boonjiproject" target="_blank" rel="noreferrer"> 
                <img src="/images/Discord.png" alt="" />
            </a>
            <a href="https://twitter.com/BoonjiProject" target="_blank" rel="noreferrer"> 
                <img src="/images/Vector-1.png" alt="" />
            </a>
            <a href="https://opensea.io/collection/boonjiproject" target="_blank" rel="noreferrer"> 
                <img src="/images/opensea.png" alt="" />
            </a>
            <a href="https://niftygateway.com/marketplace/collectible/0x4cd0ea8b1bdb5ab9249d96ccf3d8a0d3ada2bc76" target="_blank" rel="noreferrer"> 
                <img src="/images/nifty-gateway.svg" alt="" />
            </a>

        </div>
    )

}

export default SocialLogos;


/* 

import React, { useState } from "react";
import BasicModal from "./Modals/BasicModal";

import './SocialLogos.css'

const SocialLogos = props=>{

    const [openBasicModal,  setOpenBasicModal ] = useState(false);
    const [modalType,  setModalType ] = useState("");

    const toogleBasicModal = (type)=>{
        setModalType(type);
        setOpenBasicModal(!openBasicModal);
    }
    
    return(
        <div className="logos-container">          

            <a href="https://discord.com/invite/boonjiproject" target="_blank" rel="noreferrer"> 
                <img src="/images/Discord.png" alt="" />
            </a>
            <a href="https://twitter.com/BoonjiProject" target="_blank" rel="noreferrer"> 
                <img src="/images/Vector-1.png" alt="" />
            </a>
            <a href="https://opensea.io/collection/boonjiproject" target="_blank" rel="noreferrer"> 
                <img src="/images/opensea.png" alt="" />
            </a>
            <a href="https://niftygateway.com/marketplace/collectible/0x4cd0ea8b1bdb5ab9249d96ccf3d8a0d3ada2bc76" target="_blank" rel="noreferrer"> 
                <img src="/images/nifty-gateway.svg" alt="" />
            </a>

            <span onClick={()=>{toogleBasicModal("connect")}}>M1</span> 
            <span onClick={()=>{toogleBasicModal("notify")}}>M2</span> 
            <span onClick={()=>{toogleBasicModal("mint")}}>M3</span> 
            <span onClick={()=>{toogleBasicModal("problem")}}>M4</span>  
            <span onClick={()=>{toogleBasicModal("network")}}>M5</span>              
                
            {openBasicModal && <BasicModal closeModal={toogleBasicModal} type={modalType}/>}    
            
        </div>
    )

}

export default SocialLogos; 

*/