import React from "react";
import LinkBtn from "../../../components/Buttons/LinkBtn";

import './Brendan.css';

const Brendan = props =>{
    return(
        <section className="brendan-section">
            <div className="brendan-img-container">
                <img src="./images/mv2-p-500.jpeg" alt="" />
            </div>
            <div className="brendan-info-container">
                <h2>Brendan Murphy</h2>
                <p>Brendan Murphy (B 1971 - ) is a widely known contemporary artist whose works are found in prominent private collections and galleries around the world. Since his early career, Brendan has blended abstract and figurative forms to reveal meaning with deeper contemplations through his sculpture and painting. Brendan believes it is in art’s potential where we can most universally transmit positive energy. He recognizes the effect this energy, when experienced as a collective, can have on society as a whole. His commitment to process and true craftsmanship is the unifying thread throughout his body of work. <br/>​<br/>
                    Brendan currently works from his home-based studios in Malibu, CA and Miami, FL. His work is represented in over 600 private collections and his new works are commonly featured at Art Fairs around the world through his gallery representation in the USA, Canada, Germany, Colombia, England and Monaco.<br/><br/>
                    The sculptural work of Brendan Murphy communicates a rare perspective on the modern human experience through explorations of form, color, language, and universal symbols. Thematically, Brendan Murphy explores the interplay of beauty, power and the need to understand the source of all the energies of life.<br/><br/>
                    The inspirations of the work; formulas, equations, relationships; create a pictorial spectrum of human emotion and the unifying natures of shared humanity. Through the layering of these emblems, Brendan Murphy’s painted works boldly portray careful imagery and density of the human experience, exposing the deep natural beauty that surface in this fragile balance.<br/><br/>
                </p>
                <div className="brendan-links">
                    <LinkBtn title="Learn More" link="https://www.brendanmurphyart.com/"></LinkBtn>
                </div>
            </div>
        </section>
    )
}

export default Brendan;
