import React from "react";
import PropTypes from 'prop-types'

import './PurpleBtnRectClick.css'

export const PurpleBtnRectClick = props=>{
    const {clickFunc, text, disabled} = props;
    const doNothing = () => {
        // is disabled
    }
    return(
        <dir className={(disabled) ? 'purple-btn-rect-click-disabled' : 'purple-btn-rect-click'} onClick={(disabled) ? doNothing : clickFunc}>
            {text}
        </dir>
            
    )
}

PurpleBtnRectClick.propTypes = {
    clickFunc: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired
}
