import {uiTypes} from './uiTypes';

const initialState = {
    alert: {
        open: false,
        severity: 'success',
        message: '',
        title: ''
    },
    backdrop: false,
    showMaxPreview: false,
    showConfirmation: false,
    showConfirmation2: false,
    showConfirmation3: false,
    inProgress: {
        show: false,
        title: '',
        message: ''
    }
};


export const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        
        // shows the alert message
        case uiTypes.uiOpenAlert:
            return ({
                ...state,
                alert: {open: true, severity: action.payload.severity, title: action.payload.title, message: action.payload.message}
            })

        // closes the alert message
        case uiTypes.uiCloseAlert:
            return ({
                ...state,
                alert: {...state.alert, open: !state.alert.open}
            })
        case uiTypes.uiMaxPreviewOpen:
            return {
                ...state,
                showMaxPreview: true
            }

        case uiTypes.uiMaxPreviewClose:
            return {
                ...state,
                showMaxPreview: false
            }

        case uiTypes.uiConfirmationOpen:
            return {
                ...state,
                showConfirmation: true
            }

        case uiTypes.uiConfirmationClose:
            return {
                ...state,
                showConfirmation: false
            }

        case uiTypes.uiConfirmation2Open:
            return {
                ...state,
                showConfirmation2: true
            }

        case uiTypes.uiConfirmation2Close:
            return {
                ...state,
                showConfirmation2: false
            }
           
        case uiTypes.uiConfirmation3Open:
                return {
                    ...state,
                    showConfirmation3: true
                }
    
        case uiTypes.uiConfirmation3Close:
                return {
                    ...state,
                    showConfirmation3: false
                }
        case uiTypes.uiInProgressOpen:
            return {
                ...state,
                inProgress:{
                    show: true,
                    title: action.payload.title,
                    message: action.payload.message
                }
            }

        case uiTypes.uiInProgressClose:
            return {
                ...state,
                inProgress:{
                    show: false,
                    title: '',
                    message: ''
                }
            }

        case uiTypes.uiOpenBackdrop:
            return {
                ...state,
                backdrop: true
            }

        case uiTypes.uiCloseBackdrop:
            return {
                ...state,
                backdrop: false
            }
    
    
        default:
            return state;
    }
}