import React from 'react'
import PropTypes from 'prop-types'

import './PreviewMobileBoonji.css';
export const PreviewMobileBoonji = props => {
    const {selected} = props;

  return (
    <div className='previewMobile-boonji'>
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" fill={(selected) ? "violet" : "gray"}/>
        </svg>
    </div>
  )
}

PreviewMobileBoonji.propTypes = {
    selected: PropTypes.bool.isRequired
}
