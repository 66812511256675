import React from 'react'
import { useSelector } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Grid, CircularProgress } from '@mui/material';

import './InProgress.css'

export const InProgress = props => {
    const {inProgress} = useSelector(state => state.ui);
    const {show, title, message} = inProgress;
    
    const handleOnClose = () => {
        // no actions, can't close it
    }

  return (
    <div>
        <Dialog
            open={show}
            onClose={handleOnClose}
        >
            <DialogContent className='inProgress'>
                <Grid 
                    container
                    direction="column"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>
                    <div className='inProgress-title'>
                        {title}
                    </div>
                    </Grid>
                    <Grid item>
                        <CircularProgress color='secondary'/>
                    </Grid>
                    <Grid item className='inProgress-message'>
                        {message}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    </div>
  )
}