import {combineReducers} from 'redux';

import {uiReducer} from './uiReducer/uiReducer';
import {authReducer} from './authReducer/authReducer';
import {walletProviderReducer} from './walletProviderReducer/walletProviderReducer';
import {boonjiStudioReducer} from './boonjiStudioReducer/boonjiStudioReducer';
import { fifthTraitReducer } from './fifthTrait/fifthTraitReducer';
import { redemptionReducer } from './redemptionReducer/redemptionReducer';


export const rootReducer = combineReducers({
    ui: uiReducer,
    auth: authReducer,
    walletProvider: walletProviderReducer,
    boonjiStudio: boonjiStudioReducer,
    fifthTrait: fifthTraitReducer,
    redemption: redemptionReducer

})
