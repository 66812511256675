import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types'
import { addBoonjiToBurn, removeBoonjiToBurn } from '../../../reducers/boonjiStudioReducer/boonjiStudioActions';

require('./BoonjiMain.css')

export const BoonjiMain = props => {
  const {boonji} = props;
  const {tokenId} = boonji;
  const {image, name} = boonji;

  const {preview} = useSelector(state => state.boonjiStudio);
  const {boonjies, body, ear, visor, background} = preview;

  const [selected, setSelected] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (boonjies.find(val => val.tokenId == tokenId))
      setSelected(true);
    else
      setSelected(false)
  }, [boonjies, body, ear, visor, background])

  const handleBoonjiClick = () => {
    if (!selected){
      // we will allow adding this boonji only if there is space to add them
      if (boonjies.length < 4 && body && ear && visor && background){
        setSelected(true);
        dispatch(addBoonjiToBurn(boonji));
        }
    } else {
      // we allow removing this boonji only if no traits are using it
      if (tokenId === body?.tokenId || tokenId === visor?.tokenId || tokenId === ear?.tokenId || tokenId === background?.tokenId){
        // can't allow it
      } else {
        setSelected(false);
        dispatch (removeBoonjiToBurn(boonji))
      }
    }
  }
  return (
    <div className='boonji-main' onClick={handleBoonjiClick}>
      {
        (!boonji.owned) ? (
          <div className='boonji-main-Opensea'>
            <a href={process.env.REACT_APP_OPENSEA_COLLECTION_TOKEN+boonji.tokenId} target='_blank'>Buy on OpenSea</a> 
          </div>
        ) : (
          <div></div>
        )
      }
      <img src={image} alt={name} className={(selected) ? ((boonji.owned) ? 'boonji-main-img-selected' : 'boonji-main-img-selected-notOwned') : 'boonji-main-img'}></img>
          <p>{name}</p>
    </div>
  )
}

BoonjiMain.propTypes = {
  boonji: PropTypes.object.isRequired
}
