import { boonjiStudioTypes } from "./boonjiStudioTypes";

const initialState = {
    owned: [],
    notOwned: [],
    preview: {
        boonjies: [],
        body: null,
        visor: null,
        ear: null,
        background: null,
        fifthTrait: null
    }
}

export const boonjiStudioReducer = (state = initialState, action) => {
    switch(action.type) {

        case (boonjiStudioTypes.setOwnedBoonjies):
            return {
                ...state,
                owned: action.payload,
                // we remove from the notOwned any boonji we got from the owned list
                // notOwned: [...state.notOwned, state.notOwned.filter(val => !action.payload.includes(val))]
            }
        
        case (boonjiStudioTypes.addNotOwnedBoonji):
            return {
                ...state,
                // we only add a not owned, if it doesn't exists in the owned ones already
                notOwned: [...state.notOwned, action.payload]
            }
        case boonjiStudioTypes.selectBodyTrait:
            return {
                ...state,
                preview: {
                    ...state.preview,
                    body: action.payload
                }
            }
        case boonjiStudioTypes.selectVisorTrait:
            return {
                ...state,
                preview: {
                    ...state.preview,
                    visor: action.payload
                }
            }
        case boonjiStudioTypes.selectEarTrait:
            return {
                ...state,
                preview: {
                    ...state.preview,
                    ear: action.payload
                }
            }
        case boonjiStudioTypes.selectBackgroundTrait:
            return {
                ...state,
                preview: {
                    ...state.preview,
                    background: action.payload
                }
            }
        
        case boonjiStudioTypes.selectFifthTrait:
            return {
                ...state,
                preview: {
                    ...state.preview,
                    fifthTrait: action.payload
                }
            }


        case boonjiStudioTypes.addBoonjiToBurn:
            return {
                ...state,
                preview: {
                    ...state.preview,
                    boonjies: [...state.preview.boonjies, action.payload]
                }
            }

        case boonjiStudioTypes.removeBoonjiToBurn:
            return {
                ...state,
                preview: {
                    ...state.preview,
                    boonjies: state.preview.boonjies.filter(val => val.tokenId !== action.payload.tokenId)
                }
            }
        
            case boonjiStudioTypes.saveBase64ImageURL:
                return {
                    ...state,
                    preview: {
                        ...state.preview,
                        url: action.payload
                    }
                }

        default:
            return state;
    }
}