import React from 'react'
import PropTypes from 'prop-types'
import './PreviewMobileTrait.css'
export const PreviewMobileTrait = props => {
    const {selected} = props;
  return (
    <div className='previewMobile-trait'>
        <svg  viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" fill={(selected) ? 'green' : 'gray'}/>
        </svg>
    </div>
  )
}

PreviewMobileTrait.propTypes = {
    selected: PropTypes.bool.isRequired
}
