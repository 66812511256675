import { Grid } from '@mui/material'
import React from 'react'
import { MaxPreview } from '../MaxPreview/MaxPreview'
import { PreviewMobileBoonjiLine } from './boonji/PreviewMobileBoonjiLine'
import { PreviewMobileImage } from './image/PreviewMobileImage'

import './PreviewMobile.css'
import { PreviewMobileTraitLine } from './trait/PreviewMobileTraitLine'

export const PreviewMobile = () => {
  return (
    <div className='previewMobile'>
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item>
                <div>
                    <PreviewMobileBoonjiLine/>
                </div>
            </Grid>

            <Grid item>
                <div>
                    <PreviewMobileTraitLine/>
                </div>
            </Grid>

            <Grid item>
                <div>
                    <PreviewMobileImage/>
                    <MaxPreview/>
                </div>
            </Grid>
        </Grid>
    </div>
  )
}
