import React, { useState } from 'react';
import Menu from './Menu';
import {shortenAddress} from '../../utils/blockchain'
import './Navbar.css';
import { useDispatch, useSelector } from 'react-redux';
import { disconnectProvider, showWalletProviderModal } from '../../reducers/walletProviderReducer/walletProviderActions';

const Navbar = props =>{
  const {address} = useSelector(state => state.walletProvider);
  const [click, setClick] = useState(false);
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    if (!address){
      // we connect
        dispatch(showWalletProviderModal())
    } else {
      // we disconnect
      dispatch(disconnectProvider())
    }
  }

  const handleClick = () => {
    setClick(!click);
  }

  const closeMobileMenu = (li) => {
    setClick(false);
  }

  const showSuperBoonji = (process.env.REACT_APP_SHOW_SUPERBOONJI_MENU === 'true' ? true : false)

  return (        
       <nav className='navbar'>            
        <div className='navbar-container'>
          <div className='menu-icon' onClick={handleClick}>
            <h2>OPEN MENU</h2>
            <img src="./images/hamburger.png" alt=""></img>
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>            
            <Menu title="Home" link="/" onClick= {closeMobileMenu}/>
            {(process.env.REACT_APP_REDEMPTION_ENABLED === 'true') && (
              <Menu title="Redemption of Physical Art" link="/redeem" onClick={closeMobileMenu}/>
            )}
            {/* <Menu title="News" link="https://news.boonjiproject.com/" onClick={closeMobileMenu}/>
            <Menu title="Journey Through Joa (Roadmap)" link="/Journey-Planets" onClick={closeMobileMenu}/> */}
            {(showSuperBoonji) ? (
              <Menu title="Boonji Studio" link="/boonjiStudio" onClick= {closeMobileMenu}/>
            ) : (
              <div></div>
            )}      
            <Menu title="Artist Bio" link="/brendan-murphy-biography" onClick={closeMobileMenu}/>
            <div className='connect-wallet-container'>
              <div className='connect-btn'>             
                  <button onClick={handleButtonClick}>
                  {
                    (address) ? (
                      <div>
                        {shortenAddress(address)}
                      </div>
                    ) : (
                      <div>
                        CONNECT WALLET
                      </div>
                    )
                  }  
                  </button>                              
               </div>              
            </div>            
          </ul>         
        </div>
      </nav>    
  );
}

export default Navbar;