import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid } from '@mui/material'


import { SelectedBoonjai } from './SelectedBoonjai/SelectedBoonjai';
import { SelectedTraits } from './SelectedTraits/SelectedTraits';
import { FifthTrait } from './FifthTrait/FifthTrait';
import { Preview } from './Preview/Preview';

import { MaxPreview } from './MaxPreview/MaxPreview';
import { uiShowAlert, uiShowconfirmation } from '../../reducers/uiReducer/uiActions';

import { reserveFifthTrait } from '../../reducers/fifthTrait/fifthTraitActions';

require ('./SuperBoonjiPreview.css')

export const SuperBoonjiPreview = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = useDispatch();

  const {preview} = useSelector (state => state.boonjiStudio);
  const background = preview.background?.attributes.find(val => val.trait_type === 'background')
  const ear = preview.ear?.attributes.find(val => val.trait_type === 'ear')
  const visor = preview.visor?.attributes.find(val => val.trait_type === 'visor')
  const body = preview.body?.attributes.find(val => val.trait_type === 'body')
  
  const isMintEnabled = (process.env.REACT_APP_ENABLE_MINT === 'true' ? true : false);
  
  const handleOnClickNext = async () => {
    try {
      await dispatch(reserveFifthTrait())
      await dispatch(uiShowconfirmation());
    } catch ( error ){
      await dispatch(uiShowAlert('warning', '5th Trait error', error.toString()));
    }
  }

  useEffect(() => {
    const {boonjies, body, visor, ear, background, fifthTrait} = preview;
    if (boonjies.length === 4 && !boonjies.find(boonji => !boonji.owned)&& body && visor && ear && background && fifthTrait)
      setIsDisabled(false);
    else
      setIsDisabled(true);
  }, [preview])
  

  return (
    <Box className='boonjiPreview'>
        <Grid
          container 
          direction="column"
          alignItems="center"
          justifyContent="center"
        > 
        <Grid >
          <div className='boonjiPreview-previewTitle'>
            Your Super Boonji
          </div>
        </Grid>
        <div className='boonjiPreview-background'>
          <Grid item>
            <Preview/>
          </Grid>

            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <div className='selectedBonjai-title'>
                    Selected Boonjai
                    <SelectedBoonjai/>          
                  </div>
                </Grid>

                <Grid item>
                  <div className='selectedTraits-title'>
                    Selected Traits: {((body) ? 1 : 0) + ((visor) ? 1 : 0) + ((ear) ? 1 : 0) + ((background) ? 1 : 0)}/4
                    <SelectedTraits readOnly={false}/>
                  </div>
                </Grid>
              </Grid>
            </Grid>


            <Grid item paddingBottom={1}>
              <FifthTrait readOnly={false}/>
            </Grid>
          </div>


          {(isMintEnabled) ? (
            <Button 
              onClick={handleOnClickNext} 
              variant="contained" 
              className={'Button'}
              style={{
                backgroundColor: "#DF7DA3",
                width: '100%'
            }}
              disabled={isDisabled} 
            >
                Create your Super Boonji
            </Button>
          ) : (
            <div></div>
          )}
        </Grid>
        <MaxPreview/>
    </Box>
  )
}
