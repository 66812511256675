import { fifthTraitTypes } from "./fifthTraitTypes";

const initialState = {
    devilAngels: [],
    halos: [],
    headphones: [],
    rocketMans: [],
    signatures: [],
    vulcanEars: [],
    necklaces: [],
    crowns: [],
    horns: [],
    words: [],
    fingerprints: [],
    spirals: [],
    ladies: [],
    walkingBoonjies : [],
    rocketBoonjies: [],
    puzzles: [],
    formulas: [],
    chains:[],
    // SB2
    background: [],
    body: [],
    helmet: [],
    snake: [],
    visor: [],
    wig: [],
    elephantEar: [],
    misc: [],
}

export const fifthTraitReducer = (state = initialState, action) => {
    switch (action.type) {
        
        // headphone
        case (fifthTraitTypes.addFifthTraitHeadphone): 
            return {
                ...state,
                headphones: [...state.headphones, action.payload]
            }
        
        case (fifthTraitTypes.updateFifthTraitHeadphone):
            return {
                ...state,
                headphones: [...state.headphones.map(val => (val.name === action.payload.name) ? action.payload : val)]
            }


        // halo
        case (fifthTraitTypes.addFifthTraitHalo): 
            return {
                ...state,
                halos: [...state.halos, action.payload]
            }

        case (fifthTraitTypes.updateFifthTraitHalo):
            return {
                ...state,
                halos: [...state.halos.map(val => (val.name === action.payload.name) ? action.payload : val)]
            }
    
        // devilAngels
        case (fifthTraitTypes.addFifthTraitDevilAngel): 
        return {
            ...state,
            devilAngels: [...state.devilAngels, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitDevilAngel):
        return {
            ...state,
            devilAngels: [...state.devilAngels.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // Signature
        case (fifthTraitTypes.addFifthTraitSignature): 
        return {
            ...state,
            signatures: [...state.signatures, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitSignature):
        return {
            ...state,
            signatures: [...state.signatures.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // rocketMan
        case (fifthTraitTypes.addFifthTraitRocketMan): 
        return {
            ...state,
            rocketMans: [...state.rocketMans, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitRocketMan):
        return {
            ...state,
            rocketMans: [...state.rocketMans.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        //  Vulcan ears
        case (fifthTraitTypes.addFifthTraitVulcanEar): 
        return {
            ...state,
            vulcanEars: [...state.vulcanEars, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitVulcanEar):
        return {
            ...state,
            vulcanEars: [...state.vulcanEars.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // necklace
        case (fifthTraitTypes.addFifthTraitNecklace): 
        return {
            ...state,
            necklaces: [...state.necklaces, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitNecklace):
        return {
            ...state,
            necklaces: [...state.necklaces.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // crowns
        case (fifthTraitTypes.addFifthTraitCrown): 
        return {
            ...state,
            crowns: [...state.crowns, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitCrown):
        return {
            ...state,
            crowns: [...state.crowns.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        
        // horns
        case (fifthTraitTypes.addFifthTraitHorn): 
        return {
            ...state,
            horns: [...state.horns, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitHorn):
        return {
            ...state,
            horns: [...state.horns.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // word
        case (fifthTraitTypes.addFifthTraitWord): 
        return {
            ...state,
            words: [...state.words, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitWord):
        return {
            ...state,
            words: [...state.words.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // fingerprint
        case (fifthTraitTypes.addFifthTraitFingerprint): 
        return {
            ...state,
            fingerprints: [...state.fingerprints, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitFingerprint):
        return {
            ...state,
            fingerprints: [...state.fingerprints.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // spiral
        case (fifthTraitTypes.addFifthTraitSpiral): 
        return {
            ...state,
            spirals: [...state.spirals, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitSpiral):
        return {
            ...state,
            dispiralssks: [...state.spirals.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // Lady
        case (fifthTraitTypes.addFifthTraitLady): 
        return {
            ...state,
            ladies: [...state.ladies, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitLady):
        return {
            ...state,
            ladies: [...state.ladies.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        
        // walkingBoonji
        case (fifthTraitTypes.addFifthTraitWalkingBoonji): 
        return {
            ...state,
            walkingBoonjies: [...state.walkingBoonjies, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitWalkingBoonji):
        return {
            ...state,
            walkingBoonjies: [...state.walkingBoonjies.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // RocketBoonji
        case (fifthTraitTypes.addFifthTraitRocketBoonji): 
        return {
            ...state,
            rocketBoonjies: [...state.rocketBoonjies, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitRocketBoonji):
        return {
            ...state,
            rocketBoonjies: [...state.rocketBoonjies.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // puzzles
        case (fifthTraitTypes.addFifthTraitPuzzle): 
        return {
            ...state,
            puzzles: [...state.puzzles, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitPuzzle):
        return {
            ...state,
            puzzles: [...state.puzzles.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // formula
        case (fifthTraitTypes.addFifthTraitFormula): 
        return {
            ...state,
            formulas: [...state.formulas, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitFormula):
        return {
            ...state,
            formulas: [...state.formulas.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // chain
        case (fifthTraitTypes.addFifthTraitChain): 
        return {
            ...state,
            chains: [...state.chains, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitChain):
        return {
            ...state,
            chains: [...state.chains.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // SB2 

        // background
        case (fifthTraitTypes.addFifthTraitBackground): 
        return {
            ...state,
            background: [...state.background, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitBackground):
        return {
            ...state,
            background: [...state.background.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // body
        case (fifthTraitTypes.addFifthTraitBody): 
        return {
            ...state,
            body: [...state.body, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitBody):
        return {
            ...state,
            body: [...state.body.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // helmet
        case (fifthTraitTypes.addFifthTraitHelmet): 
        return {
            ...state,
            helmet: [...state.helmet, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitHelmet):
        return {
            ...state,
            helmet: [...state.helmet.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // snake
        case (fifthTraitTypes.addFifthTraitSnake): 
        return {
            ...state,
            snake: [...state.snake, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitSnake):
        return {
            ...state,
            snake: [...state.snake.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // visor
        case (fifthTraitTypes.addFifthTraitVisor): 
        return {
            ...state,
            visor: [...state.visor, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitVisor):
        return {
            ...state,
            visor: [...state.visor.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // Wig
        case (fifthTraitTypes.addFifthTraitWig): 
        return {
            ...state,
            wig: [...state.wig, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitWig):
        return {
            ...state,
            wig: [...state.wig.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // ElephantEar
        case (fifthTraitTypes.addFifthTraitElephantEar): 
        return {
            ...state,
            elephantEar: [...state.elephantEar, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitElephantEar):
        return {
            ...state,
            elephantEar: [...state.elephantEar.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        // Misc
        case (fifthTraitTypes.addFifthTraitMisc): 
        return {
            ...state,
            misc: [...state.misc, action.payload]
        }

        case (fifthTraitTypes.updateFifthTraitMisc):
        return {
            ...state,
            misc: [...state.misc.map(val => (val.name === action.payload.name) ? action.payload : val)]
        }

        default:
            return state;
    }
}