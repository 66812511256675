import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';

import {noBackground} from '../../../assets/superBoonji/preview/background.js';
import {noBody} from '../../../assets/superBoonji/preview/body.js';
import {noEar} from '../../../assets/superBoonji/preview/ear.js';
import {noVisor} from '../../../assets/superBoonji/preview/visor.js';

import { uiShowMaxPreview } from '../../../reducers/uiReducer/uiActions';

require('./Preview.css');

export const Preview = () => {
    const {preview} =  useSelector(state => state.boonjiStudio);
    const background = preview.background?.attributes.find(val => val.trait_type === 'background')
    const ear = preview.ear?.attributes.find(val => val.trait_type === 'ear')
    const visor = preview.visor?.attributes.find(val => val.trait_type === 'visor')
    const body = preview.body?.attributes.find(val => val.trait_type === 'body')
    const {fifthTrait} = preview;

    
    const dispatch = useDispatch();
    const handlePreviewClick = () => {
        dispatch(uiShowMaxPreview());
    }

  return (
      <div className='preview'>
            <div className='preview-hover'>
                <svg
                    onClick={handlePreviewClick}
                    id='svgPreview'
                    xmlns='http://www.w3.org/2000/svg'
                >

                    <image
                        href={(background) ? background.url : noBackground}
                    />
                    
                    {(fifthTrait && fifthTrait.type === 'background') && (
                        <image
                            href={fifthTrait.url}
                        />            
                    )}
                    
                    <image
                        href={(body) ? body.url : noBody}
                    />
                    <image
                        href={(ear) ? ear.url : noEar}
                    />
                    <image
                        href={(visor) ? visor.url : noVisor}
                    />
                    {
                        (fifthTrait && fifthTrait.type !== 'background') ? (
                            <image
                                href={fifthTrait.url}
                            />            
                        ) : (
                            <div></div>
                        )
                    }
                </svg>
            </div>
        </div>
  )
}
