import { Button, Divider, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getAdminData } from 'reducers/redemptionReducer/redemptionActions'
import RedeemRow from '../RedeemRow/RedeemRow';
import { tokens } from 'pages/NewRedemption/redemptionTokens';
import { Box } from '@mui/system';
import ObjectsToCsv from 'objects-to-csv'
import { saveAs } from 'file-saver';    

export const Admin = () => {
    const [token1, setToken1] = useState([])
    const [token2, setToken2] = useState([])
    const [token3, setToken3] = useState([])
    const [token4, setToken4] = useState([])
    const [token5, setToken5] = useState([])
    useEffect(() => {
        getAdminData('1').then(result => {
            setToken1(result)
        })
        getAdminData('2').then(result => {
            setToken2(result)
        })
        getAdminData('3').then(result => {
            setToken3(result)
        })
        getAdminData('4').then(result => {
            setToken4(result)
        })
        getAdminData('5').then(result => {
            setToken5(result)
        })
    }, [])

    const handleExport = async (tokenId) => {
        let csv, data,blob
        switch(tokenId) {
            case 1:
                csv = new ObjectsToCsv(token1);
                data = await csv.toString();
                blob = new Blob([data], {type: "text/plain;charset=utf-8"});
                saveAs(blob, 'token1.csv');
                break;
            case 2:
                csv = new ObjectsToCsv(token2);
                data = await csv.toString();
                blob = new Blob([data], {type: "text/plain;charset=utf-8"});
                saveAs(blob, 'token2.csv');
                break;
            case 3:
                csv = new ObjectsToCsv(token3);
                data = await csv.toString();
                blob = new Blob([data], {type: "text/plain;charset=utf-8"});
                saveAs(blob, 'token3.csv');
                break;
            case 4:
                csv = new ObjectsToCsv(token4);
                data = await csv.toString();
                blob = new Blob([data], {type: "text/plain;charset=utf-8"});
                saveAs(blob, 'token4.csv');
                break;
            case 5:
                csv = new ObjectsToCsv(token5);
                data = await csv.toString();
                blob = new Blob([data], {type: "text/plain;charset=utf-8"});
                saveAs(blob, 'token5.csv');
                break;
            default:
                console.log(`Unknown token it to export ${tokenId}`);
        }
    }
    
  return (
    <Grid
        container
        direction={'column'}
        alignItems='center'
        justifyContent={'center'}
        spacing={4}
    >
        <Grid item>
            <Typography textAlign={'center'} fontSize={25}>
                Token 1 - {tokens[0].name}
            </Typography>
        </Grid>

        {(token1.length > 0) ? (
            <Box>
                <Grid container direction={'row'}>
                    {token1.map(val => (
                        <Grid item xs={12} md={6}>
                            <RedeemRow data={val}/>
                            <Divider variant="middle"  style={{backgroundColor: 'red'}}/>
                        </Grid>
                        ))}
                </Grid>
                <Box textAlign={'center'}  marginTop={4}>
                    <Button  variant='contained' color='warning' onClick={() => handleExport(1)}>Export</Button>
                </Box>
            </Box>
        ) : (
            <Grid item>
                <Typography>
                    No values
                </Typography>
            </Grid>
        )}

        <Grid item>
            <Typography  textAlign={'center'} fontSize={25}>
                Token 2 - {tokens[1].name}
            </Typography>
        </Grid>
        
        {(token2.length > 0) ? (
            <Box>
                <Grid container direction={'row'}>
                    {token2.map(val => (
                        <Grid item>
                            <RedeemRow data={val}/>
                            <Divider variant="middle"  style={{backgroundColor: 'red'}}/>
                        </Grid>
                    ))}
                </Grid>
                <Box textAlign={'center'}  marginTop={4}>
                    <Button  variant='contained' color='warning' onClick={() => handleExport(2)}>Export</Button>
                </Box>
            </Box>
        ) : (
            <Grid item>
                <Typography>
                    No values
                </Typography>
            </Grid>
        )}

        <Grid item>
            <Typography  textAlign={'center'} fontSize={25}>
                Token 3 - {tokens[2].name}
            </Typography>
        </Grid>
        
        {(token3.length > 0) ? (
            <Box >
                <Grid container direction={'row'}>
                {token3.map(val => (
                    <Grid item>
                        <RedeemRow data={val}/>
                        <Divider variant="middle"  style={{backgroundColor: 'red'}}/>
                    </Grid>
                ))}
                </Grid>
                <Box textAlign={'center'}  marginTop={4}>
                    <Button  variant='contained' color='warning' onClick={() => handleExport(3)}>Export</Button>
                </Box>
            </Box>
        ) : (
            <Grid item>
                <Typography>
                    No values
                </Typography>
            </Grid>
        )}

        <Grid item>
            <Typography  textAlign={'center'} fontSize={25}>
                Token 4 - {tokens[3].name}
            </Typography>
        </Grid>
        
        {(token4.length > 0) ? (
            <Box>
                <Grid container direction={'row'}>
                {token4.map(val => (
                    <Grid item>
                        <RedeemRow data={val}/>
                        <Divider variant="middle"  style={{backgroundColor: 'red'}}/>
                    </Grid>
                ))}
                </Grid>
                <Box textAlign={'center'}  marginTop={4}>
                    <Button  variant='contained' color='warning' onClick={() => handleExport(4)}>Export</Button>
                </Box>
            </Box>
        ) : (
            <Grid item>
                <Typography>
                    No values
                </Typography>
            </Grid>
        )}

        <Grid item>
            <Typography  textAlign={'center'} fontSize={25}>
                Token 5 - {tokens[4].name}
            </Typography>
        </Grid>
        
        {(token5.length > 0) ? (
            <Box>
                <Grid container direction={'row'}>
                {token5.map(val => (
                    <Grid item>
                        <RedeemRow data={val}/>
                        <Divider variant="middle"  style={{backgroundColor: 'red'}}/>
                    </Grid>
                ))}
                </Grid>
                <Box textAlign={'center'} marginTop={4}>
                    <Button  variant='contained' color='warning' onClick={() => handleExport(5)}>Export</Button>
                </Box>
            </Box>
        ) : (
            <Grid item>
                <Typography>
                    No values
                </Typography>
            </Grid>
        )}
    </Grid>
  )
}
