import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector} from 'react-redux';
import { Grid, Tooltip } from '@mui/material';
import PropTypes from 'prop-types'
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { amber } from '@mui/material/colors';

import { selectFifthTrait } from '../../../reducers/boonjiStudioReducer/boonjiStudioActions';

require('./FifthTraitImage.css')
export const FifthTraitImage = props => {
    const {trait} = props;
    const dispatch = useDispatch()
    const {preview} = useSelector(state => state.boonjiStudio);
    const {fifthTrait} = preview;
    const {body, visor, ear, background} = preview;
    const [selected, setSelected] = useState(false);
    const popularTraitValueLimit = process.env.REACT_APP_POPULAR_TRAIT_VALUE_LIMIT

    const [isValidForSignature, setIsValidForSignature] = useState(false);

    const handleTraitClick = async () => {
        // if the trait is already reserved, we don't do anything
        if (trait.status && trait.status > 0)
            return;
        
        // this is a signature but is not valid? then you can't select it
        if (trait.type === 'signature' && !isValidForSignature){
            return;
        }

        if (!selected){
            await dispatch(selectFifthTrait(trait))
            await setSelected(true);
        } else {
            await dispatch(selectFifthTrait(null))
            await setSelected(false);
        }
    }

    
    useEffect(async () => {
        if (!body || !visor || !ear || !background){
            setIsValidForSignature(false);
            return;
        }

    if (body.tokenId !== ear.tokenId || body.tokenId !== visor.tokenId || body.tokenId !== background.tokenId){
        setIsValidForSignature(false)
        if (trait?.type === 'signature' && fifthTrait?.type === trait.type && fifthTrait?.name === trait.name ){
            await setSelected(false);
            await dispatch(selectFifthTrait(null))
        }
    } else
        setIsValidForSignature(true)
    }, [body, ear, visor, background])
    


    useEffect(async () => {
        if (fifthTrait?.type === trait.type && fifthTrait?.name === trait.name){
            if (trait.type === 'signature'){
                if (isValidForSignature)
                    await setSelected(true);
                else
                    await setSelected(false);
            } else
                await setSelected(true);
        } else {
            await setSelected(false);
        }
    }, [fifthTrait])
    

  return (
      <div className='fifthTraitImage'>
        <div  onClick={handleTraitClick}>
                {
                        (trait.type !== 'signature') ? (

                        (trait.picked?.length > popularTraitValueLimit) ? (
                            <Grid container  direction="row" justifyContent="center" alignItems="center" >
                                <Grid item>
                                    <Tooltip title={'picked by ' + trait.picked?.length} placement="top">
                                        <div >
                                            <LocalFireDepartmentIcon fontSize="small"  sx={{ color: amber[500] }}/> 
                                        </div>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <div className={(trait.status && trait.status > 0) ? 'fifthTraitImage-status-reserved' : 'fifthTraitImage-status-available'}>
                                        {(trait.status && trait.status > 0) ? (trait.status == 1) ? 'Reserved' : 'Used' : 'Available'}
                                    </div>
                                </Grid>
                            </Grid>
                        ) : (
                            <div className={(trait.status && trait.status > 0) ? 'fifthTraitImage-status-reserved' : 'fifthTraitImage-status-available'}>{(trait.status && trait.status > 0) ? (trait.status == 1) ? 'Reserved' : 'Used' : 'Available'}</div>
                        )
                    ) : (
                        <div>
                        {
                            (isValidForSignature) ? (
                                <div className='fifthTraitImage-status-available'> Available</div>
                            ) : (
                                <div className='fifthTraitImage-status-reserved'> Not Available</div>
                            )
                        }
                        </div>
                    )
                }
            <div className={(selected) ? 'fifthTraitImage-selected' : ''}>
                <img src={trait.url} alt={trait.name} className={((trait.status && trait.status > 0) || (trait.type === 'signature' && !isValidForSignature)) ? 'fifthTraitImage-status-reserved-img' : 'fifthTraitImage'}/>
            </div>
            <div className='fifthTraitImage-name'>
                {trait.name}  
            </div>
        </div>
      </div>
  )
}

FifthTraitImage.propTypes = {
    trait: PropTypes.object.isRequired
}
