import React from "react";
import { Grid } from "@mui/material";

import license from "./Jupiter-BM Super Boonji License Agreement.pdf";
require("./BoonjiStudioTitle.css");

export const BoonjiStudioTitle = () => {
  return (
    <div className="boonjiStudioTitle">
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <div className="boonjiStudioTitle-title">Boonji Studio</div>
        </Grid>

        <Grid item>
          <div className="boonjiStudioTitle-subTitle">
            Welcome to the Super Boonji Studio!
          </div>
        </Grid>

        <Grid item>
          <div className="boonjiStudioTitle-description">
            Connect your wallet to see all of the Boonjis you currently own.
            Explore other Boonji’s by searching for their IDs in order to
            experiment with different available traits.
            <br />
            <br />
            Create your unique Super Boonji by burning 4 normal Boonjis and
            selecting up to 4 traits to be combined. You can even choose
            multiple traits from the same Boonji! Add a special 5th trait from
            our exclusive collection, with each being unique in color
            variations—this trait will be reserved for only 2 minutes upon
            selection, ensuring no duplicates are created.
            <br />
            <br />
            Unlock a special Brendan Murphy’s signature trait by choosing all 4
            traits from a single Boonji. This also includes a unique Metadata
            trait called “unaltered.”
            <br />
            <br />
            Stay tuned on Discord for more details on the process and get ready for an exciting adventure with us! Let's get started!


          </div>
        </Grid>
      </Grid>
    </div>
  );
};
