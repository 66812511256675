import React from "react";
import { NavLink } from "react-router-dom";

import './Menu.css'

const Menu = props =>{

    return(
        <li className='nav-item'>
            {props.title !== "News" ?
                <NavLink 
                    exact
                    to={props.link} 
                    className='nav-links' 
                    activeClassName="link-selected"
                    onClick={props.onClick}>{props.title}
                 </NavLink> 
                :
                <a className='nav-links' href="https://news.boonjiproject.com/">{props.title}</a>               
            }                     
        </li>
    )
}

export default Menu;