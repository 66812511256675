import React from "react";
import PurpleBtnRect from "../../../components/Buttons/PurpleBtnRect";

import './Address.css';

const Address = props =>{
    const {openSea, address} = props;

    const network = process.env.REACT_APP_NETWORK;
    const etherscan = 'https://' + (network === 'rinkeby' ? 'rinkeby.' : '') + 'etherscan.io/address/' + address;
    
    const handleCopyOnClick = () => {
        navigator.clipboard.writeText(address);
        return false;
    }
    const buttonLinks =[
        {
            id:1, 
            title:"Open on Etherscan",
            link: etherscan
        },
        {
            id:2, 
            title:"Open on OpenSea",
            link: openSea
        },
        {
            id:3, 
            title:"Copy Address",
            onClick: handleCopyOnClick
        }
    ]

    return(
        <section className="address-section">
            <div className="address-container">
                <h2>Verified smart contract address:</h2>
                <div className="address-container-input">{address}</div>
                <div className="address-btn-container">
                    {buttonLinks.map(info=>{
                        return <PurpleBtnRect key={info.id} title={info.title} link={info.link} onClick={info.onClick}/>
                    })}
                </div>
            </div>
        </section>
    )
}

export default Address;
