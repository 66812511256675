import React from "react"
import './BoonjiImg.css'

const BoonjiImg = props =>{

    return(
        <div className="boonji-img">
            <img src="/images/boonji-logo-oct4-2.png" alt="" />
        </div>
    )
}

export default BoonjiImg;