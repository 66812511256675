import React from "react";
import BoonjiProject from "./Sections/BoonjiProject";
import Specs from "./Sections/Specs";
import Communji from "./Sections/Communji";
import Moonji from "./Sections/Moonji";
import Maxim from "./Sections/Maxim";
import Journey from "../Journey/Journey";

import "./Home.css";

const Home = (props) => {
  return (
    <div className="general-container">
      <div className="general-container-int">
        <BoonjiProject />
        {/* <Specs /> */}
        <Communji />
        {/* <Moonji />
            <Maxim /> 
            <div id="Journey-Planets">
              <Journey />  
            </div>                */}
      </div>
    </div>
  );
};
export default Home;
