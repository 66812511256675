import React from 'react'

import './LinkBtn.css'

const LinkBtn = props =>{

    return(
        <div className='link-btn'>
            <div>
                <a href={props.link} target="_blank" rel="noreferrer">
                    {props.title}
                </a>
            </div>              
        </div>
    )
}

export default LinkBtn;