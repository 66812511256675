import React, {useEffect} from 'react';
import {BrowserView, MobileView} from 'react-device-detect';

import { SuperBoonjiPreview } from '../../components/SuperBoonjiPreview/SuperBoonjiPreview';
import { NotOwnedBoonji } from '../../components/NotOwnedBoonji/NotOwnedBoonji';
import { BoonjiStudioTitle } from './BoonjiStudioTitle/BoonjiStudioTitle';
import { OwnedBoonji } from '../../components/OwnedBoonji/OwnedBoonji';
import { Grid } from '@mui/material';

import { subscribeFifthTraitHeadphone, subscribeFifthTraitHalo, subscribeFifthTraitSignature, subscribeFifthTraitRocketMan,  subscribeFifthTraitNecklace, subscribeFifthTraitCrown, subscribeFifthTraitHorn, subscribeFifthTraitDevilAngel,  subscribeFifthTraitVulcanEar,  subscribeFifthTraitChain, subscribeFifthTraitWord, subscribeFifthTraitFingerprint, subscribeFifthTraitChainLoolee, subscribeFifthTraitPuzzle, subscribeFifthTraitRocketBoonji, subscribeFifthTraitWalkingBoonji, subscribeFifthTraitFormula, subscribeFifthTraitSpiral, subscribeFifthTraitLady, subscribeFifthTraitBackground, subscribeFifthTraitBody, subscribeFifthTraitHelmet, subscribeFifthTraitSnake, subscribeFifthTraitVisor, subscribeFifthTraitWig, subscribeFifthTraitElephantEar, subscribeFifthTraitMisc } from '../../reducers/fifthTrait/fifthTraitActions';
import { useDispatch } from 'react-redux';
import { uiShowAlert } from '../../reducers/uiReducer/uiActions';
import { FifthTrait } from '../../components/FifthTrait/FifthTrait';
import Address from '../../pages/Journey/Sections/Address';
import { PreviewMobile } from '../../components/SuperBoonjiPreview/PreviewMobile/PreviewMobile';
import {isMobile} from 'react-device-detect';
import { Confirmation } from '../../components/SuperBoonjiPreview/Confirmation/Confirmation';

require('./BoonjiStudio.css')
export const BoonjiStudio = () => {

  const address = process.env.REACT_APP_SUPER_BOONJI_ADDRESS;
  const openSea = process.env.REACT_APP_OPENSEA_COLLECTION_SUPERBOONJI
  const showFifthTrait = (process.env.REACT_APP_SHOW_FIFTH_TRAIT === 'false' ? false : true);

  const dispatch = useDispatch();

  useEffect(async () => {
    try {
      if (showFifthTrait){
        await dispatch(subscribeFifthTraitHeadphone());
        await dispatch(subscribeFifthTraitHalo());
        await dispatch(subscribeFifthTraitDevilAngel());
        await dispatch(subscribeFifthTraitSignature());
        await dispatch(subscribeFifthTraitRocketMan());
        await dispatch(subscribeFifthTraitVulcanEar());
        await dispatch(subscribeFifthTraitNecklace());
        await dispatch(subscribeFifthTraitCrown());
        await dispatch(subscribeFifthTraitHorn());
        await dispatch(subscribeFifthTraitWord());
        await dispatch(subscribeFifthTraitFingerprint());
        await dispatch(subscribeFifthTraitSpiral());
        await dispatch(subscribeFifthTraitLady());
        await dispatch(subscribeFifthTraitPuzzle());
        await dispatch(subscribeFifthTraitFormula());
        await dispatch(subscribeFifthTraitRocketBoonji());
        await dispatch(subscribeFifthTraitWalkingBoonji());
        await dispatch(subscribeFifthTraitChain());
        // SB2
        await dispatch(subscribeFifthTraitBackground());
        await dispatch(subscribeFifthTraitBody());
        await dispatch(subscribeFifthTraitHelmet());
        await dispatch(subscribeFifthTraitSnake());
        await dispatch(subscribeFifthTraitVisor());
        await dispatch(subscribeFifthTraitWig());
        await dispatch(subscribeFifthTraitElephantEar());
        await dispatch(subscribeFifthTraitMisc());

      }
    } catch ( error ) {
      await dispatch(uiShowAlert('warning', 'Fifth trait', error.toString()));
    }
  }, [])
  
  return (
    <div className='boonjiStudio'>
      <Confirmation/>
      <div>
        <BoonjiStudioTitle/>
      </div>
      <div>
        <Grid
          container 
          spacing={2}
          columns={20}
        >
          <div className='boonjiStudio-previewMobile'>
            <Grid item>
              <MobileView>
                <PreviewMobile/>
              </MobileView>
            </Grid>
          </div>
          <Grid item xs={(isMobile) ? 10 : 12}>
            <Grid 
              container 
              spacing={2}
              direction="column"
              justifyContent="left"
              alignItems="left"
            >
              <Grid item>            
                <div>
                  <OwnedBoonji/>
                </div>
              </Grid>

              <Grid item>
                <div>
                  <NotOwnedBoonji/>
                </div>
              </Grid>

              {
                (showFifthTrait) ? (
                  <Grid item>
                    <div>
                      <FifthTrait/>
                    </div>
                  </Grid>
                ) : (
                  <div></div>
                )
              }
            </Grid>
          </Grid>

          <Grid item>
              <div className='boonjiStudio-preview'>
                <BrowserView>
                  <SuperBoonjiPreview/>
                </BrowserView>
              </div>
          </Grid>
          
        </Grid>
      </div>
      {
        (showFifthTrait) ? (
          <div>
            <br/>
            <br/>
            <Address address={address} openSea={openSea}/>
          </div>
        ) : (
          <div></div>
        )
      }
    </div>
    )
};
