import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { AddressForm } from '../AddressForm';
import { useDispatch, useSelector } from 'react-redux';
import { tokens } from 'pages/NewRedemption/redemptionTokens';
import { burnTokens, reduceBalance, setShowSuccessDialog, updateColorAvailability } from 'reducers/redemptionReducer/redemptionActions';
import { uiCloseBackdrop, uiShowAlert, uiShowBackdrop } from 'reducers/uiReducer/uiActions';
import { SuccessDialog } from 'pages/NewRedemption/components/SuccessDialog';

const RedeemDialog = props => {
    const {open, setOpen, tokenId} = props;
    const {address, provider} = useSelector(state => state.walletProvider);
    const [formData, setFormData] = useState(null);
    const dispatch = useDispatch();
    
    useEffect(() => {
      if (!address)
        onClose();
    }, [address])
    

    const onClose = () => {
        setOpen(false);
        setFormData(null);
    }

    const handleSubmit = async (data) => {
        try {
            dispatch(uiShowBackdrop());
            
            const value = Number(data.qty);
            const hash = await burnTokens(data, address, tokenId, value, provider)

            // did we redeem a color, we need to reduce the amount of color available
            if (tokenId === 1){
                const {color, qty} = data;
                await updateColorAvailability(color, Number(qty))
            }

            dispatch(setShowSuccessDialog(tokenId));
            dispatch(reduceBalance(tokenId, value))
            onClose();
        } catch ( error ) {
            console.log(error);
            const {message} =  error
            if (message.includes('Signature: User denied transaction signature')){
                dispatch(uiShowAlert('info', 'Redeem token', 'Transaction was declined. No tokens burned.'))    
            } else {
                dispatch(uiShowAlert('error', 'Redeem token', 'There was an error redeeming your token. Please refresh and try again.'))
            }
        } finally {
            dispatch(uiCloseBackdrop());
            
        }

    }
  return (
    <Dialog
        open={open}
        onClose={onClose}
        maxWidth={'100%'}
        style={{zIndex: 1}}
    >
        <DialogTitle  >
            <Typography color={'black'}>
                Redeem your Token "{tokens[tokenId-1].name}"
            </Typography>
        </DialogTitle>

        <DialogContent>
            <AddressForm 
                formData={formData} 
                setFormData={setFormData} 
                tokenId={tokenId}
                handleRedeem={handleSubmit}
            />
        </DialogContent>

        <DialogActions>
            <Button fullWidth onClick={onClose}>Cancel</Button>
        </DialogActions>
    </Dialog>
  )
}

RedeemDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    tokenId: PropTypes.number.isRequired
}

export default RedeemDialog