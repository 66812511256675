import {redemptionTypes} from './redemptionTypes';

const initialState = {
    token1: '0',
    token2: '0',
    token3: '0',
    token4: '0',
    token5: '0',
    loadingBalances: false,
    colors: {
        black: 0,
        blue: 0,
        gold: 0,   
        green: 0,   
        red: 0,   
        purple: 0,   
        rose: 0,   
    },
    successDialog: 0,
}

export const redemptionReducer = (state = initialState, action) => {
    switch (action.type) {
        // sets the provider, network and address once we connected with the wallet
        case redemptionTypes.setBalanceToken1:
            return {
                ...state,
                token1: action.payload
            }

        case redemptionTypes.setBalanceToken2:
            return {
                ...state,
                token2: action.payload
            }

        case redemptionTypes.setBalanceToken3:
            return {
                ...state,
                token3: action.payload
            }

        case redemptionTypes.setBalanceToken4:
            return {
                ...state,
                token4: action.payload
            }

        case redemptionTypes.setBalanceToken5:
            return {
                ...state,
                token5: action.payload
            }

        case redemptionTypes.setLoadingBalances:
            return {
                ...state,
                loadingBalances: action.payload
            }

        case redemptionTypes.updateColorsAvailability:
            return {
                ...state,
                colors: action.payload
            }

        case redemptionTypes.reduceColorAvailability:
            return {
                ...state,
                colors: {
                    ...state,
                    colors: {...state.colors, ...state.colors[action.payload.color] = state.colors[action.payload.color] - action.payload.value}
                }
            }

        case redemptionTypes.setSuccessDialog:
            return {
                ...state,
                successDialog: action.payload
            }
        default:
            return state
    }
}