import React, { useState, useEffect } from 'react'
import { Button, CircularProgress, Grid, InputAdornment, TextField } from '@mui/material';
import {BrowserView, MobileView} from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { addNewNotOwnedBoonji } from '../../reducers/boonjiStudioReducer/boonjiStudioActions';
import { uiShowAlert } from '../../reducers/uiReducer/uiActions';
import { BoonjiLine } from '../BoonjiLine/BoonjiLine';
import {BoonjiCard} from '../BoonjiCard/BoonjiCard'
import {useForm} from '../../hooks/useForm';
import {oneOfOnes} from '../../utils/oneOfOnes'

require('./NotOwnedBoonji.css');

export const NotOwnedBoonji = () => {
    const dispatch = useDispatch();
    const {notOwned, owned} = useSelector(state => state.boonjiStudio);
    const [isLoading, setIsLoading] = useState(false)
    const [formValues, handleInputchange, reset] = useForm({tokenId:''});
    const {tokenId} = formValues

    
    const [error, setError] = useState({isError: false, message :''})
    
    useEffect(() => {
        if (tokenId){
            // we run all validations
            if (isNaN(tokenId)){
                setError({isError: true, message: 'only numbers'})
                return;
            }

            if (notOwned.find(val => val.tokenId === tokenId)){
                setError({isError: true, message: 'already exists'})
                return;
            }

            if (owned.find(val => val.tokenId === tokenId)){
                setError({isError: true, message: 'already exists'})
                return;
            }

            if (tokenId > 11111){
                setError({isError: true, message: 'too high'})
                return;
            }

            // token is 1:1, we don't allow it
            if (oneOfOnes.find(val => val == tokenId)){
                setError({isError: true, message: 'Boonji is 1/1'})
                return;
            }
        }
        setError({isError: false, message: ''})
    }, [tokenId])
    

    const handleOnClick = async () => {
        // don't do anything if empty
        if (!tokenId)
            return;

        try {
            setIsLoading(true)
            await dispatch(addNewNotOwnedBoonji(tokenId));
            reset()
        } catch ( error ) {
            await dispatch(uiShowAlert('error', 'Oops!', error.toString()));
        } finally {
            setIsLoading(false)
        }
    }
    return (
        <div className='notOwnedBoonji'>
            <div className='notOwnedBoonji-title'>Try with a Boonji you don’t own!</div>
            <Grid
                container 
                spacing={1}
                direction="row"
                justifyContent="left"
                alignItems="center"
            >
                <Grid item>
                    <div className='notOwnedBoonji-description'>
                        Experiment with a Boonji you don't own until you get the perfect combination! 
                    </div>
                </Grid>

                <Grid item>
                    <TextField  
                        className='notOwnedBoonji-textField' 
                        label="Token ID" 
                        color="warning" 
                        name='tokenId'
                        error={error.isError}
                        helperText={error.message}
                        autoComplete="off"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">#</InputAdornment>,
                          }}
                        value={tokenId}
                        onChange={handleInputchange}
                        style={{borderRadius:'10px'}}
                        size="small" 
                        variant="filled"/>
                </Grid>

                <Grid item>
                    <Button disabled={error.isError} variant="contained"  color='secondary' className='notOwnedBoonji-button' onClick={handleOnClick} style={{borderRadius:'10px'}}>Get It</Button>
                </Grid>
            </Grid>
            {
                (isLoading) ? (
                    <div className='notOwnedBoonji-loading'>
                        <CircularProgress  color="secondary" />
                    </div>
                ) : (
                    <div className='notOwnedBoonji-loading'>

                    </div>
                )
            }

            <Grid
                container
                direction="row"
                justifyContent={{xs:'center', md:"flex-start"}}
                alignItems="center"
                spacing={2}
                paddingLeft={2}
                columns={{ xs: 4, md: 8 }}
            >
                    <div className='notOwnedBoonji-list'>
                        {notOwned.map((boonji, index) => (
                            <div className='nowOwnedBoonji-item'>
                                <Grid item >
                                    <BrowserView>
                                        <BoonjiLine key={(boonji?.tokenId) ? boonji.tokenId : index} boonji={boonji}/>
                                    </BrowserView>
                                    <MobileView>
                                        <BoonjiCard key={(boonji?.tokenId) ? boonji.tokenId : index} boonji={boonji}/>
                                    </MobileView>
                                </Grid>
                        </div>
                    ))}
                    </div>
            </Grid>
        </div>
    )
}
