import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'

import {noBackground} from '../../../assets/superBoonji/preview/background.js'
import { selectBodyTrait, selectVisorTrait, selectBackgroundTrait, selectEarTrait } from '../../../reducers/boonjiStudioReducer/boonjiStudioActions';

require ('./SelectedTraits.css')

export const SelectedTraits = (props) => {
  const {readOnly} = props;

  const dispatch = useDispatch();
  const {preview} = useSelector (state => state.boonjiStudio);
  const background = preview.background?.attributes.find(val => val.trait_type === 'background')
  const ear = preview.ear?.attributes.find(val => val.trait_type === 'ear')
  const visor = preview.visor?.attributes.find(val => val.trait_type === 'visor')
  const body = preview.body?.attributes.find(val => val.trait_type === 'body')
  const ownedBackground = (preview.background?.owned) ? true : false;
  const ownedVisor = (preview.visor?.owned) ? true : false;
  const ownedEar = (preview.ear?.owned) ? true : false;
  const ownedBody = (preview.body?.owned) ? true : false;

  const handleClickBody = () => {
    if (!readOnly)
      dispatch(selectBodyTrait(null))
  }

  const handleClickVisor = () => {
    if (!readOnly)
      dispatch(selectVisorTrait(null))
  }

  const handleClickEar = () => {
    if (!readOnly)
      dispatch(selectEarTrait(null))
  }

  const handleClickBackground = () => {
    if (!readOnly)
      dispatch(selectBackgroundTrait(null))
  }

  return (
    <div className='selectedTraits'>
      <Grid
        container 
        direction="column"
        spacing={0}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Grid 
            container 
            spacing={0.5}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item >
              <div className='selectedTraits-trait'>
                <img src={(body) ? body.url : noBackground} alt='Body' onClick={handleClickBody} className={(body) ? ((ownedBody) ? 'selectedTraits-selected' : 'selectedTraits-selected-notOwned') : 'selectedTraits-notSelected'}/>
              </div>
              
              <div className='selectedTraits-type'>
                Body
              </div>
            </Grid>

            <Grid item>
              <div className='selectedTraits-trait'>
                <img src={(visor) ? visor.url : noBackground} alt='Visor' onClick={handleClickVisor} className={(visor) ? ((ownedVisor) ? 'selectedTraits-selected' : 'selectedTraits-selected-notOwned') : 'selectedTraits-notSelected'}/>
              </div>

              <div className='selectedTraits-type'>
                Visor
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid 
            container 
            spacing={0.5}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
          <Grid item>
            <div className='selectedTraits-trait'>
              <img src={(ear) ? ear.url : noBackground} alt='Ear' onClick={handleClickEar} className={(ear) ? ((ownedEar) ? 'selectedTraits-selected' : 'selectedTraits-selected-notOwned') : 'selectedTraits-notSelected'}/>
            </div>

            <div className='selectedTraits-type'>
              Ears
            </div>
          </Grid>

          <Grid item>
            <div className='selectedTraits-trait'>
              <img src={(background) ? background.url : noBackground} alt='Background' onClick={handleClickBackground} className={(background) ? ((ownedBackground) ? 'selectedTraits-selected' : 'selectedTraits-selected-notOwned') : 'selectedTraits-notSelected'}/>
            </div>

            <div className='selectedTraits-type'>
              Background
            </div>
          </Grid>
        </Grid>
          
        </Grid>

      </Grid>
    </div>
  )
}

SelectedTraits.propTypes = {
  readOnly: PropTypes.bool.isRequired
}