import {uiTypes} from './uiTypes';

export const uiCloseAlert = () => ({
    type: uiTypes.uiCloseAlert
})

export const uiShowAlert = (severity, title, message) => ({
    type: uiTypes.uiOpenAlert,
    payload: {severity, title, message}
})

export const uiShowMaxPreview = () => ({
    type: uiTypes.uiMaxPreviewOpen
})

export const uiCloseMaxPreview = () => ({
    type: uiTypes.uiMaxPreviewClose
})

export const uiShowconfirmation = () => ({
    type: uiTypes.uiConfirmationOpen
})

export const uiCloseConfirmation = () => ({
    type: uiTypes.uiConfirmationClose
})

export const uiShowconfirmation2 = () => ({
    type: uiTypes.uiConfirmation2Open
})

export const uiCloseConfirmation2 = () => ({
    type: uiTypes.uiConfirmation2Close
})

export const uiShowconfirmation3 = () => ({
    type: uiTypes.uiConfirmation3Open
})

export const uiCloseConfirmation3 = () => ({
    type: uiTypes.uiConfirmation3Close
})

export const uiOpenInProgress = (title, message) => ({
    type: uiTypes.uiInProgressOpen,
    payload: {title, message}
})

export const uiCloseInProgress = () => ({
    type: uiTypes.uiInProgressClose
})

export const uiShowBackdrop = () => ({
    type: uiTypes.uiOpenBackdrop
})

export const uiCloseBackdrop = () => ({
    type: uiTypes.uiCloseBackdrop
})