import React from "react";
import RoadMap from "./Sections/RoadMap";
import Phases from "./Sections/Phases"; 
import Tools from "./Sections/Tools";
import Crew from "./Sections/Crew";
import Address from "./Sections/Address";

import './Journey.css';

const Journey = propos =>{
    const address = process.env.REACT_APP_BOONJI_PROJECT_ADDRESS;
    const openSea = process.env.REACT_APP_OPENSEA_COLLECTION
    return(
        <div className="journey-container">
            <RoadMap />
            <Phases />
            <Tools /> 
            <Crew /> 
            <Address address={address} openSea={openSea}/>        
        </div>       
    )
}

export default Journey;