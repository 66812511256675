import React from 'react';

import {Provider} from 'react-redux'

import { AppRouter } from './routers/AppRouter';
import bgImg from './assets/new-twinkle.gif'
import {store} from './store/store'

import './BoonjiProject.css';

export const BoonjiProject = () => {
  return (
      <div style={{backgroundImage: "url(" + bgImg + ")"}}>
        <Provider store={store}>
            <AppRouter/>
        </Provider>
      </div>
    )
};
