import React from "react";

import './PurpleBtnRect.css'

const PurpleBtnRect = props=>{
    return(
        <a href={props.link} 
            className="purple-btn-rect"
            target={(props.onClick) ? "_self" : "_blank"}
            onClick={props.onClick}
            rel='noreferrer'> {props.title} </a>
    )
}

export default PurpleBtnRect;