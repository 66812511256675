import React from "react";
import imgBg from '../../../assets/lore-bg.jpeg';
import CrewUI from "../../../shared/UIElements/CrewUI";

import './Crew.css'

//febrero 28

const Crew = props =>{

    const CrewInfo =[
        {
            id:1,
            img:"./images/BrendanBoonji-p-500.jpeg",
            name:"Brendan Murphy",
            position:"Artist | Project Founder",
            twitter:"@BMArt_33",
            twitterLink:"https://twitter.com/BMArt_33",
            instagram:"@brendanmurphyart",
            instagramLink:"https://www.instagram.com/brendanmurphyart/",
            facebook:"@brendanmurphyart",
            facebookLink:"https://web.facebook.com/brendanmurphyart",
            bioLink:"/brendan-murphy-biography",
            bioInfoPopup:false,
            bioInfo:[]
        },
        {
            id:2,
            img:"./images/nicolemc2-p-500.jpeg",
            name:"Nicole McGraw",
            position:"Chief Creative Officer | Co-Founder",
            twitter:"",
            twitterLink:"",
            instagram:"@nicolemcgrawart",
            instagramLink:"https://www.instagram.com/nicolemcgrawart/",
            facebook:"@nicolemcgrawart",
            facebookLink:"https://web.facebook.com/nicolemcgrawart",
            bioLink:"https://boonji-jan19.webflow.io/brendan-murphy-biography",
            bioInfoPopup:true,
            bioInfo:[
                "Represented hundreds of artists",
                "International clientele of collectors",
                "Founder of CANVAS Art Charities",
                "Owns Palm Beach gallery",
                "Curated $3,000,000+ in public art",
                "Passionate about cryptocurrency"
            ]
        },
        {
            id:3,
            img:"./images/jamesfeltman-p-500.jpeg",
            name:"James Feltman",
            position:"CFO | Co-Founder",
            twitter:"",
            twitterLink:"",
            instagram:"",
            instagramLink:"",
            facebook:"",
            facebookLink:"",
            bioLink:"https://boonji-jan19.webflow.io/brendan-murphy-biography",
            bioInfoPopup:true,
            bioInfo:[
                "Top 25 Consultant in US",
                "30+ years experience in litigation, forensics and investigative services",
                "20+ years with Big 4 accounting firms",
                "Corporate restructuring expert",
                "Certified Public Accountant",
                "World class chef"            
            ]
        },
        {
            id:4,
            img:"./images/adam-p-500.jpeg",
            name:"Adam Sedacca",
            position:"Boonji Project Community Manager",
            twitter:"@bananaman",
            twitterLink:"https://twitter.com/bananaman",
            instagram:"",
            instagramLink:"",
            facebook:"",
            facebookLink:"",
            bioLink:"https://boonji-jan19.webflow.io/brendan-murphy-biography",
            bioInfoPopup:true,
            bioInfo:[
                "Social Media Manager, NFT Consultant",
                "Passionate about blockchain technology & the future of NFTs"
            ]
        },
        {
            id:5,
            img:"./images/rodrigo.jpg",
            name:"Rodrigo Acosta",
            position:"Lead Developer",
            twitter:"",
            twitterLink:"",
            instagram:"",
            instagramLink:"",
            facebook:"",
            facebookLink:"",
            bioLink:"",
            bioInfoPopup:false,
            bioInfo:[]
        },
        {
            id:12,
            img:"./images/mateo.jpeg",
            name:"Dr. Matteo Allesandro",
            position:"Marketing",
            twitter:"",
            twitterLink:"",
            instagram:"",
            instagramLink:"",
            facebook:"",
            facebookLink:"",
            bioLink:"",
            bioInfoPopup:false,
            bioInfo:[]
        },
        {
            id:7,
            img:"./images/CamCrypto.jpeg",
            name:"CamCrypto",
            position:"Marketing",
            twitter:"",
            twitterLink:"",
            instagram:"",
            instagramLink:"",
            facebook:"",
            facebookLink:"",
            bioLink:"",
            bioInfoPopup:false,
            bioInfo:[]
        },
        {
            id:8,
            img:"./images/Dr-Boonji.jpeg",
            name:"Dr Boonji",
            position:"Marketing",
            twitter:"",
            twitterLink:"",
            instagram:"",
            instagramLink:"",
            facebook:"",
            facebookLink:"",
            bioLink:"",
            bioInfoPopup:false,
            bioInfo:[]
        },
        {
            id:9,
            img:"./images/Dr-Boonji.jpeg",
            name:"TYFigther",
            position:"Marketing",
            twitter:"",
            twitterLink:"",
            instagram:"",
            instagramLink:"",
            facebook:"",
            facebookLink:"",
            bioLink:"",
            bioInfoPopup:false,
            bioInfo:[]
        },
        {
            id:10,
            img:"./images/Benni.jpeg",
            name:"Benni Nicklaus",
            position:"Moderator",
            twitter:"",
            twitterLink:"",
            instagram:"",
            instagramLink:"",
            facebook:"",
            facebookLink:"",
            bioLink:"",
            bioInfoPopup:false,
            bioInfo:[]
        },
        {
            id:11,
            img:"./images/Ed08.jpeg",
            name:"Ed08",
            position:"Moderator",
            twitter:"",
            twitterLink:"",
            instagram:"",
            instagramLink:"",
            facebook:"",
            facebookLink:"",
            bioLink:"",
            bioInfoPopup:false,
            bioInfo:[]
        },
        {
            id:13,
            img:"./images/complexities.jpeg",
            name:"Complexities",
            position:"Head Website Moderator",
            twitter:"",
            twitterLink:"",
            instagram:"",
            instagramLink:"",
            facebook:"",
            facebookLink:"",
            bioLink:"",
            bioInfoPopup:false,
            bioInfo:[]
        },
    ]

    return(
        <section className="crew-section"
        style={{backgroundImage: "linear-gradient(180deg, rgba(89, 59, 131, 0.8), #291743), url(" + imgBg + ")"}}>
            <div className="crew-section-container">
                <h2>Boonji Crew</h2>
                <p>Brendan Murphy partnered with Jupiter to bring his Boonjai to life, and this journey is just the beginning. We can’t wait to build this into the best NFT community out there! Follow the Boonji Project here: <a href="https://twitter.com/BoonjiProject" target="_Blank" rel='noreferrer'>@BoonjiProject</a></p>
                <div className="crew-info-container">
                    {CrewInfo.map(info =>{
                        return <CrewUI 
                                    key={info.id} 
                                    img={info.img}
                                    name={info.name}
                                    position={info.position}
                                    twitter={info.twitter}
                                    twitterLink={info.twitterLink}
                                    instagram={info.instagram}
                                    instagramLink={info.instagramLink}
                                    facebook={info.facebook}
                                    facebookLink={info.facebookLink}
                                    bioLink={info.bioLink}
                                    bioInfoPopup={info.bioInfoPopup}
                                    bioInfo={info.bioInfo}
                                />
                            }
                        )
                    }            
                </div> 
            </div>   
        </section>
    )
}

export default Crew;