import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import Zoom from "@mui/material/Zoom";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { isMobile } from "react-device-detect";

import { showWalletProviderModal } from "../../reducers/walletProviderReducer/walletProviderActions";
import {
  loadOwnedBoonjies,
  selectVisorTrait,
  selectBackgroundTrait,
  selectEarTrait,
  selectBodyTrait,
} from "../../reducers/boonjiStudioReducer/boonjiStudioActions";
import { uiShowAlert } from "../../reducers/uiReducer/uiActions";
import { localStorageGet } from "../../utils/localStorage";
import { BoonjiCard } from "../BoonjiCard/BoonjiCard";
import { BoonjiLine } from "../BoonjiLine/BoonjiLine";

require("./OwnedBoonji.css");
export const OwnedBoonji = () => {
  const { provider, address } = useSelector((state) => state.walletProvider);
  const { owned } = useSelector((state) => state.boonjiStudio);

  const dispatch = useDispatch();

  const handleConnectClick = async () => {
    if (!provider) await dispatch(showWalletProviderModal());

    if (address) {
      await dispatch(loadOwnedBoonjies());
    }
  };

  useEffect(async () => {
    if (owned.length == 0 && address) {
      try {
        await dispatch(loadOwnedBoonjies());
      } catch (error) {
        await dispatch(uiShowAlert("error", "Oops", error.toString()));
      }
    }
  }, [address]);

  // if we have selections stored, we will mark them
  useEffect(async () => {
    if (owned.length > 0 && address) {
      const storage = localStorageGet(address);
      if (storage.visor) {
        const tokenId = storage.visor.tokenId;
        if (owned.find((boonji) => boonji.tokenId === tokenId))
          dispatch(selectVisorTrait(storage.visor));
      }

      if (storage.background) {
        const tokenId = storage.background.tokenId;
        if (owned.find((boonji) => boonji.tokenId === tokenId))
          dispatch(selectBackgroundTrait(storage.background));
      }

      if (storage.ear) {
        const tokenId = storage.ear.tokenId;
        if (owned.find((boonji) => boonji.tokenId === tokenId))
          dispatch(selectEarTrait(storage.ear));
      }

      if (storage.body) {
        const tokenId = storage.body.tokenId;
        if (owned.find((boonji) => boonji.tokenId === tokenId))
          dispatch(selectBodyTrait(storage.body));
      }
    }
  }, [owned, address]);

  const [view, setView] = useState();

  const handleViewChange = (event, nextView) => {
    setView(nextView);
  };
  return (
    <div className="ownedBoonji">
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="left"
        alignItems="left"
      >
        <Grid item>
          <div className="ownedBoonji-title">
            All your owned Boonji
            <div className="ownedBoonji-amount">({owned.length})</div>
            <Tooltip
              title="Not showing 1/1 Boonjai"
              placement="right"
              TransitionComponent={Zoom}
            >
              <HelpIcon fontSize="small" />
            </Tooltip>
            &nbsp;
            {!isMobile ? (
              <div>
                <ToggleButtonGroup
                  orientation="horizontal"
                  value={view}
                  exclusive
                  onChange={handleViewChange}
                >
                  <ToggleButton
                    value="module"
                    aria-label="module"
                    style={{ color: "#DF7DA3", borderColor: "#DF7DA3" }}
                  >
                    <ViewModuleIcon />
                  </ToggleButton>
                  <ToggleButton
                    value="list"
                    aria-label="list"
                    style={{ color: "#DF7DA3", borderColor: "#DF7DA3" }}
                  >
                    <ViewListIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </Grid>

        {address ? (
          owned.length > 0 ? (
            <Grid
              container
              direction="row"
              justifyContent={{ xs: "center", md: "flex-start" }}
              alignItems="center"
              spacing={2}
              columns={{ xs: 4, md: 8 }}
            >
              {owned.map((boonji, index) => (
                <Grid item>
                  <div key={boonji?.tokenId ? boonji.tokenId : index}>
                    {view === "list" ? (
                      <BoonjiLine
                        key={boonji?.tokenId ? boonji.tokenId : index}
                        boonji={boonji}
                      />
                    ) : (
                      <BoonjiCard
                        key={boonji?.tokenId ? boonji.tokenId : index}
                        boonji={boonji}
                      />
                    )}
                  </div>
                </Grid>
              ))}
            </Grid>
          ) : (
            <div>
              <p>
                You don't own any Boonji. Search for your favourite one below by
                entering a token Id between 1 and 11,111.
              </p>
              <p>
                You can also buy Boonjies at our{" "}
                <a
                  href={process.env.REACT_APP_OPENSEA_COLLECTION}
                  target="_parent"
                >
                  OpenSea
                </a>{" "}
                collection.
              </p>
            </div>
          )
        ) : (
          <Grid item>
            <Button
              variant="outlined"
              style={{
                backgroundColor: "#674BA2",
                color: "#FFFFFF",
                border: "2px solid #A66ECD",
              }}
              onClick={handleConnectClick}
            >
              Connect wallet to get your Boonjai
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
