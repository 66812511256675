import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'

import {BoonjiTrait} from '../BoonjiTrait/BoonjiTrait'

require('./BoonjiTraitLine.css');

export const BoonjiTraitLine = props => {
    const {boonji} = props;
    
  return (
    <div >
        <Grid
            container 
            spacing={0}
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item>
                <div className='boonji-trait-line-item'>
                    <BoonjiTrait boonji={boonji} traitType={'body'}/>
                </div>
            </Grid>

            <Grid item>
                <div className='boonji-trait-line-item'>
                    <BoonjiTrait boonji={boonji} traitType={'visor'}/>
                </div>
            </Grid>

            <Grid item>
                <div className='boonji-trait-line-item'>
                    <BoonjiTrait boonji={boonji} traitType={'ear'}/>
                </div>
            </Grid>

            <Grid item>
                <div className='boonji-trait-line-item'>
                    <BoonjiTrait boonji={boonji} traitType={'background'}/>
                </div>
            </Grid>

            
        </Grid>
    </div>
  )
}

BoonjiTraitLine.propTypes = {
    boonji: PropTypes.object.isRequired
}
