import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import { useSelector } from 'react-redux'
import { Admin } from './components/Admin';

export const AdminRedeem = () => {
    const {address} = useSelector(state => state.walletProvider);
    const admins = (process.env.REACT_APP_ADMIN_ADDRESSES).split(',');

  return (
    <Box>
        {(admins.find(val => val === address)) ? (
            <Admin/>
        ) : (
            <Typography textAlign={'center'}>
                You are not an admin. Connect your admin wallet.
            </Typography>
        )}
    </Box>
  )
}
