import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux';

import svgToDataURL  from 'svg-to-dataurl';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Grid, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import Countdown from 'react-countdown';

import { uiCloseConfirmation, uiCloseInProgress, uiOpenInProgress, uiShowAlert, uiShowconfirmation2 } from '../../../reducers/uiReducer/uiActions';
import { SelectedBoonjai } from '../SelectedBoonjai/SelectedBoonjai';
import {SelectedTraits} from '../SelectedTraits/SelectedTraits'
import { FifthTrait } from '../FifthTrait/FifthTrait';
import { PurpleBtnRectClick } from '../../Buttons/PurpleBtnRectClick';
import { releaseReservationFifthTrait, verifyFifthTraitReservation } from '../../../reducers/fifthTrait/fifthTraitActions';
import { setBase64ImageURL, superBoonjiSave } from '../../../reducers/boonjiStudioReducer/boonjiStudioActions';
import {getBase64FromUrl} from '../../../utils/base64'

import {storage, ref, uploadString, getDownloadURL} from '../../../config/firebase'
import { InProgress } from './InProgress';
import {Confirmation2} from './Confirmation2';
import {Confirmation3} from './Confirmation3';

require('./Confirmation.css');
export const Confirmation = () => {
    const {preview} =  useSelector(state => state.boonjiStudio);
    const background = preview.background?.attributes.find(val => val.trait_type === 'background')
    const ear = preview.ear?.attributes.find(val => val.trait_type === 'ear')
    const visor = preview.visor?.attributes.find(val => val.trait_type === 'visor')
    const body = preview.body?.attributes.find(val => val.trait_type === 'body')
    const {fifthTrait} = preview;

    const [backgroundBase64, setBackgroundBase64] = useState();
    const [visorBase64, setVisorBase64] = useState();
    const [earBase64, setEarBase64] = useState();
    const [bodyBase64, setBodyBase64] = useState();
    const [fifthTraitBase64, setFifthTraitBase64] = useState();

    useEffect(async () => {
        if (background && background.url){
          const base64 = await getBase64FromUrl(background.url)
          setBackgroundBase64(base64)
        }
  
        if (body && body.url){
          const base64 = await getBase64FromUrl(body.url)
          setBodyBase64(base64)
        }
  
        if (ear && ear.url){
          const base64 = await getBase64FromUrl(ear.url)
          setEarBase64(base64)
        }
  
        if (visor && visor.url){
          const base64 = await getBase64FromUrl(visor.url)
          setVisorBase64(base64)
        }
  
        if (fifthTrait && fifthTrait.url){
          const base64 = await getBase64FromUrl(fifthTrait.url)
          setFifthTraitBase64(base64)
        }
  
      }, [background, ear, visor, body, fifthTrait, getBase64FromUrl])

    const {showConfirmation} = useSelector(state => state.ui);  
    const {uid} = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false)

    const handleOnClickNext = async () => {
        dispatch(uiOpenInProgress('Almost there...', 'Wait a moment while we prepare your awesome Super Boonji...'))
        try {
            // we verify we still have the fifth trait
            await dispatch(verifyFifthTraitReservation())

            const base64 = await getBase64();
            const url = await storeInStorage(base64);
            await dispatch(setBase64ImageURL(url))
            await dispatch(superBoonjiSave())
            await dispatch(uiShowconfirmation2());
        } catch ( error ) {
            dispatch(uiShowAlert('error', 'Oops', error.toString()));
        } finally {
            dispatch(uiCloseInProgress());
        }
    }

    const storeInStorage = async (base64) => {
        const superBoonji = 'superBoonji/' + uid + '.png'; 
        const superBoonjiRef = ref(storage, superBoonji);
        const metadata = {
            contentType: 'image/png',
            cacheControl: 'no-store'
          };
        const uploadTask = await uploadString(superBoonjiRef, base64, 'data_url', metadata);
        const url = await getDownloadURL(uploadTask.ref);
        return url;
    }

    const getBase64 = async () => {
            const svg =  document.getElementById('svg-generate-image')
            let svgData = new XMLSerializer().serializeToString(svg);
            const dataUrl = svgToDataURL(svgData);
            
            const canvas = document.createElement("canvas");
            canvas.setAttribute("id", "canvas");
            canvas.width = 3000;
            canvas.height = 2371;
    
            const img = document.createElement("img");
            img.setAttribute("src", dataUrl);
            return new Promise((resolve) => {
                img.onload = async function() {
                    canvas.getContext("2d").drawImage(img, 0, 0);
                    const base64 = canvas.toDataURL('image/png');
                    resolve(base64)
                }
            })
    }

    const handleOnClose = async () => {
        setIsDisabled(false);
        await dispatch(uiCloseConfirmation());
    }

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            setIsDisabled(true);
            dispatch(releaseReservationFifthTrait());
            return (<div>Time out!</div>);
        } else {
            // Render a countdown
            return <span>Countdown: 00:0{minutes}:{(seconds) < 10 ? '0' + seconds : seconds  }</span>;
        }
    };

    const handleOnClickCancel = async () => {    
        await dispatch(releaseReservationFifthTrait());    
        handleOnClose();
    }
  return (
    <div>
        <InProgress/>
        <Confirmation2/>
        <Confirmation3/>
        <Dialog
            open={showConfirmation}
            onClose={handleOnClose}
        >
            <DialogContent className='confirmation'>
                <Grid 
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        <div className='confirmation-invisible'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='3000px'
                                height='2371px'
                                id='svg-generate-image'
                            >
                                <image
                                    alt='background'
                                    href={backgroundBase64}
                                    id='background'
                                    width='3000px'
                                    height='2371px'
                                />

                                {(fifthTrait && fifthTrait.type === 'background') && (
                                    <image
                                        alt='background'
                                        href={fifthTraitBase64}
                                        id='background'
                                        width='3000px'
                                        height='2371px'
                                    />
                                )}

                                <image
                                    href={bodyBase64}
                                    id='body'
                                    width='3000px'
                                    height='2371px'
                                />
                                <image
                                    href={earBase64}
                                    id='ear'
                                    width='3000px'
                                    height='2371px'
                                />
                                <image
                                    href={visorBase64}
                                    id='visor'
                                    width='3000px'
                                    height='2371px'
                                />
                                {
                                    (fifthTrait && fifthTrait.type !== 'background') ? (
                                        <image
                                            href={fifthTraitBase64}
                                            idd='fifthTrait'
                                            width='3000px'
                                            height='2371px'
                                        />        
                                    ) : (
                                        <div></div>
                                    )
                                }
                            </svg>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className='confirmation-superBoonji'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='100%'
                                height='100%'
                            >
                                <image
                                    alt='background'
                                    href={backgroundBase64}
                                    id='background'
                                    width='100%'
                                    height='100%'
                                />
                                <image
                                    href={bodyBase64}
                                    id='body'
                                    width='100%'
                                    height='100%'
                                />
                                <image
                                    href={earBase64}
                                    id='ear'
                                    width='100%'
                                    height='100%'
                                />
                                <image
                                    href={visorBase64}
                                    id='visor'
                                    width='100%'
                                    height='100%'
                                />
                                {
                                    (fifthTrait) ? (
                                        <image
                                            href={fifthTraitBase64}
                                            idd='fifthTrait'
                                            width='100%'
                                            height='100%'
                                        />        
                                    ) : (
                                        <div></div>
                                    )
                                }
                            </svg>
                        </div>
                    </Grid>

                    <Grid item>
                        <div className='confirmation-title'>
                            New Super Boonji on the rise!
                        </div>
                    </Grid>
                    <Grid item>
                        <Grid 
                            container
                            spacing={1}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item>
                                <SelectedBoonjai/>
                            </Grid>
                            <Grid item>
                                <SelectedTraits readOnly={true}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Grid item className='confirmation-fifth-trait'>
                        <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item>
                                <FifthTrait readOnly={true}/>
                            </Grid>

                            {
                                (fifthTrait?.type === 'signature') ? (
                                    <div></div>
                                ) : (
                                    <Grid item>
                                        <div className='confirmation-fifth-trait-text'>
                                            reserved for 2 minutes.
                                        </div>  
                                        <div className='confirmation-fifth-trait-text'>
                                            <Countdown date={Date.now() + 120000} renderer={renderer}/>
                                        </div>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>
                    
                </Grid>
            </DialogContent>
            
            <DialogActions className='confirmation-actions'>
                <Grid 
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item>
                        <PurpleBtnRectClick text='Create Super Boonji' clickFunc={handleOnClickNext} disabled={isDisabled}/>
                    </Grid>

                    <Grid item>
                        <Button color="secondary" variant="contained" onClick={handleOnClickCancel}>Cancel</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    </div>
  )
}
