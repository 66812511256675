import moment from 'moment';

import { logError } from "../../utils/errors";
import {db, collection, runTransaction, doc, onSnapshot, getDoc } from '../../config/firebase';
import { fifthTraitTypes } from "./fifthTraitTypes";

export const subscribeFifthTraitHalo = () => {
    return async (dispatch) => {
        try {
            const haloCol = collection(db, 'traits/fifthTrait/halo/');
            onSnapshot(haloCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const halo = change.doc.data();
                    halo.type= 'halo';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitHalo(halo))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitHalo(halo))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitHalo = (halo) => ({
    type: fifthTraitTypes.addFifthTraitHalo,
    payload: halo
})

const updateFifthTraitHalo = (halo) => ({
    type: fifthTraitTypes.updateFifthTraitHalo,
    payload: halo
})

export const subscribeFifthTraitHeadphone = () => {
    return async (dispatch) => {
        try {
            const headphoneCol = collection(db, 'traits/fifthTrait/headphone/');
            onSnapshot(headphoneCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const headphone = change.doc.data();
                    headphone.type = 'headphone';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitHeadphone(headphone))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitHeadphone(headphone))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitHeadphone = (headphone) => ({
    type: fifthTraitTypes.addFifthTraitHeadphone,
    payload: headphone
})

const updateFifthTraitHeadphone = (headphone) => ({
    type: fifthTraitTypes.updateFifthTraitHeadphone,
    payload: headphone
})

export const subscribeFifthTraitDevilAngel = () => {
    return async (dispatch) => {
        try {
            const devilAngelCol = collection(db, 'traits/fifthTrait/devilAngel/');
            onSnapshot(devilAngelCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const devilAngel = change.doc.data();
                    devilAngel.type= 'devilAngel';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitDevilAngel(devilAngel))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitDevilAngel(devilAngel))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitDevilAngel = (devilAngel) => ({
    type: fifthTraitTypes.addFifthTraitDevilAngel,
    payload: devilAngel
})

const updateFifthTraitDevilAngel = (devilAngel) => ({
    type: fifthTraitTypes.updateFifthTraitDevilAngel,
    payload: devilAngel
})


export const subscribeFifthTraitNecklace = () => {
    return async (dispatch) => {
        try {
            const necklaceCol = collection(db, 'traits/fifthTrait/necklace/');
            onSnapshot(necklaceCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const necklace = change.doc.data();
                    necklace.type= 'necklace';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitNecklace(necklace))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitNecklace(necklace))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitNecklace = (necklace) => ({
    type: fifthTraitTypes.addFifthTraitNecklace,
    payload: necklace
})

const updateFifthTraitNecklace = (necklace) => ({
    type: fifthTraitTypes.updateFifthTraitNecklace,
    payload: necklace
})

export const subscribeFifthTraitSignature = () => {
    return async (dispatch) => {
        try {
            const signatureCol = collection(db, 'traits/fifthTrait/signature/');
            onSnapshot(signatureCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const signature = change.doc.data();
                    signature.type= 'signature';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitSignature(signature))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitSignature(signature))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitSignature = (signature) => ({
    type: fifthTraitTypes.addFifthTraitSignature,
    payload: signature
})

const updateFifthTraitSignature = (signature) => ({
    type: fifthTraitTypes.updateFifthTraitSignature,
    payload: signature
})


export const subscribeFifthTraitRocketMan = () => {
    return async (dispatch) => {
        try {
            const rocketManCol = collection(db, 'traits/fifthTrait/rocketMan/');
            onSnapshot(rocketManCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const rocketMan = change.doc.data();
                    rocketMan.type= 'rocketMan';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitRocketMan(rocketMan))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitRocketMan(rocketMan))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitRocketMan = (rocketMan) => ({
    type: fifthTraitTypes.addFifthTraitRocketMan,
    payload: rocketMan
})

const updateFifthTraitRocketMan = (rocketMan) => ({
    type: fifthTraitTypes.updateFifthTraitRocketMan,
    payload: rocketMan
})


export const subscribeFifthTraitVulcanEar = () => {
    return async (dispatch) => {
        try {
            const vulcanEarCol = collection(db, 'traits/fifthTrait/vulcanEar/');
            onSnapshot(vulcanEarCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const vulcanEar = change.doc.data();
                    vulcanEar.type= 'vulcanEar';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitVulcanEar(vulcanEar))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitVulcanEar(vulcanEar))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitVulcanEar = (vulcanEar) => ({
    type: fifthTraitTypes.addFifthTraitVulcanEar,
    payload: vulcanEar
})

const updateFifthTraitVulcanEar = (vulcanEar) => ({
    type: fifthTraitTypes.updateFifthTraitVulcanEar,
    payload: vulcanEar
})


export const subscribeFifthTraitHorn = () => {
    return async (dispatch) => {
        try {
            const hornCol = collection(db, 'traits/fifthTrait/horn/');
            onSnapshot(hornCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const horn = change.doc.data();
                    horn.type= 'horn';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitHorn(horn))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitHorn(horn))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitHorn = (horn) => ({
    type: fifthTraitTypes.addFifthTraitHorn,
    payload: horn
})

const updateFifthTraitHorn = (horn) => ({
    type: fifthTraitTypes.updateFifthTraitHorn,
    payload: horn
})

///////////////////

export const subscribeFifthTraitCrown = () => {
    return async (dispatch) => {
        try {
            const crownCol = collection(db, 'traits/fifthTrait/crown/');
            onSnapshot(crownCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const crown = change.doc.data();
                    crown.type= 'crown';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitCrown(crown))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitCrown(crown))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitCrown = (crown) => ({
    type: fifthTraitTypes.addFifthTraitCrown,
    payload: crown
})

const updateFifthTraitCrown = (crown) => ({
    type: fifthTraitTypes.updateFifthTraitCrown,
    payload: crown
})
///////////////////

export const subscribeFifthTraitWord = () => {
    return async (dispatch) => {
        try {
            const wordCol = collection(db, 'traits/fifthTrait/word/');
            onSnapshot(wordCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const word = change.doc.data();
                    word.type= 'word';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitWord(word))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitWord(word))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitWord = (word) => ({
    type: fifthTraitTypes.addFifthTraitWord,
    payload: word
})

const updateFifthTraitWord = (word) => ({
    type: fifthTraitTypes.updateFifthTraitWord,
    payload: word
})
///////////////////

export const subscribeFifthTraitFingerprint = () => {
    return async (dispatch) => {
        try {
            const fingerprintCol = collection(db, 'traits/fifthTrait/fingerprint/');
            onSnapshot(fingerprintCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const fingerprint = change.doc.data();
                    fingerprint.type= 'fingerprint';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitFingerprint(fingerprint))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitFingerprint(fingerprint))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitFingerprint = (fingerprint) => ({
    type: fifthTraitTypes.addFifthTraitFingerprint,
    payload: fingerprint
})

const updateFifthTraitFingerprint = (fingerprint) => ({
    type: fifthTraitTypes.updateFifthTraitFingerprint,
    payload: fingerprint
})
///////////////////


export const subscribeFifthTraitSpiral = () => {
    return async (dispatch) => {
        try {
            const spiralCol = collection(db, 'traits/fifthTrait/spiral/');
            onSnapshot(spiralCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const spiral = change.doc.data();
                    spiral.type= 'spiral';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitSpiral(spiral))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitSpiral(spiral))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitSpiral = (spiral) => ({
    type: fifthTraitTypes.addFifthTraitSpiral,
    payload: spiral
})

const updateFifthTraitSpiral = (spiral) => ({
    type: fifthTraitTypes.updateFifthTraitSpiral,
    payload: spiral
})
///////////////////


export const subscribeFifthTraitLady = () => {
    return async (dispatch) => {
        try {
            const ladyCol = collection(db, 'traits/fifthTrait/lady/');
            onSnapshot(ladyCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const lady = change.doc.data();
                    lady.type= 'lady';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitLady(lady))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitLady(lady))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitLady = (lady) => ({
    type: fifthTraitTypes.addFifthTraitLady,
    payload: lady
})

const updateFifthTraitLady = (lady) => ({
    type: fifthTraitTypes.updateFifthTraitLady,
    payload: lady
})
///////////////////

export const subscribeFifthTraitFormula = () => {
    return async (dispatch) => {
        try {
            const formulaCol = collection(db, 'traits/fifthTrait/formula/');
            onSnapshot(formulaCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const formula = change.doc.data();
                    formula.type= 'formula';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitFormula(formula))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitFormula(formula))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitFormula = (formula) => ({
    type: fifthTraitTypes.addFifthTraitFormula,
    payload: formula
})

const updateFifthTraitFormula = (formula) => ({
    type: fifthTraitTypes.updateFifthTraitFormula,
    payload: formula
})
///////////////////

export const subscribeFifthTraitRocketBoonji = () => {
    return async (dispatch) => {
        try {
            const rocketBoonjiCol = collection(db, 'traits/fifthTrait/rocketBoonji/');
            onSnapshot(rocketBoonjiCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const rocketBoonji = change.doc.data();
                    rocketBoonji.type= 'rocketBoonji';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitRocketBoonji(rocketBoonji))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitRocketBoonji(rocketBoonji))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitRocketBoonji = (rocketBoonji) => ({
    type: fifthTraitTypes.addFifthTraitRocketBoonji,
    payload: rocketBoonji
})

const updateFifthTraitRocketBoonji = (rocketBoonji) => ({
    type: fifthTraitTypes.updateFifthTraitRocketBoonji,
    payload: rocketBoonji
})
///////////////////

export const subscribeFifthTraitWalkingBoonji = () => {
    return async (dispatch) => {
        try {
            const walkingBoonjiCol = collection(db, 'traits/fifthTrait/walkingBoonji/');
            onSnapshot(walkingBoonjiCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const walkingBoonji = change.doc.data();
                    walkingBoonji.type= 'walkingBoonji';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitWalkingBoonji(walkingBoonji))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitWalkingBoonji(walkingBoonji))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitWalkingBoonji = (walkingBoonji) => ({
    type: fifthTraitTypes.addFifthTraitWalkingBoonji,
    payload: walkingBoonji
})

const updateFifthTraitWalkingBoonji = (walkingBoonji) => ({
    type: fifthTraitTypes.updateFifthTraitWalkingBoonji,
    payload: walkingBoonji
})
///////////////////

export const subscribeFifthTraitPuzzle = () => {
    return async (dispatch) => {
        try {
            const puzzleCol = collection(db, 'traits/fifthTrait/puzzle/');
            onSnapshot(puzzleCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const puzzle = change.doc.data();
                    puzzle.type= 'puzzle';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitPuzzle(puzzle))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitPuzzle(puzzle))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitPuzzle = (puzzle) => ({
    type: fifthTraitTypes.addFifthTraitPuzzle,
    payload: puzzle
})

const updateFifthTraitPuzzle = (puzzle) => ({
    type: fifthTraitTypes.updateFifthTraitPuzzle,
    payload: puzzle
})
///////////////////

export const subscribeFifthTraitChain = () => {
    return async (dispatch) => {
        try {
            const chainCol = collection(db, 'traits/fifthTrait/chain/');
            onSnapshot(chainCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const chain = change.doc.data();
                    chain.type= 'chain';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitChain(chain))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitChain(chain))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitChain = (chain) => ({
    type: fifthTraitTypes.addFifthTraitChain,
    payload: chain
})

const updateFifthTraitChain = (chain) => ({
    type: fifthTraitTypes.updateFifthTraitChain,
    payload: chain
})
///////////////////

export const subscribeFifthTraitBackground = () => {
    return async (dispatch) => {
        try {
            const backgroundCol = collection(db, 'traits/fifthTrait/background/');
            onSnapshot(backgroundCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const background = change.doc.data();
                    background.type= 'background';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitBackground(background))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitBackground(background))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitBackground = (background) => ({
    type: fifthTraitTypes.addFifthTraitBackground,
    payload: background
})

const updateFifthTraitBackground = (background) => ({
    type: fifthTraitTypes.updateFifthTraitBackground,
    payload: background
})
///////////////////

export const subscribeFifthTraitBody = () => {
    return async (dispatch) => {
        try {
            const bodyCol = collection(db, 'traits/fifthTrait/body/');
            onSnapshot(bodyCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const body = change.doc.data();
                    body.type= 'body';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitBody(body))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitBody(body))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitBody = (body) => ({
    type: fifthTraitTypes.addFifthTraitBody,
    payload: body
})

const updateFifthTraitBody = (body) => ({
    type: fifthTraitTypes.updateFifthTraitBody,
    payload: body
})
///////////////////

export const subscribeFifthTraitHelmet = () => {
    return async (dispatch) => {
        try {
            const helmetCol = collection(db, 'traits/fifthTrait/helmet/');
            onSnapshot(helmetCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const helmet = change.doc.data();
                    helmet.type= 'helmet';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitHelmet(helmet))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitHelmet(helmet))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitHelmet = (helmet) => ({
    type: fifthTraitTypes.addFifthTraitHelmet,
    payload: helmet
})

const updateFifthTraitHelmet = (helmet) => ({
    type: fifthTraitTypes.updateFifthTraitHelmet,
    payload: helmet
})
///////////////////

export const subscribeFifthTraitSnake = () => {
    return async (dispatch) => {
        try {
            const snakeCol = collection(db, 'traits/fifthTrait/snake/');
            onSnapshot(snakeCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const snake = change.doc.data();
                    snake.type= 'snake';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitSnake(snake))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitSnake(snake))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitSnake = (snake) => ({
    type: fifthTraitTypes.addFifthTraitSnake,
    payload: snake
})

const updateFifthTraitSnake = (snake) => ({
    type: fifthTraitTypes.updateFifthTraitSnake,
    payload: snake
})
///////////////////

export const subscribeFifthTraitVisor = () => {
    return async (dispatch) => {
        try {
            const visorCol = collection(db, 'traits/fifthTrait/visor/');
            onSnapshot(visorCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const visor = change.doc.data();
                    visor.type= 'visor';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitVisor(visor))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitVisor(visor))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitVisor = (visor) => ({
    type: fifthTraitTypes.addFifthTraitVisor,
    payload: visor
})

const updateFifthTraitVisor = (visor) => ({
    type: fifthTraitTypes.updateFifthTraitVisor,
    payload: visor
})
///////////////////

export const subscribeFifthTraitWig = () => {
    return async (dispatch) => {
        try {
            const wigCol = collection(db, 'traits/fifthTrait/wig/');
            onSnapshot(wigCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const wig = change.doc.data();
                    wig.type= 'wig';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitWig(wig))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitWig(wig))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitWig = (wig) => ({
    type: fifthTraitTypes.addFifthTraitWig,
    payload: wig
})

const updateFifthTraitWig = (wig) => ({
    type: fifthTraitTypes.updateFifthTraitWig,
    payload: wig
})
///////////////////

export const subscribeFifthTraitElephantEar = () => {
    return async (dispatch) => {
        try {
            const elephantEarCol = collection(db, 'traits/fifthTrait/elephantEar/');
            onSnapshot(elephantEarCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const elephantEar = change.doc.data();
                    elephantEar.type= 'elephantEar';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitElephantEar(elephantEar))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitElephantEar(elephantEar))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitElephantEar = (elephantEar) => ({
    type: fifthTraitTypes.addFifthTraitElephantEar,
    payload: elephantEar
})

const updateFifthTraitElephantEar = (elephantEar) => ({
    type: fifthTraitTypes.updateFifthTraitElephantEar,
    payload: elephantEar
})
///////////////////

export const subscribeFifthTraitMisc = () => {
    return async (dispatch) => {
        try {
            const miscCol = collection(db, 'traits/fifthTrait/misc/');
            onSnapshot(miscCol, async (snapshot) => {
                for (const change of snapshot.docChanges()){
                    const misc = change.doc.data();
                    misc.type= 'misc';
                    if (change.type === 'added'){
                        await dispatch(addFifthTraitMisc(misc))
                    }

                    if (change.type === 'modified'){
                        await dispatch(updateFifthTraitMisc(misc))
                    }
                }
            })
        } catch ( error ){
            await logError('Subscribing to Fifth trait snapshot', error);
            throw new Error ('We are unable to load the Fifth trait at this time. Please refresh and retry later.');
        }
    }
}

const addFifthTraitMisc = (misc) => ({
    type: fifthTraitTypes.addFifthTraitMisc,
    payload: misc
})

const updateFifthTraitMisc = (misc) => ({
    type: fifthTraitTypes.updateFifthTraitMisc,
    payload: misc
})
///////////////////

export const verifyFifthTraitReservation = () => {
    return async (dispatch, getState) => {
        const {uid} = getState().auth;

        try {
            if (!uid) {
                throw new Error ('You must be logged in to reserve the Fifth trait. Refresh ad sign with your wallet the pending message.');
            }

            const {preview} = getState().boonjiStudio;
            const {fifthTrait} = preview;

            // we won't verify it if it is a signature
            if (fifthTrait.type === 'signature')
                return;

            const ref = doc(db, 'traits', 'fifthTrait', fifthTrait.type, fifthTrait.name);
            const traitDoc = await getDoc(ref);
            const trait = traitDoc.data();
            if (trait.status !== 1 || trait.to !== uid){
                throw new Error(`Your fifth trait is no longer reserved to you. Try again.`)
            }

        } catch ( error ) {
            logError('Verify reservation of fifth trait', error, (uid) ? uid : 'NA' )
            if (error.message === 'Your fifth trait is no longer reserved to you. Try again.')
                throw error;
            else
                throw new Error ('We were unable to verify your fifth trait reservation. Please refresh and try again.')
        }
    }
}

export const reserveFifthTrait = () => {
    return async (dispatch, getState) => {
        const {uid} = getState().auth;

        try {
            if (!uid) {
                throw new Error ('You must be logged in to reserve the Fifth trait. Refresh ad sign with your wallet the pending message.');
            }

            const {preview} = getState().boonjiStudio;
            const {fifthTrait} = preview;

            // we won't reserve it if it is a signature
            if (fifthTrait.type === 'signature')
                return;

            const ref = doc(db, 'traits', 'fifthTrait', fifthTrait.type, fifthTrait.name);
                await runTransaction(db, async (transaction) => {
                    const traitDoc = await transaction.get(ref);
                    const trait = traitDoc.data();
                    if (trait.status && trait.status > 0 && trait.to !== uid){
                        throw new Error (`Trait ${fifthTrait.type}/${fifthTrait.name} is already reserved by someone else.`)
                    }
                    const reserved= { status:1, to: uid, timestamp: moment().unix()};
                    transaction.update(ref, reserved);
                  });
        } catch ( error ) {
            logError('Reserving fifth trait', error, (uid) ? uid : 'NA' )
            if (error.message === 'You must be logged in to reserve the Fifth trait. Refresh ad sign with your wallet the pending message.')
                throw error;
            else
                throw new Error ('We were unable to reserve this fifth trait. Please refresh and try again.')
        }
    }
}

export const releaseReservationFifthTrait = () => {
    return async (dispatch, getState) => {
        const {uid} = getState().auth;

        try {
            if (!uid) {
                throw new Error ('You must be logged in to undo a Fifth trait reservation');
            }

            const {preview} = getState().boonjiStudio;
            const {fifthTrait} = preview;
            const ref = doc(db,  'traits', 'fifthTrait', fifthTrait.type, fifthTrait.name);
            await runTransaction(db, async (transaction) => {
                const traitDoc = await transaction.get(ref);
                const trait = traitDoc.data();
                if (trait.status && trait.status === 1 && trait.to === uid){
                    const reserved = {status: 0, to: null, timestamp: 0};
                    transaction.update(ref,  reserved );
                }
                
            });
        } catch ( error ) {
            logError('Releases Reserv on fifth trait', error, (uid) ? uid : 'NA' )
            // if we coudln't releases the reserve, the function will do it for us
        }
    }
}

export const setUsedFifthTrait = () => {
    return async (dispatch, getState) => {
        const {uid} = getState().auth;

        try {
            if (!uid) {
                throw new Error ('You must be logged in to use a Fifth trait.');
            }

            const {preview} = getState().boonjiStudio;
            const {fifthTrait} = preview;
            const ref = doc(db,  'traits', 'fifthTrait', fifthTrait.type, fifthTrait.name);
            await runTransaction(db, async (transaction) => {
                const traitDoc = await transaction.get(ref);
                const trait = traitDoc.data();
                if (trait.to && trait.to === uid){
                    const reserved = {status: 2, to: uid, timestamp: moment().unix()};
                    transaction.update(ref,  reserved );
                }
                
            });
        } catch ( error ) {
            logError('Sets fifth trate as used.', error, (uid) ? uid : 'NA' )
            // if we coudln't releases the reserve, the function will do it for us
        }
    }
}