import React from 'react';

import './BoonjiProject.css'

const BoonjiProject = props =>{

    return(
        <section className='boonji-section'>
            <div className='boonji-section-container'>
                <div className='boonji-section-info'>
                    <h2>Boonji Project</h2>
                    <h3>EXPLORE THE GREAT BEYOND WITH BRENDAN MURPHY</h3>
                    <p>Boonji Project is a collection of 11,111 unique digital Non-Fungible Tokens launched on the Ethereum blockchain created by world-renowned artist Brendan Murphy. Your Boonji  Avatar NFT not only serves as artwork but also becomes the passport that allows you to travel with the Boonji Spaceman around the world and beyond, granting access to member-only events, merchandise, and physical/digital artwork. As the Boonji Spaceman reaches each new planet, the community can unlock a series of exciting rewards through roadmap activation. <br/><br/>
    Each Boonji Spaceman Avatar is unique and programmatically generated from a multitude of possible traits, including the Boonji Spaceman Bust, unique formulas, backgrounds, visors and more. All Boonji Spaceman Avatars feature elements from a lifetime of Brendan’s artwork. They include details from his original paintings, sculptures and monumental Boonji Spaceman sculptures that stand all over the world. Each Avatar is truly one of a kind. However, some are rarer than others based on the Algorithm generated when the Avatar is minted. These rarities have special powers that can unlock physical artwork and experiences.</p>
                </div>
                <div className='boonji-section-image-container'>
                    <img src="./images/boonji-logo-oct4-2.png" alt='Boonji'></img>
                </div>
            </div>
            
        </section>
    )
}

export default BoonjiProject;