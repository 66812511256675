const apiToken = process.env.REACT_APP_BOONJI_API_TOKEN;
const apiURL = process.env.REACT_APP_BOONJI_API_URL;

export const requestAuthMessage = async (address) => {
    const data = {address};
    const url = apiURL.concat('api/auth/getMessage');    
    const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiToken
        },
        body: JSON.stringify(data)
      });
      return response.json(); 
}

export const requestAuthToken = async (address, signature) => {
    const data = {address, signature};
    const url = apiURL.concat('api/auth/getToken');    
    
    const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiToken
        },
        body: JSON.stringify(data)
      });
      return response.json(); 
}

export const apiGetMyBoonjies = async (address) => {
  const data = {address};
  const url = apiURL.concat('api/boonji/getMyBoonjies');    
  
  const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiToken
      },
      body: JSON.stringify(data)
    });
    return response.json(); 
}

export const apiGetBalance = async (address) => {
  const data = {address};
  const url = apiURL.concat('api/boonji/getBalance');    
  
  const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiToken
      },
      body: JSON.stringify(data)
    });
    return response.json(); 
}

export const apiGetBoonji = async (tokenId) => {
  const data = {tokenId};
  const url = apiURL.concat('api/boonji/getBoonji');    
  
  const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiToken
      },
      body: JSON.stringify(data)
    });
    return response.json(); 
}


export const getMintPrice = async () => {
  const url = apiURL.concat('api/superBoonji/getMintPrice');    
  
  const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiToken
      }
    });
    return response.json(); 
}