import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { FifthTraitImage } from '../FifthTraitImage/FifthTraitImage';

require('./FifthTraitLine.css');
export const FifthTraitLine = props => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const {traits} = props;

  const [filteredTraits, setFilteredTraits] = useState(traits);

  function handleWindowSizeChange() {
    setScreenWidth(window.innerWidth);
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

useEffect(() => {
  setFilteredTraits(traits.filter(val => !val.status || val.status < 3));
}, [traits])



  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          infinite: true,
          dots: false
        }
      }
    ],
  };

  
  return (
    <div style={{width:(screenWidth < 800) ? '300px' : '750px'}}>
        <Slider {...settings}>
          {
            filteredTraits.map(trait => (
              <div key={trait.name}>
                  <FifthTraitImage key={trait.type + trait.name} trait={trait}/>
              </div>
            ))
          }
        </Slider>
      </div>
  )
}

FifthTraitLine.propTypes = {
  type: PropTypes.string.isRequired,
  traits: PropTypes.array.isRequired
}
