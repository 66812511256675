import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material';
import { BoonjiTrait } from '../BoonjiTrait/BoonjiTrait';
import './BoonjiCardTraitLine.css'

export const BoonjiTraitLine = props => {
    const {boonji} = props;
  return (
    <div className='boonjiCardTraitLine'>
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
        >
            <Grid item>
                <BoonjiTrait boonji={boonji} traitType={'body'}/>
            </Grid>
            <Grid item>
                <BoonjiTrait boonji={boonji} traitType={'visor'}/>
            </Grid>
            <Grid item>
                <BoonjiTrait boonji={boonji} traitType={'ear'}/>
            </Grid>
            <Grid item>
                <BoonjiTrait boonji={boonji} traitType={'background'}/>
            </Grid>
        </Grid>
    </div>
  )
}

BoonjiTraitLine.propTypes = {
    boonji: PropTypes.object.isRequired
}
