import { Grid } from '@mui/material'
import React from 'react'
import { RedeemToken } from '../RedeemToken/RedeemToken'

export const RedeemTokenList = () => {
  return (
    <Grid 
        container
        direction={'row'}
        justifyContent={'center'}
        alignItems={'flex-start'}
        spacing={2}
    >
        <Grid item xs={12} md={4}>
            <RedeemToken tokenId={1}/>
        </Grid>

        <Grid item xs={12} md={4}>
            <RedeemToken tokenId={2}/>
        </Grid>

        <Grid item xs={12} md={4}>
            <RedeemToken tokenId={3}/>
        </Grid>

        <Grid item xs={12} md={6}>
            <RedeemToken tokenId={4}/>
        </Grid>

        <Grid item xs={12} md={6}>
            <RedeemToken tokenId={5}/>
        </Grid>

    </Grid>
  )
}
