import { Grid } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux'
import { PreviewMobileTrait } from './PreviewMobileTrait';

export const PreviewMobileTraitLine = () => {
    const {preview} = useSelector(state => state.boonjiStudio);
    const {background, visor, ear, body, fifthTrait} = preview;

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <PreviewMobileTrait selected={(body) ? true : false}/>
        </Grid>

        <Grid item>
          <PreviewMobileTrait selected={(visor) ? true : false}/>
        </Grid>

        <Grid item>
          <PreviewMobileTrait selected={(ear) ? true : false}/>
        </Grid>

        <Grid item>
          <PreviewMobileTrait selected={(background) ? true : false}/>
        </Grid>

        <Grid item>
          <PreviewMobileTrait selected={(fifthTrait) ? true : false}/>    
        </Grid>
      </Grid>
    </div>
  )
}
