import { Grid, Typography } from '@mui/material'
import React from 'react'
import { tokens } from 'pages/NewRedemption/redemptionTokens'
import { RedeemTokenList } from './components/RedeemTokenList'
import { SuccessDialog } from '../SuccessDialog'
export const Balance = () => {
  return (
    <Grid
        container
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={3}
    >
        <Grid item>
            <RedeemTokenList/>
        </Grid>

        <Grid item>
            <Typography textAlign={'center'}>
                {tokens[0].about}
            </Typography>
        </Grid>
        <SuccessDialog />
    </Grid>
  )
}
