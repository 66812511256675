import React from "react";
import LinkBtn from "../../../components/Buttons/LinkBtn";

import "./Tools.css";

const Tools = (props) => {
  return (
    <section className="tools-section">
      <h2>Communji Tools</h2>
      <p>
        Whether you're joining our community on Discord or on Twitter, we're
        glad to have you with us!
      </p>
      <div className="tools-links">
        <LinkBtn
          title="TWITTER"
          link="https://twitter.com/BoonjiProject"
        ></LinkBtn>
        <LinkBtn
          title="DISCORD SERVER"
          link="https://discord.com/invite/boonjiproject"
        ></LinkBtn>
        {/* <LinkBtn title="JUPITER GROUP" link="https://www.jupitergroup.io/"></LinkBtn> */}
      </div>
    </section>
  );
};

export default Tools;
