import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Button, Grid } from '@mui/material';


import { uiCloseConfirmation3 } from '../../../reducers/uiReducer/uiActions';
import { Preview } from '../Preview/Preview';
import { resetStudio } from '../../../reducers/boonjiStudioReducer/boonjiStudioActions';

import './Confirmation3.css';
export const Confirmation3 = () => {
    const dispatch = useDispatch();
    const {showConfirmation3} = useSelector(state => state.ui);

    const handleOnClose = () => {
        dispatch(resetStudio());
        dispatch(uiCloseConfirmation3());
        
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
          }
        window.location.reload();
    }
  return (
    <div >
        <Dialog
            open={showConfirmation3}
            onClose={handleOnClose}
            className='confirmation3'
        >
            <DialogContent className='confirmation2-background'>
                <Grid 
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item >
                        <div className='confirmation2-title'>
                            Super Boonji ready
                        </div>
                    </Grid>
                    <Grid item>
                        <Preview/>
                    </Grid>

                    <Grid item>
                        <div className='confirmation2-mint'>
                            Check at <a href={process.env.REACT_APP_OPENSEA_COLLECTION_SUPERBOONJI} target='_blank' rel="noopener noreferrer" className='confirmation3'>OpenSea</a>
                        </div>
                        <div className='confirmation3-description'>
                            (It might take some minutes to be available)
                        </div>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleOnClose}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    </div>
  )
}
