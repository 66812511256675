import React from "react";

import './Phase1.css'

const Phase1 = props =>{

    return(
        <div>
            <div className="roadmap-phase-number">PHASE 1</div>
            <h3 className="road-map-h3">PUBLIC SALE - DUTCH AUCTION</h3>
            <h2 className="road-map-h2">Boonji Project Launch</h2>
            <ul className="redemption-ul">
                <li key="road1">
                    <div>
                        <span>First 200 NFTs sold will come with a </span>
                        <a 
                            href="https://twitter.com/BoonjiProject/status/1446950606954979332"
                            target="_blank"
                            rel='noreferrer'>
                            physical Boonji Spaceman sculpture 
                        </a>
                    </div>                       
                </li>
                <li key="road2">
                    <div>
                        <span>Every 33rd sale after the first 200 will also come with a </span>
                        <a 
                            href="https://twitter.com/BoonjiProject/status/1446950606954979332"
                            target="_blank"
                            rel='noreferrer'>
                            physical Boonji Spaceman sculpture
                        </a>
                    </div>                       
                </li>
                <li key="road3">
                    <div>
                        <span>30 randomly-selected participants in the Public Sale will receive a 1/1 physical painting </span>
                    </div>                       
                </li>
                <li key="road4">
                    <div>
                        <span>Buying 5+ NFTs during the public auction will put you in the running to win one monumental </span>
                        <a 
                            href="https://twitter.com/BoonjiProject/status/1447281881523892225"
                            target="_blank"
                            rel='noreferrer'>
                            physical Boonji Spaceman sculpture
                        </a>
                        <span> retailed at $750,000 for FREE </span>
                    </div>                       
                </li>
                <li key="road5">
                    <div>
                        <span>$5,000,000+ USD worth of art will be given out to public sale minters </span>
                    </div>                       
                </li>
                <li key="road6">
                    <div>
                        <span>Hi-res images for print </span>
                    </div>                       
                </li>
            </ul>
        </div>
    )

}

export default Phase1;