import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { AppBar, Button, IconButton, Toolbar } from '@mui/material';
import svgToDataURL  from 'svg-to-dataurl';

import {BrowserView, MobileView} from 'react-device-detect';
import { saveAs } from 'file-saver'

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';

import { uiCloseMaxPreview, uiShowAlert, uiShowconfirmation } from '../../../reducers/uiReducer/uiActions';

import {noBackground} from '../../../assets/superBoonji/preview/background.js';
import {noBody} from '../../../assets/superBoonji/preview/body.js';
import {noEar} from '../../../assets/superBoonji/preview/ear.js';
import {noVisor} from '../../../assets/superBoonji/preview/visor.js';
import {getBase64FromUrl} from '../../../utils/base64';
import { reserveFifthTrait } from '../../../reducers/fifthTrait/fifthTraitActions';


require('./MaxPreview.css');
export const MaxPreview = () => {
    const {preview} =  useSelector(state => state.boonjiStudio);
    const background = preview.background?.attributes.find(val => val.trait_type === 'background')
    const ear = preview.ear?.attributes.find(val => val.trait_type === 'ear')
    const visor = preview.visor?.attributes.find(val => val.trait_type === 'visor')
    const body = preview.body?.attributes.find(val => val.trait_type === 'body')
    const {fifthTrait} = preview;
    
    const [backgroundBase64, setBackgroundBase64] = useState();
    const [visorBase64, setVisorBase64] = useState();
    const [earBase64, setEarBase64] = useState();
    const [bodyBase64, setBodyBase64] = useState();
    const [fifthTraitBase64, setFifthTraitBase64] = useState();

    const isMintEnabled = (process.env.REACT_APP_ENABLE_MINT === 'true' ? true : false);

      const handleClickDownload = async () => {
        await convertToImageAndSave();
      }

    const {showMaxPreview} =  useSelector(state => state.ui);
    const dispatch = useDispatch();
    
    const handleOnClose = () => {
        dispatch(uiCloseMaxPreview())
    }

    useEffect(async () => {
      if (background && background.url){
        const base64 = await getBase64FromUrl(background.url)
        setBackgroundBase64(base64)
      }

      if (body && body.url){
        const base64 = await getBase64FromUrl(body.url)
        setBodyBase64(base64)
      }

      if (ear && ear.url){
        const base64 = await getBase64FromUrl(ear.url)
        setEarBase64(base64)
      }

      if (visor && visor.url){
        const base64 = await getBase64FromUrl(visor.url)
        setVisorBase64(base64)
      }

      if (fifthTrait && fifthTrait.url){
        const base64 = await getBase64FromUrl(fifthTrait.url)
        setFifthTraitBase64(base64)
      }

    }, [background, ear, visor, body, fifthTrait, getBase64FromUrl])
    


    const convertToImageAndSave = async () =>{
        const svg = document.getElementById('svg-studio-max');
        let svgData = new XMLSerializer().serializeToString(svg);
        const dataUrl = svgToDataURL(svgData);
        
        const canvas = document.createElement("canvas");
        canvas.setAttribute("id", "canvas");
        canvas.width = 3000;
        canvas.height = 2371;

        const img = document.createElement("img");
        img.setAttribute("src", dataUrl);
        img.onload = async function() {
            canvas.getContext("2d").drawImage(img, 0, 0);
            const superBoonji = canvas.toDataURL('image/png');
            saveAs(superBoonji, 'superboonji.png')
        }
    }

    const [isNextDisabled, setIsNextDisabled] = useState(false);
    
    const handleClickNext = async () => {
        try {
            // we reserve the fith trait        
            await dispatch(reserveFifthTrait())
            await dispatch(uiShowconfirmation());
        } catch ( error ) {
            dispatch(uiShowAlert('warning', '5th Trait error', error.toString()));
        }
    }
    useEffect(() => {
        const {boonjies, body, visor, ear, background, fifthTrait} = preview;
        if (boonjies.length === 4 && !boonjies.find(boonji => !boonji.owned)&& body && visor && ear && background && fifthTrait)
            setIsNextDisabled(false);
        else
            setIsNextDisabled(true);
      }, [preview])
  return (
    <div className='maxPreview'>
        <Dialog 
            open={showMaxPreview}
            onClose={handleOnClose}
            fullScreen
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar className='maxPreview-menu'>
                    <IconButton
                    edge="start"
                    onClick={handleOnClose}
                    aria-label="close"
                    >
                    <CloseIcon />
                    </IconButton>
                        Super Boonji
                </Toolbar>
            </AppBar>
            <DialogContent className='maxPreview' >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='100%'
                        height='100%'
                        id='svg-studio-max'
                    >
                        <image
                            alt='background'
                            href={(background) ? backgroundBase64 : noBackground}
                            id='background'
                            width='100%'
                            height='100%'
                        />

                        {(fifthTrait && fifthTrait.type === 'background') && (
                            <image
                                href={fifthTrait.url}
                            />            
                        )}
                        
                        <image
                            href={(body) ? bodyBase64 : noBody}
                            id='body'
                            width='100%'
                            height='100%'
                        />
                        <image
                            href={(ear) ? earBase64 : noEar}
                            id='ear'
                            width='100%'
                            height='100%'
                        />
                        <image
                            href={(visor) ? visorBase64 : noVisor}
                            id='visor'
                            width='100%'
                            height='100%'
                        />
                        {
                            (fifthTrait && fifthTrait.type !== 'background') ? (
                                <image
                                    href={fifthTraitBase64}
                                    id='fifthTrait'
                                    width='100%'
                                    height='100%'
                                />        
                            ) : (
                                <div></div>
                            )
                        }
                    </svg>
            </DialogContent>

            <DialogActions>
            <BrowserView>
                <Button autoFocus color="inherit" onClick={handleClickDownload}>
                    Download
                </Button>
            </BrowserView>
            <MobileView>
                {(isMintEnabled) ? (
                    <Button color="warning" disabled={isNextDisabled} onClick={handleClickNext}>
                        Next
                    </Button>        
                ) : (
                    <div></div>
                )}
            </MobileView>
                
            </DialogActions>
        </Dialog>
        {/* <Confirmation/> */}
    </div>
  )
}
