export const fifthTraitTypes = {
    addFifthTraitHalo: '[fifthTrait] adds a new halo fifth trait ',
    updateFifthTraitHalo: '[fifthTrait] updates a halo fifth trait',

    addFifthTraitHeadphone: '[fifthTrait] adds a new Headphone fifth trait ',
    updateFifthTraitHeadphone: '[fifthTrait] updates a Headphone fifth trait',

    addFifthTraitDevilAngel: '[fifthTrait] adds a new DevilAngel fifth trait ',
    updateFifthTraitDevilAngel: '[fifthTrait] updates a DevilAngel fifth trait',

    addFifthTraitSignature: '[fifthTrait] adds a new Signature fifth trait ',
    updateFifthTraitSignature: '[fifthTrait] updates a Signature fifth trait',

    addFifthTraitRocketMan: '[fifthTrait] adds a new RocketMan fifth trait ',
    updateFifthTraitRocketMan: '[fifthTrait] updates a RocketMan fifth trait',

    addFifthTraitVulcanEar: '[fifthTrait] adds a new VulcanEar fifth trait ',
    updateFifthTraitVulcanEar: '[fifthTrait] updates a VulcanEar fifth trait',

    addFifthTraitNecklace: '[fifthTrait] adds a new Necklace fifth trait ',
    updateFifthTraitNecklace: '[fifthTrait] updates a Necklace fifth trait',

    addFifthTraitCrown: '[fifthTrait] adds a new Crown fifth trait',
    updateFifthTraitCrown: '[fifthTrait] updates a Crown fifth trait',

    addFifthTraitHorn: '[fifthTrait] adds a new Horn fifth trait',
    updateFifthTraitHorn: '[fifthTrait] updates a Horn fifth trait',

    addFifthTraitWord: '[fifthTrait] adds a new Word fifth trait',
    updateFifthTraitWord: '[fifthTrait] updates a Word fifth trait',

    addFifthTraitFingerprint: '[fifthTrait] adds a new Fingerprint fifth trait',
    updateFifthTraitFingerprint: '[fifthTrait] updates a Fingerprint fifth trait',

    addFifthTraitSpiral: '[fifthTrait] adds a new Spiral fifth trait',
    updateFifthTraitSpiral: '[fifthTrait] updates a Spiral fifth trait',

    addFifthTraitLady: '[fifthTrait] adds a new Lady fifth trait',
    updateFifthTraitLady: '[fifthTrait] updates a Lady fifth trait',

    addFifthTraitChain: '[fifthTrait] adds a new Chain fifth trait',
    updateFifthTraitChain: '[fifthTrait] updates a Chain fifth trait',

    addFifthTraitWalkingBoonji: '[fifthTrait] adds a new WalkingBoonji fifth trait',
    updateFifthTraitWalkingBoonji: '[fifthTrait] updates a WalkingBoonji fifth trait',

    addFifthTraitRocketBoonji: '[fifthTrait] adds a new Rocket Boonji fifth trait',
    updateFifthTraitRocketBoonji: '[fifthTrait] updates a Rocket Boonji fifth trait',

    addFifthTraitPuzzle: '[fifthTrait] adds a new Puzzle fifth trait',
    updateFifthTraitPuzzle: '[fifthTrait] updates a Puzzle fifth trait',

    addFifthTraitFormula: '[fifthTrait] adds a new Formula fifth trait',
    updateFifthTraitFormula: '[fifthTrait] updates a Formula fifth trait',

    // SB2
    addFifthTraitBackground: '[fifthTrait] adds a new Background fifth trait',
    updateFifthTraitBackground: '[fifthTrait] updates a Background fifth trait',
    addFifthTraitBody: '[fifthTrait] adds a new Body fifth trait',
    updateFifthTraitBody: '[fifthTrait] updates a Body fifth trait',
    addFifthTraitHelmet: '[fifthTrait] adds a new Helmet fifth trait',
    updateFifthTraitHelmet: '[fifthTrait] updates a Helmet fifth trait',
    addFifthTraitSnake: '[fifthTrait] adds a new Snake fifth trait',
    updateFifthTraitSnake: '[fifthTrait] updates a Snake fifth trait',
    addFifthTraitVisor: '[fifthTrait] adds a new Visor fifth trait',
    updateFifthTraitVisor: '[fifthTrait] updates a Visor fifth trait',
    addFifthTraitWig: '[fifthTrait] adds a new Wig fifth trait',
    updateFifthTraitWig: '[fifthTrait] updates a Wig fifth trait',
    addFifthTraitElephantEar: '[fifthTrait] add a elephant ear fifth trait',
    updateFifthTraitElephantEar: '[fifthTrait] updates a elephant ear fifth trait',
    addFifthTraitMisc: '[fifthTrait] add a misc fifth trait',
    updateFifthTraitMisc: '[fifthTrait] updates a misc fifth trait',

}