import clone from 'just-clone';

import { apiGetMyBoonjies, apiGetBalance, apiGetBoonji } from "../../utils/api"
import { boonjiStudioTypes } from "./boonjiStudioTypes"
import {logError} from '../../utils/errors'
import {db, doc, setDoc, runTransaction, getDoc} from '../../config/firebase';
import {localStorageAdd} from '../../utils/localStorage';

export const loadOwnedBoonjies = () => {
    return async (dispatch, getState) => {
        try {
            const {address} = getState().walletProvider
            // we display the loading ones first
            let response = await apiGetBalance(address);
            if (response){
                const balance = response.message;
                await dispatch(setLoadingBoonjies(Number(balance)));
            }
            response = await apiGetMyBoonjies(address)
            if (response.ok){
                const {message} = response;
                // we add the owned bool value
                const boonjies = message.map(boonjie => ({...boonjie, owned:true}));
                await dispatch(setOwnedBoonjies(boonjies));
            } else {
                const error = response.message
                throw new Error (error);
            }
        } catch ( error ) {
            await logError('Loading owned boonjies', error);
            throw new Error ('Unable to load your boonjai. Please refresh and retry later.');
        }
    }
}

const setBodyTrait = (trait) => ({
    type: boonjiStudioTypes.selectBodyTrait,
    payload: trait
}) 

export const selectBodyTrait = (trait) => {
    return async (dispatch, getState) => {
        try {
            const {address} = getState().walletProvider;
            if (address)
                localStorageAdd(address, {body: trait});
        } catch ( error ) {
            // ignored
        } finally {
            await dispatch(setBodyTrait(trait))
            const {preview} = getState().boonjiStudio;
            const {boonjies} = preview;
            if (trait && !boonjies.find(val => val.tokenId === trait?.tokenId)){
                dispatch(addBoonjiToBurn(trait))
            }
        }
    }
}

const setVisorTrait = (trait) => ({
    type: boonjiStudioTypes.selectVisorTrait,
    payload: trait
})

export const selectVisorTrait = (trait) => {
    return async (dispatch, getState) => {
        try {
            const {address} = getState().walletProvider;
            if (address)
                localStorageAdd(address, {visor: trait});
        } catch ( error ) {
            // ignored
        } finally {
            await dispatch(setVisorTrait(trait))
            const {preview} = getState().boonjiStudio;
            const {boonjies} = preview;
            if (trait && !boonjies.find(val => val.tokenId === trait?.tokenId)){
                dispatch(addBoonjiToBurn(trait))
            }
        }
    }
}


const setEarTrait = (trait) => ({
    type: boonjiStudioTypes.selectEarTrait,
    payload: trait
})

export const selectEarTrait = (trait) => {
    return async (dispatch, getState) => {
        try {
            const {address} = getState().walletProvider;
            if (address)
                localStorageAdd(address, {ear: trait});
        } catch ( error ) {
            // ignored
        } finally {
            await dispatch(setEarTrait(trait))
            const {preview} = getState().boonjiStudio;
            const {boonjies} = preview;
            if (trait && !boonjies.find(val => val.tokenId === trait?.tokenId)){
                dispatch(addBoonjiToBurn(trait))
            }
        }
    }
}

const setBackgroundTrait = (trait) => ({
    type: boonjiStudioTypes.selectBackgroundTrait,
    payload: trait
}) 

export const selectBackgroundTrait = (trait) => {
    return async (dispatch, getState) => {
        try {
            const {address} = getState().walletProvider;
            if (address)
                localStorageAdd(address, {background: trait});
        } catch ( error ) {
            // ignored
        } finally {
            await dispatch(setBackgroundTrait(trait))
            
            const {preview} = getState().boonjiStudio;
            const {boonjies} = preview;
            
            if (trait && !boonjies.find(val => val.tokenId === trait?.tokenId)){
                dispatch(addBoonjiToBurn(trait))
            }
        }
    }
}

const setFifthTrait = (trait) => ({
    type: boonjiStudioTypes.selectFifthTrait,
    payload: trait
})


export const selectFifthTrait = (trait) => {
    return async (dispatch, getState) => {
        const {uid} = getState().auth;
        try {
            // if the trait is not null and being selected other than signature, we record it.
            if (trait && uid && trait.type !== 'signature') {
                // we get it and increase the picked value in a transaction
                const traitRef = doc(db, 'traits', 'fifthTrait', trait.type, trait.name);
                await runTransaction(db, async (transaction) => {
                    const traitDoc = await transaction.get(traitRef);
                    const picked = (traitDoc.data().picked) ? traitDoc.data().picked : [];
                    if (!picked.find(val => val === uid)){
                        picked.push(uid);
                        transaction.update(traitRef, { picked });
                    }
                  });
            }
        } catch ( error ) {
            logError('saving fifthTrait being selected', error, (uid) ? uid : 'N/A' )
        } finally {
            try {
                const {address} = getState().walletProvider;
                if (address)
                    localStorageAdd(address, {fifthTrait: trait});
            } catch ( error ) {
                // ignored
            } finally {
                dispatch(setFifthTrait(trait));
            }            
        }
    }
}

const setOwnedBoonjies = (ownedBoonjies) => ({
    type: boonjiStudioTypes.setOwnedBoonjies,
    payload: ownedBoonjies
})

export const setLoadingBoonjies = (amount) => {
    return async(dispatch) => {
        const loadingBoonjie = {
            "attributes": [
                {
                    "value": "",
                    "trait_type": "background",
                    "url": "https://i.gifer.com/EXfm.gif"
                },
                {
                    "trait_type": "body",
                    "value": "",
                    "url": "https://i.gifer.com/EXfm.gif"
                },
                {
                    "value": "",
                    "trait_type": "ear",
                    "url": "https://i.gifer.com/EXfm.gif"
                },
                {
                    "trait_type": "visor",
                    "value": "",
                    "url": "https://i.gifer.com/EXfm.gif"
                }
            ],
            "name": "",
            "owned": true,
            "external_url": "https://boonjiproject.com/",
            "image": "https://uploads-ssl.webflow.com/61defc8fd2e97f97b6ba9865/620e652f0b78845e23425444_loading-boonji.gif",
            "tokenId": 0,
            "description": "Boonji Project is a collection of 11,111 unique digital Non-Fungible Tokens launched on the Ethereum blockchain created by world-renowned artist Brendan Murphy. Your Boonji Avatar NFT not only serves as artwork but also becomes the passport that allows you to travel with the Boonji Spaceman.",
            "timestamp": {
                "_seconds": 1644851056,
                "_nanoseconds": 524000000
            }
        }

        const boonjies = [];
        for (let i=0; i<amount; i++){
            boonjies.push(loadingBoonjie);
        }

        await dispatch(setOwnedBoonjies(boonjies))
    }
}

export const addBoonjiToBurn = (boonji) => ({
    type: boonjiStudioTypes.addBoonjiToBurn,
    payload: boonji
})

export const removeBoonjiToBurn = (boonji) => ({
    type: boonjiStudioTypes.removeBoonjiToBurn,
    payload: boonji
})

const addNotOwnedBoonji = (boonji) => ({
    type: boonjiStudioTypes.addNotOwnedBoonji,
    payload: boonji
})

export const addNewNotOwnedBoonji = (tokenId) => {
    return async (dispatch) => {
        try {
            const response = await apiGetBoonji(tokenId);
            if (response){
                const boonji = response.message
                dispatch(addNotOwnedBoonji({...boonji, owned: false}));
            } else {
                const error = response.message
                throw new Error (error);
            }
        } catch ( error ) {
            await logError('Loading not owned boonjies', error);
            throw new Error ('Unable to load this boonji. Please refresh and retry later.');
        }
    }
}

export const resetStudio = () =>{
    return (dispatch, getState) => {
        dispatch(setBodyTrait(null));
        dispatch(setBackgroundTrait(null));
        dispatch(setEarTrait(null));
        dispatch(setVisorTrait(null));
        dispatch(setFifthTrait(null));

        const {preview} = getState().boonjiStudio;
        const {boonjies} = preview;
        
        for (const boonji of boonjies){
            dispatch(removeBoonjiToBurn(boonji));
        }
    }
}


export const superBoonjiSave = () => {
    return async (dispatch, getState) => {
        try {
            const {preview} = getState().boonjiStudio;
            const {uid} = getState().auth;
            
            const ref = doc(db, 'users', uid, 'superBoonji', 'current');
            await setDoc(ref, preview, {merge: true});
        } catch ( error ) {
            logError(error)
            throw new Error('Unable to store your Super Boonji information. Please refresh and retry later.');
        }
    }
}

// Status of process is:
// 1 isApprovedForAll is confirmed
// 2 transaction to mintAndBurn is signed
// 3 transaction to mintAndBurn is confirmed
// 4 superBoonji metadata is placed on storage so superBoonji is minted
// 5 burned boonjai metadatas has been modified

export const superBoonjiApprovedForAll = (txHash) => {
    return async (dispatch, getState) => {
        try {
            const {uid} = getState().auth;
            const ref = doc(db, 'users', uid, 'superBoonji', txHash);
            await setDoc(ref, {approvedHash: txHash, status: 1}, {merge:true});
        } catch ( error ) {
            logError(error)
            throw new Error('Unable to store your Super Boonji information. Please refresh and retry later.');
        }
    }
}


export const superBoonjiAddTx = (txHash) => {
    return async (dispatch, getState) => {
        try {
            const {uid} = getState().auth;
            const currentRef = doc(db, 'users', uid, 'superBoonji', 'current');
            const snapshot = await getDoc(currentRef);
            const preview = snapshot.data();
            const ref = doc(db, 'users', uid, 'superBoonji', txHash);
            await setDoc(ref, {...preview, mintTx: txHash, status: 2});
        } catch ( error ) {
            logError(error)
            throw new Error('Unable to store your Super Boonji information. Please refresh and retry later.');
        }
    }
}

export const superBoonjiConfirmTx = (txHash) => {
    return async (dispatch, getState) => {
        try {
            const {uid} = getState().auth;
            const ref = doc(db, 'users', uid, 'superBoonji', txHash);
            await setDoc(ref, {mintTx: txHash, status: 3}, {merge:true});
        } catch ( error ) {
            logError(error)
            throw new Error('Unable to store your Super Boonji information. Please refresh and retry later.');
        }
    }
}

export const setBase64ImageURL = (url) => ({
    type: boonjiStudioTypes.saveBase64ImageURL,
    payload: url
})

