export const uiTypes = {
    uiCloseAlert: '[UI] - Hides alert message',
    uiOpenAlert: '[UI] - Shows the alert message',

    uiMaxPreviewOpen: '[UI] Opens the maximized Preview',
    uiMaxPreviewClose: '[UI] Closes the maximized Preview',

    uiConfirmationOpen: '[UI] Opens the confirmation screen',
    uiConfirmationClose: '[UI] Closes the confirmation screen',

    uiConfirmation2Open: '[UI] Opens the confirmation2 screen',
    uiConfirmation2Close: '[UI] Closes the confirmation2 screen',

    uiConfirmation3Open: '[UI] Opens the confirmation3 screen',
    uiConfirmation3Close: '[UI] Closes the confirmation3 screen',

    uiInProgressOpen: '[UI] Opens the InProgress screen',
    uiInProgressClose: '[UI] Closes the inProgress screen',

    uiOpenBackdrop: '[UI] - opens the backdrop',
    uiCloseBackdrop: '[UI] - close the backdrop',

}