import React, { useState } from 'react';

import './CrewUI.css'

const CrewUI = props =>{

    const[activePopup, setActiveState] = useState(false)
    
    const clickHandler = ()=>{

        setActiveState(!activePopup);
    }

    return(
        <div className="crew-ui-container">

            {props.bioInfoPopup && activePopup &&
                <div className="crew-ui-popup">
                    <div className='crew-ui-popup-x-div'> 
                        <img onClick={clickHandler} src="./images/x-is-for-close.svg" alt="" />
                    </div>
                    <h4>{props.name}</h4>
                    <div className='crew-ui-popup-position'>{props.position}</div>
                    <div className="crew-ui-popup-divider"></div>
                    {props.bioInfo.map((info, index) =>{
                        return(
                            <p key={index} className="crew-ui-popup-p">{info}</p>
                        )
                    })}     
                </div>         
            }
            
            <div className="crew-ui-img"><img src={props.img} alt=""/></div>
            <div className="crew-ui-name">{props.name}</div>
            <div className="crew-ui-position">{props.position}</div>
            {props.twitter !== "" &&
                <div className="crew-ui-social">                   
                    <img src="./images/Twitter.svg" alt=""/>
                    <a href={props.twitterLink} target="_Blank" rel='noreferrer'>{props.twitter}</a>                    
                </div>
            }
            {props.instagram !== "" &&
                <div className="crew-ui-social">
                    <img src="./images/instagram-icon.svg" alt=""/>
                    <a href={props.instagramLink} target="_Blank" rel='noreferrer'>{props.instagram}</a>  
                </div>
            }
            {props.facebook !== "" &&
                <div className="crew-ui-social">
                    <img src="./images/facebook-icon.svg" alt=""/>
                    <a href={props.facebookLink} target="_Blank" rel='noreferrer'>{props.facebook}</a> 
                </div>
            }
            {props.bioInfoPopup &&
                <div className="crew-ui-social">
                    <span onClick={clickHandler}>Bio popup</span>
                </div>
            }
            {!props.bioInfoPopup && props.bioLink !== "" &&
                <div className="crew-ui-social">
                    <a href={props.bioLink} target="_Blank" rel='noreferrer'>Bio</a>
                </div>
            }
        </div>
    )
}

export default CrewUI