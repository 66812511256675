import React from 'react'
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material'

import {noBackground} from '../../../assets/superBoonji/preview/background.js'

require ('./SelectedBoonjai.css');

export const SelectedBoonjai = () => {
    const {preview} = useSelector (state => state.boonjiStudio);
    const {boonjies} = preview;

  return (
    <div className='selectedBoonjai'>
      <Grid
        container 
        direction="column"
        spacing={0}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Grid 
            container 
            spacing={0.5}
            direction="row"
            justifyContent="center"
            alignItems="center"
            >
              <Grid item>
                <div className='selectedBoonjai-boonji'>
                  <img src={(boonjies.length > 0) ? boonjies[0].image : noBackground} alt='Boonjie #1' className={(boonjies.length>0) ? (boonjies[0].owned ? 'selectedBoonjai-selected' : 'selectedBoonjai-selected-notOwned') : 'selectedBoonjai-notSelected'} />
                </div>

                <div className='selectedBoonjai-name'>
                  {(boonjies.length > 0) ? boonjies[0].name : (<div>&nbsp;</div>)}
                </div>

              </Grid>

              <Grid item>
                <div className='selectedBoonjai-boonji'>
                  <img src={(boonjies.length > 1) ? boonjies[1].image : noBackground} alt='Boonjie #2' className={(boonjies.length>1) ? (boonjies[1].owned ? 'selectedBoonjai-selected' : 'selectedBoonjai-selected-notOwned') : 'selectedBoonjai-notSelected'}/>
                </div>

                <div className='selectedBoonjai-name'>
                  {(boonjies.length > 1) ? boonjies[1].name : (<div>&nbsp;</div>)}
                </div>
              </Grid>
            </Grid>
        </Grid>

        <Grid item>
          <Grid 
            container 
            spacing={0.5}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <div className='selectedBoonjai-boonji'>
                <img src={(boonjies.length > 2) ? boonjies[2].image : noBackground} alt='Boonjie #3' className={(boonjies.length>2) ? (boonjies[2].owned ? 'selectedBoonjai-selected' : 'selectedBoonjai-selected-notOwned') : 'selectedBoonjai-notSelected'}/>
              </div>

              <div className='selectedBoonjai-name'>
                {(boonjies.length > 2) ? boonjies[2].name : (<div>&nbsp;</div>)}
              </div>
            </Grid>

            <Grid item>
              <div className='selectedBoonjai-boonji'>
                <img src={(boonjies.length > 3) ? boonjies[3].image : noBackground} alt='Boonjie #4' className={(boonjies.length>3) ? (boonjies[3].owned ? 'selectedBoonjai-selected' : 'selectedBoonjai-selected-notOwned') : 'selectedBoonjai-notSelected'}/>
              </div>

              <div className='selectedBoonjai-name'>
                {(boonjies.length > 3) ? boonjies[3].name : (<div>&nbsp;</div>)}
              </div>
            </Grid>
        </Grid>

      </Grid>

      </Grid>
    </div>
  )
}
