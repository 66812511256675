import React from "react";

import "./Footer.css";

const Footer = (props) => {
  return (
    <section className="footer-section">
      <div className="footer-img-container">
        <img src="./images/boonji-h-p-500.png" alt=""></img>
      </div>
      <div className="footer-logos-container">
        <a
          href="https://discord.com/invite/boonjiproject"
          target="_blank"
          rel="noreferrer"
        >
          <img src="./images/Discord.png" alt=""></img>
        </a>
        <a
          href="https://twitter.com/BoonjiProject"
          target="_blank"
          rel="noreferrer"
        >
          <img src="./images/Vector-1.png" alt=""></img>
        </a>
      </div>
      <div className="footer-credits-container">
        <div className="footer-credits-a">
          <a href="/">©2021 Brendan Murphy.</a>
        </div>
        <div className="footer-powered">
          <div>Powered by Brendan Murphy</div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
