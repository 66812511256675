import { CircularProgress, Container, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBalances, getColors } from '../../../../reducers/redemptionReducer/redemptionActions'
import { Balance } from '../Balance';

export const WithConnection = () => {
    const {provider, address} = useSelector(state => state.walletProvider);
    const {loadingBalances} = useSelector(state => state.redemption)
    const dispatch = useDispatch();

    const loadBalances = () => {
        dispatch(getBalances(provider.provider, address));
    }

    useEffect(() => {
        loadBalances();
        dispatch(getColors());
    }, [])
    
    

  return (
    <Container>
        {(loadingBalances) ? (
            <Grid container direction={'column'} spacing={2} justifyContent='center' alignItems={'center'}>
                <Grid item>
                    <CircularProgress/>
                </Grid>
                <Grid item>
                    <Typography>
                        Getting you token balance...
                    </Typography>
                </Grid>
            </Grid>
        ) : (
            <Balance/>
        )}
    </Container>
  )
}
