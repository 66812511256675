export const boonjiStudioTypes = {
    setLoadingBoonjies: '[Boonji Studio] sets fake boonjies for display while loading', 
    setOwnedBoonjies: '[Boonji Studio] sets the owned boonjies',
    addNotOwnedBoonji: '[Boonji Studio] adds a not owned boonji',
    
    selectBodyTrait: '[Boonji Studio] selects a body trait',
    selectEarTrait: '[Boonji Studio] selects an ear trait',
    selectVisorTrait: '[Boonji Studio] selects a visor trait',
    selectBackgroundTrait: '[Boonji Studio] selects a background trait',
    selectFifthTrait: '[Boonji Studio] selects a fifth trait',
    saveBase64ImageURL:  '[Boonji Studio] saves the base 64 image of the preview',

    addBoonjiToBurn: '[BoonjiStudio] adds a boonji to burn',
    removeBoonjiToBurn: '[BoonjiStudio] removes a boonji to burn',

    resetPreview: '[BoonjiStudio] cleans up all selections',


}