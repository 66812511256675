import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types'

import { selectBodyTrait, selectVisorTrait, selectEarTrait, selectBackgroundTrait, addBoonjiToBurn, removeBoonjiToBurn } from '../../../reducers/boonjiStudioReducer/boonjiStudioActions';

require('./BoonjiTrait.css');

export const BoonjiTrait = props => {
    const {traitType, boonji} = props;
    const {tokenId} = boonji;
    const trait = boonji.attributes.find(val => val.trait_type === traitType)
    const {url, value, trait_type} = trait;

    const dispatch = useDispatch();
    const {preview} = useSelector(state => state.boonjiStudio);
    const {boonjies} = preview;
    const background = preview.background?.attributes.find(val => val.trait_type === 'background')
    const ear = preview.ear?.attributes.find(val => val.trait_type === 'ear')
    const visor = preview.visor?.attributes.find(val => val.trait_type === 'visor')
    const body = preview.body?.attributes.find(val => val.trait_type === 'body')

    const [selected, setSelected] = useState(false);


    const handleTraitClick = async (e) => {
        if (!selected){
            switch(trait_type){
                case 'visor':
                    dispatch(selectVisorTrait(boonji))
                    break;
                case 'body':
                    dispatch(selectBodyTrait(boonji))
                    break;
                case 'ear':
                    dispatch(selectEarTrait(boonji))
                    break;
                case 'background':
                    dispatch(selectBackgroundTrait(boonji))
                    break;
            }
            
        } else {
            switch(trait_type){
                case 'visor':
                    dispatch(selectVisorTrait(null))
                    break;
                case 'body':
                    dispatch(selectBodyTrait(null))
                    break;
                case 'ear':
                    dispatch(selectEarTrait(null))
                    break;
                case 'background':
                    dispatch(selectBackgroundTrait(null))
                    break;
            }
        }

    }
    const doNothing = () => {
        // do nothing function
    }
    useEffect(() => {
        if (trait_type === 'body'){
            if (body?.value === value && preview.body?.tokenId === tokenId) 
                setSelected(true);
            else{
                setSelected(false);
            }
        }

        if (trait_type === 'visor'){
            if (visor?.value === value  && preview.visor?.tokenId === tokenId) 
                setSelected(true);
            else {
                setSelected(false);
                }
        }

        if (trait_type === 'ear'){
            if (ear?.value === value  && preview.ear?.tokenId === tokenId) 
                setSelected(true);
            else{
                setSelected(false);
                }
        }

        if (trait_type === 'background'){
            if (background?.value === value  && preview.background?.tokenId === tokenId) 
                setSelected(true);
            else{
                setSelected(false);
                }
        }
    

        if (boonjies.find(val => val.tokenId === tokenId)){
            if (tokenId === preview.body?.tokenId || tokenId === preview.visor?.tokenId || tokenId === preview.ear?.tokenId || tokenId === preview.background?.tokenId){
                // do nothing            
            } else {
                dispatch(removeBoonjiToBurn(boonji));
            }
        }
    }, [body, visor, ear, background])

  
    return (
    <div className = "boonji-estudio-trait">
        <p className='boonji-estudio-trait-title'>{trait_type}</p> 
        <div className={(tokenId === 0) ? 'boonji-estudio-trait-bg-img-loading' : 'boonji-estudio-trait-bg-img'} onClick={(tokenId === 0) ? doNothing : handleTraitClick}>
            <img src={url} alt={value} className={(selected) ? ((boonji.owned) ? 'boonji-estudio-trait-selected' : 'boonji-estudio-trait-selected-notOwned') : 'boonji-estudio-trait-unSelected'}></img>
        </div>
            
        <p className='boonji-estudio-trait-name'>{value}</p>
    </div>
  )
}

BoonjiTrait.propTypes = {
    boonji: PropTypes.object.isRequired,
    traitType: PropTypes.string.isRequired,
}
