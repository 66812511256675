import React from "react";

import './Reel.css'

const Reel = props =>{

    return(
        <div className="reel-container">
            <img src="./images/3b.jpeg" alt=""></img>
            <img src="./images/4b.jpeg" alt=""></img>
            <img src="./images/2b.jpeg" alt=""></img>
            <img src="./images/1b.jpeg" alt=""></img>
        </div>
    )
}

export default Reel;