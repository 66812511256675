import { POSClient, use } from "@maticnetwork/maticjs"
import { Web3ClientPlugin } from '@maticnetwork/maticjs-web3'
import { ethers } from "ethers";
import {db, doc, setDoc, getDoc,onSnapshot, collection} from '../../config/firebase';
import { uiShowAlert } from "../uiReducer/uiActions";
import { redemptionTypes } from "./redemptionTypes";
import redemptionAbi from 'utils/BoonjiRedemption.json'
import { logError } from "utils/errors";
import { getDocs } from "firebase/firestore";

export const getBalances = (provider, address) => {
    return async (dispatch) =>{
        const network = process.env.REACT_APP_POLYGON_NETWORK;
        const version = (network === 'mainnet' ? 'v1' : 'mumbai')
        use(Web3ClientPlugin)
        const posClient = new POSClient();

        dispatch(setLoadingBalances(true));
        try {
            await posClient.init({
                network: network,  // 'testnet' or 'mainnet'
                version: version,
                parent: {
                  provider: provider,
                  defaultConfig: {
                    from: address
                  }
                },
                child: {
                  provider: provider,
                  defaultConfig: {
                    from: address
                  }
                }
              });
            
            const contractAddress = process.env.REACT_APP_REDEMPTION_CONTRACT_ADDRESS;
            const erc1155Token = posClient.erc1155(contractAddress,true);
            for  (let i=1; i<6; i++){
                const balance = await erc1155Token.getBalance(address, i)
                console.log(balance);
                switch(i) {
                    case 1:
                        dispatch(setToken1Balance(balance));
                        break;
                    case 2:
                        dispatch(setToken2Balance(balance));
                        break;
                    case 3:
                        dispatch(setToken3Balance(balance));
                        break;
                    case 4:
                        dispatch(setToken4Balance(balance));
                        break;
                    case 5:
                        dispatch(setToken5Balance(balance));
                        break;
                    default:
                        console.log(`missing token. Balance: ${balance}`);
                }
            }
        } catch ( error ) {
            dispatch(uiShowAlert('warning', 'Loading your tokens', 'There was an error loading the Redemption tokens from Polygon blockchain. Please refresh and try again.'));
            console.log(error);
        } finally {
            dispatch(setLoadingBalances(false));
        }
    }
}

const setToken1Balance = (balance) => ({
    type: redemptionTypes.setBalanceToken1,
    payload: balance
})
const setToken2Balance = (balance) => ({
    type: redemptionTypes.setBalanceToken2,
    payload: balance
})
const setToken3Balance = (balance) => ({
    type: redemptionTypes.setBalanceToken3,
    payload: balance
})
const setToken4Balance = (balance) => ({
    type: redemptionTypes.setBalanceToken4,
    payload: balance
})
const setToken5Balance = (balance) => ({
    type: redemptionTypes.setBalanceToken5,
    payload: balance
})

const setLoadingBalances = (loading) => ({
    type: redemptionTypes.setLoadingBalances,
    payload: loading
})


export const burnTokens = async (data, address, tokenId, amount, provider) => {
    const contractAddress = process.env.REACT_APP_REDEMPTION_CONTRACT_ADDRESS;
    const burnAddress= process.env.REACT_APP_BURN_ADDRESS;
    const signer = provider.getSigner();
    const redemptionContract = new ethers.Contract(contractAddress, redemptionAbi.abi, signer);   
    const tx = await redemptionContract.safeTransferFrom(address, burnAddress, tokenId,amount, 0x0)
    const redemptionsRef = doc(db, 'physicals', tokenId.toString(), 'redemptions', tx.hash);
    const val = {...data, address, burn:false, txHash: tx.hash} 
    await setDoc(redemptionsRef, val,{merge: true})
    await tx.wait();
    await setDoc(redemptionsRef, {burn: true, txHash: tx.hash},{merge: true})
    return tx.hash;
}

export const reduceBalance = (tokenId, amount) => {
    return  (dispatch, getState) => {
        const {redemption} = getState();
        const balance = redemption[`token${tokenId}`] - amount;
        switch(tokenId) {
            case 1:
                dispatch(setToken1Balance(balance));
                break;
            case 2:
                dispatch(setToken2Balance(balance));
                break;
            case 3:
                dispatch(setToken3Balance(balance));
                break;
            case 4:
                dispatch(setToken4Balance(balance));
                break;
            case 5:
                dispatch(setToken5Balance(balance));
                break;
            default:
                console.log(`missing token. Balance: ${balance}`);
        }
    }
}

export const getColors = () => {
    return async (dispatch) => {
        try {
            const colorsDoc = doc(db, 'physicals/1/colors/available');
            onSnapshot(colorsDoc, async (snapshot) => {
                const colors = snapshot.data(); 
                console.log(colors);                   
                dispatch(updateColorsAvailability(colors));
            })
        } catch ( error ){
            await logError('Getting physical colors stats ', error);
            throw new Error ('We are unable to load the availabiity of physical colors. Please refresh and retry later.');
        }
    }
}

const updateColorsAvailability = (colors) => ({
    type: redemptionTypes.updateColorsAvailability,
    payload: colors
})

export const updateColorAvailability = async (color, value) => {
    try {
        const colorsDoc = doc(db, 'physicals/1/colors/available');
        const snapshot = await getDoc(colorsDoc);
        const colors = snapshot.data();
        const currentValue = colors[color];
        colors[color]= currentValue - value;
        const newColors = {...colors, ...colors[color]= currentValue - value};
        await setDoc(colorsDoc, newColors)
    } catch ( error ) {
        console.log(error);
    }
}


export const getAdminData = async (tokenId) => {
    const result = []
    const adminCol = collection(db, 'physicals', tokenId.toString(), 'redemptions');
    const snapshot = await getDocs(adminCol);
    for (const doc of snapshot.docs){
        const data = doc.data();
        

        result.push(data);
    }
    return result;
}

// tokenId 0 means we are not displaying it
export const setShowSuccessDialog = (tokenId) => ({
    type: redemptionTypes.setSuccessDialog,
    payload: tokenId
})
