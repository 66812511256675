export const oneOfOnes = [
    8783,
    3847,
    8268,
    9446,
    912,
    1664,
    2509,
    4190,
    4067,
    4396,
    3466,
    4712,
    6063,
    7509,
    6725,
    10184,
    47,
    10317,
    10274,
    10474,
    7964,
    430,
    10785,
    619,
    794,
]
