import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showWalletProviderModal } from '../../../../reducers/walletProviderReducer/walletProviderActions'

export const NoConnection = () => {
    const {provider} = useSelector(state => state.walletProvider)
    const dispatch = useDispatch();
    
    const handleConnect = () => {
        if (!provider)
            dispatch(showWalletProviderModal())
    }
  return (
    <Grid
        container
        direction={'column'}
        spacing={2}
        justifyContent='center'
        alignItems={'center'}
    >
        <Grid item margin={5}>
            <Typography>
                Connect your wallet so that we can verify the assets you hold and display all your available options.
            </Typography>
        </Grid>

        <Grid item>
            <Button
                variant="outlined"  
                style={{backgroundColor: '#674BA2', color:'#FFFFFF', border: '2px solid #A66ECD'}}
                onClick={handleConnect}
                
            >
                Connect wallet 
            </Button>
        </Grid>
    </Grid>
  )
}
