import React from 'react'
import { useDispatch, useSelector } from 'react-redux';


import {noBackground} from '../../../../assets/superBoonji/preview/background.js';
import {noEar} from '../../../../assets/superBoonji/preview/ear.js';
import {noBody} from '../../../../assets/superBoonji/preview/body.js';
import {noVisor} from '../../../../assets/superBoonji/preview/visor.js';

import './PreviewMobileImage.css'
import { uiShowMaxPreview } from '../../../../reducers/uiReducer/uiActions';

export const PreviewMobileImage = () => {

    const {preview} =  useSelector(state => state.boonjiStudio);
    const background = preview.background?.attributes.find(val => val.trait_type === 'background')
    const ear = preview.ear?.attributes.find(val => val.trait_type === 'ear')
    const visor = preview.visor?.attributes.find(val => val.trait_type === 'visor')
    const body = preview.body?.attributes.find(val => val.trait_type === 'body')
    const {fifthTrait} = preview;
    
    const width = '100';
    const heigth = '100';
    const dispatch = useDispatch();
    const handleTouch = () => {
        dispatch(uiShowMaxPreview())
    }
    return (
    <div className='previewMobile-image' onTouchEd={handleTouch} onClick={handleTouch}>
        <svg
            width={width}
            height={heigth}
            xmlns='http://www.w3.org/2000/svg'
        >
            <image
                width={width}
                height={heigth}
                href={(background) ? background.url : noBackground}
                
            />

            {(fifthTrait && fifthTrait.type === 'background') && (
                <image
                    href={fifthTrait.url}
                />            
            )}
            <image
                width={width}
                height={heigth}
                href={(body) ? body.url : noBody}
            />
            <image
                width={width}
                height={heigth}
                href={(ear) ? ear.url : noEar}
            />
            <image
                width={width}
                height={heigth}
                href={(visor) ? visor.url : noVisor}
            />
            {
                (fifthTrait && fifthTrait.type !== 'background') ? (
                    <image
                        width={width}
                        height={heigth}
                        href={fifthTrait.url}
                    />            
                ) : (
                    <div></div>
                )
            }
        </svg>
    </div>
  )
}
