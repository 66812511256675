import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { BoonjiTraitLine } from './BoonjiTraitLine/BoonjiTraitLine'
import {BoonjiMain} from './BoonjiMain/BoonjiMain';

require('./BoonjiLine.css')

export const BoonjiLine = props => {
    const {boonji} = props;
  return (
    <div className='boonjiLine'>
        <div className='boonjiLine-background'>
        
        <Grid
            container 
            spacing={0}
            direction="row"
            justifyContent="left"
            alignItems="center"
        >
                <Grid item>
                    <div >
                        <BoonjiMain boonji={boonji}/>
                    </div>
                </Grid>

                <Grid item>
                    <div >
                        <BoonjiTraitLine boonji={boonji}/>
                    </div>
                </Grid>
        </Grid>
        </div>
    </div>
  )
}

BoonjiLine.propTypes = {
    boonji: PropTypes.object.isRequired
}
