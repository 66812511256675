import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Navbar from '../shared/Navigation/Navbar';
import Footer from '../shared/Navigation/Footer';
import Home from '../pages/Home/Home';
import Bio from '../pages/Bio/Bio';
import Journey from '../pages/Journey/Journey';
import SocialLogos from '../shared/UIElements/SocialLogos';
import {BoonjiStudio} from '../pages/BoonjiStudio/BoonjiStudio';

import {auth} from '../config/firebase';
import {connectWalletProviderCache} from '../reducers/walletProviderReducer/walletProviderActions';
import {authStartSignUpUser} from '../reducers/authReducer/authActions';
import { CustomAlert } from '../components/CustomAlert/CustomAlert';
import { NewRedemption } from '../pages/NewRedemption/NewRedemption';
import { CustomBackDrop } from 'components/CustomBackDrop/CustomBackDrop';
import { AdminRedeem } from 'pages/NewRedemption/AdminRedeem/AdminRedeem';

export const AppRouter = () => {
    const dispatch = useDispatch(); 
    const {address} = useSelector(state => state.walletProvider);
    const isSuperBoonjiEnabled = (process.env.REACT_APP_ENABLE_SUPERBOONJI === 'true' ? true : false);
    
    useEffect(() => {
      // if we have a provider in the cache, we use it to connect to
      dispatch(connectWalletProviderCache());
    }, []);
    
    useEffect(() =>{
        if (address) {
        auth.onAuthStateChanged(async (user) =>{
            if(user?.uid && user.uid === address){
                // dispatch(authStartSignUpUser(user.uid));
            } 
        })
    }
    },[address])

  return (
        <Router>
            <Navbar />
            <SocialLogos />
            <Switch>
                <Route path='/' exact component={Home} />
                <Route exact path='/redeem' component={NewRedemption} />
                <Route exact path='/redeem/admin' component={AdminRedeem} />
                <Route path='/Journey-Planets' component={Journey} />           
                <Route path='/brendan-murphy-biography' component={Bio} />
                {(isSuperBoonjiEnabled) ? (
                    <Route exact path='/boonjiStudio' component={BoonjiStudio} />
                ) : (
                    <div></div>
                )}
            </Switch>
            <Footer />
            <CustomAlert/>
            <CustomBackDrop style={{zIndex: 10}}/>
        </Router> 
    );
};
