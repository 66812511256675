import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import { pink } from '@mui/material/colors';

const RedeemRow = props => {
    const {data} = props;
  return (
    <Grid
        container
        direction={'row'}
        alignItems='center'
        justifyContent={'center'}
        spacing={3}
    >
        <Grid item>
            <Typography>
                <Grid 
                    container
                    direction={'column'}
                    spacing={1}
                >
                    <Grid item>
                        Name: {data.firstName} {data.lastName}
                    </Grid>

                    <Grid item>
                        Wallet: {data.address}
                    </Grid>

                    <Grid item>
                        Contact: {data.email} - {<data className="telephone"></data>}
                    </Grid>

                    <Grid item>
                        Quantity: {data.qty}
                    </Grid>

                    {(data.color) && (
                      <Grid item>
                        Color: {data.color}
                    </Grid>  
                    )}

                    <Grid item>
                        Address: {data.addressLine1} {data.addressLine2}
                    </Grid>
                    <Grid item>
                        {data.city} - {data.state}
                    </Grid>
                    
                    <Grid item>
                        {data.country}
                    </Grid>

                    <Grid item>
                        Comments: {data.comments}
                    </Grid>
                </Grid>
            </Typography>
        </Grid>
        
        <Grid item>
            <Typography>
            <FormGroup>
                <FormControlLabel control={
                <Checkbox 
                    defaultChecked={false} 
                    sx={{
                    color: pink[800],
                    '&.Mui-checked': {
                        color: pink[600],
                    },
                    }}/>} 
                    label="Shipped?" 
                />
            </FormGroup>
            </Typography>
        </Grid>
    </Grid>
  )
}

RedeemRow.propTypes = {
    data: PropTypes.string.isRequired
}

export default RedeemRow