import React from "react";
import BoonjiImg from "../../shared/UIElements/BoonjiImg";
import Brendan from "./Sections/Brendan";
import Reel from "./Sections/Reel";

import './Bio.css';

const Bio = props =>{

    return(
        <section className="bio-section">
            <BoonjiImg />
            <Brendan />
            <Reel />
        </section>
    )
}

export default Bio;