import token1 from 'assets/redemption/1.mp4';
import token2 from 'assets/redemption/2.jpeg';
import token3 from 'assets/redemption/3.png';
import token4 from 'assets/redemption/4.jpeg';
import token5 from 'assets/redemption/5.png';

export const tokens = [
    {
        tokenId: 1,
        name: 'Time moves fast',
        description: 'Carbon fiber chromed Boonji spacemen sculpture',
        about: 'These tokens are keys to unlocking IRL Brendan Murphy Art. In the near future, each token can be burned and exchanged for physical Art by Brendan Murphy. These can also be traded in perpetuity if you wish, with no expiration date.',
        media: {
            type: 'video',
            source: token1
        }
    },
    {
        tokenId: 2,
        name: 'Physical Painting',
        description: '30 Boonji Paintings all hand embellished by Brendan Murphy',
        about: 'These tokens are keys to unlocking IRL Brendan Murphy Art. In the near future, each token can be burned and exchanged for physical Art by Brendan Murphy. These can also be traded in perpetuity if you wish, with no expiration date.',
        media: {
            type: 'image',
            source: token2
        }
    },
    {
        tokenId: 3,
        name: '7foot Boonji',
        description: '7’ Big Foot Boonji Spaceman Sculpture. Carbon Fiber, Chrome Layers with Formulas by Brendan Murphy',
        about: 'These tokens are keys to unlocking IRL Brendan Murphy Art. In the near future, each token can be burned and exchanged for physical Art by Brendan Murphy. These can also be traded in perpetuity if you wish, with no expiration date.',
        media: {
            type: 'image',
            source: token3
        }
    },
    {
        tokenId: 4,
        name: 'Catch Me If You Can',
        description: '999 Boonji Spaceman Prints. All Signed Numbered & Hand Embellished. Archival Paper 17” x 11”',
        about: 'These tokens are keys to unlocking IRL Brendan Murphy Art. In the near future, each token can be burned and exchanged for physical Art by Brendan Murphy. These can also be traded in perpetuity if you wish, with no expiration date.',
        media: {
            type: 'image',
            source: token4
        }
    },
    {
        tokenId: 5,
        name: 'Custom Boonji Rolex',
        description: 'Datejust 41 mm, Oystersteel, Yellow Gold Rolex The Unique dial inside the Rolex Features one of the Boonji Project image created by Brendan Murphy. The actual process of implementing the dial with the artwork inside a Rolex is quite complex. Similar to Brendan‘s art pieces, it will require time and focus. This will take approximately 6-8 weeks before it can be shipped to the lucky winner.',
        about: 'These tokens are keys to unlocking IRL Brendan Murphy Art. In the near future, each token can be burned and exchanged for physical Art by Brendan Murphy. These can also be traded in perpetuity if you wish, with no expiration date.',
        media: {
            type: 'image',
            source: token5
        }
    }
]