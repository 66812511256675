import { Grid } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux'
import { PreviewMobileBoonji } from './PreviewMobileBoonji';

export const PreviewMobileBoonjiLine = () => {
    const {preview} = useSelector(state => state.boonjiStudio);
    const {boonjies} = preview;
    const selectedAmount = boonjies.length;
  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <PreviewMobileBoonji selected={(selectedAmount>0) ? true : false}/>
        </Grid>
        <Grid item>
          <PreviewMobileBoonji selected={(selectedAmount>1) ? true : false}/>
        </Grid>
        <Grid item>
          <PreviewMobileBoonji selected={(selectedAmount>2) ? true : false}/>
        </Grid>
        <Grid item>
          <PreviewMobileBoonji selected={(selectedAmount>3) ? true : false}/>
        </Grid>

      </Grid>
    </div>
  )
}
