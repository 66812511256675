export const redemptionTypes = {
    setBalanceToken1: '[Redemption] sets the current balance of token 1',
    setBalanceToken2: '[Redemption] sets the current balance of token 2',
    setBalanceToken3: '[Redemption] sets the current balance of token 3',
    setBalanceToken4: '[Redemption] sets the current balance of token 4'  ,
    setBalanceToken5: '[Redemption] sets the current balance of token 5',
    setLoadingBalances: '[Redemption] specifies if balances are loading',
    updateColorsAvailability: '[Redemption] modifies the colors',
    reduceColorAvailability: '[Redemption] reduces a colors availability',
    setSuccessDialog:  '[Redemption] shows or hides the dialog',

}