import {initializeApp} from 'firebase/app';
import {getFirestore, collection, addDoc, getDoc, setDoc, doc, onSnapshot, runTransaction } from 'firebase/firestore';
import {getStorage, ref, uploadString, getDownloadURL} from 'firebase/storage';
import {getAuth, signInWithCustomToken, signOut} from 'firebase/auth';

// Production
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);


  const db = getFirestore(app);
  const storage = getStorage(app);
  const auth = getAuth(app);

export {
    auth,
    db,
    collection, addDoc, getDoc, setDoc, doc, onSnapshot, runTransaction,
    signInWithCustomToken, signOut,
    storage, ref, uploadString, getDownloadURL
}
