import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types'
import { Grid } from '@mui/material';

import {noBackground} from '../../../assets/superBoonji/preview/background.js'
import { selectFifthTrait } from '../../../reducers/boonjiStudioReducer/boonjiStudioActions';
require ('./FifthTrait.css');

export const FifthTrait = (props) => {
  const {readOnly} = props;

  const {preview} = useSelector (state => state.boonjiStudio);
  const dispatch = useDispatch();
  const {fifthTrait} = preview;

  const handleTraitClick = () => {
    if (!readOnly)
      dispatch(selectFifthTrait(null))
  }
  return (
    <div className='preview-fifthTrait'>
      <Grid 
        container 
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
            <div className='preview-fifthTrait-title'>
              Fifth Super Trait
            </div>
        </Grid>
        
        <Grid item>
          <div className='preview-fifthTrait-trait'>
            <img src={(fifthTrait) ? fifthTrait.url : noBackground} alt='5th Trait' onClick={handleTraitClick} className={(fifthTrait) ? 'preview-fifthTrait-selected' : 'preview-fifthTrait-Notselected'}/> 
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

FifthTrait.propTypes = {
  readOnly: PropTypes.bool.isRequired
}