import { Container } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux'
import { NoConnection } from './components/NoConnection';
import { WithConnection } from './components/WithConnection';
import { WrongNetwork } from './components/WrongNetwork';

export const NewRedemption = () => {
    const chainId = Number(process.env.REACT_APP_REDEMPTION_NETWORK_ID);
    const {address, networkId} = useSelector(state => state.walletProvider);

  return (
    <Container>
        {(address) ? (
            <div>
                {(chainId === networkId) ? (
                    <WithConnection/>
                ) : (
                    <WrongNetwork/>
                )}
            </div>
        ) : (
            <NoConnection/>
        )}
    </Container>
  )
}
