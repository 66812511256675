import moment from 'moment';
import {utils} from 'ethers';
import jwt_decode from "jwt-decode";

import {auth, signInWithCustomToken, signOut, db, setDoc, getDoc, doc} from '../../config/firebase';
import {requestAuthToken, requestAuthMessage} from '../../utils/api';
import {showWalletProviderModal} from '../walletProviderReducer/walletProviderActions';
import {authTypes} from './authTypes';
import {localStorageAdd, localStorageGet} from '../../utils/localStorage';
import { logError } from '../../utils/errors';
import { uiShowAlert } from '../uiReducer/uiActions';


export const authStartSignUpUser = (add) => {
    return async (dispatch, getState) => {
        try {
            const address = utils.getAddress(add);
            // we search first for token stored on storage, because this might be an existing user
            const storage = localStorageGet(address);
            
            if (storage?.token) {
                // we need to verify if the token is still valid, because it might have expired.
                // if it is expired, we request a new one by providing signature stored on db that only we can read.
                let token = storage.token;
                if (isTokenExpired(token)){
                    const signature = await getStoredSignature(address);
                    const response = await requestAuthToken(address, signature);
                    token = response.message;
                }
                
                await signInWithCustomToken(auth, token);
                dispatch(authSetUser(address));
            } else {
                // this is a new user, so we are registering it by getting a message to sign
                const response = await requestAuthMessage(address);
                
                const {provider} = getState().walletProvider;
    
                // we don't have a provider? this is weird, we will launch the connect provider modal
                if (!provider){
                    await dispatch(showWalletProviderModal())
                }
    
                // if we don't have a provider, then we will fail
                const signer = await provider.getSigner();
                const signature = await signer.signMessage(response.message);
                const signatureResponse = await requestAuthToken(address, signature);
                const token = signatureResponse.message;
                await signInWithCustomToken(auth, token);
                await dispatch(authSetUser(address));            
    
                // we store the token on local Storage to support user switching
                localStorageAdd(address, {token});
            }
    
            // we store in firestore the last login time
            await saveLastLogin(address);
        } catch ( error ) {
            logError('Signing user', error, add)
            // dispatch(uiShowAlert('error','Login error', 'We have an error trying to log you in. We are sorry for the inconvenience. Please refresh and try again.'));
        }
    }
}

/**
 * decodes and verifies if the token is expired or not
 * @param {*} token 
 * @returns 
 */
const isTokenExpired = (token) => {
    const decoded = jwt_decode(token);
    const now = moment().unix();
    const {exp} = decoded;
    return (now > exp);
}

const saveLastLogin = async (address) => {  
    try {
        await setDoc(doc(db, 'users', address), {lastLogin: moment().toDate()}, {merge: true});
    } catch ( error ) {
        await logError('Saving last login', error, address);
    }
}

// we get the last signed signature for this address in order to request a token refresh.
// This way we avoid asking the user to sign again
const getStoredSignature = async (address) => {
    try {
        const snapshot = await getDoc(doc(db, 'auth', address));
        const {signature} = snapshot.data();
        return signature;
    } catch ( error ) {
        await logError('Getting stored signature', error, address);
        throw new Error ('There was a problem accessing the database. Please try again later.')
    }
}

export const authSetUser = (uid) => ({
    type: authTypes.setUser,
    payload: uid
})

export const authStartLogOut = () => {
    return async (dispatch) => {
        await signOut(auth);
        dispatch(logOut());
    }
}

const logOut = () => ({
    type: authTypes.logOutUser
})