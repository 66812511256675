import { Container, Grid, Typography } from '@mui/material'
import React from 'react'

export const WrongNetwork = () => {
    const chainId = process.env.REACT_APP_REDEMPTION_NETWORK_ID;
  return (
    <Container>
        <Grid container direction='column' spacing={2} justifyContent='center' alignItems={'center'}>
            <Grid item margin={5}>
                <Typography>
                    Redemption tokens exists in Polygon network (Chain ID: {`${chainId}`})
                </Typography>
            </Grid>

            <Grid item>
                <Typography>
                    Switch to Polygon blockchain and refresh the webpage.
                </Typography>
            </Grid>
        </Grid>
    </Container>
  )
}
