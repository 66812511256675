import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Grid, Typography } from '@mui/material'
import { tokens } from '../../../../redemptionTokens'
import { useSelector } from 'react-redux'
import RedeemDialog from '../RedeemDialog/RedeemDialog'
export const RedeemToken = props => {
    const {tokenId} = props;
    const token = tokens.find(val => val.tokenId === tokenId)
    const redemption = useSelector(state => state.redemption)
    const balance = redemption[`token${tokenId}`];
    const title = balance + ' ' + (balance === '1' ? 'token' : 'tokens');
    const [openRedeem, setOpenRedeem] = useState(false)

    const handleRedeem = () => { 
        setOpenRedeem(true);
    }
    return (
    <Card elevation={2}>
        <CardHeader
            title={title}
        />
        <CardMedia>
            <Box>
                {(token.media.type === 'image') ? (
                    <img src={token.media.source} alt={`token ${tokenId}`} width={'100%'}/>
                ) : (
                    <video width="100%" controls loop autoPlay muted>
                        <source src={token.media.source} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                )}
            </Box>
        </CardMedia>

        

        <CardContent style={{backgroundColor: 'black'}}>
            <Grid
                container
                direction={'column'}
                flexDirection={'center'}
                alignItems={'center'}
                spacing={2}
            >
                <Grid item>
                    <Typography fontWeight={700}>
                        {token.name}
                    </Typography>
                </Grid>
                
                <Grid item minHeight={{xs:1, md: 160}}>
                    <Typography>
                        {token.description}
                    </Typography>
                </Grid>
                
            </Grid>
        </CardContent>

        <CardActions>
        
            <Button 
                fullWidth 
                disabled={(Number(balance) === 0) ? true : false}
                variant="outlined"  
                onClick={handleRedeem}
            >
                REDEEM
            </Button>
        </CardActions>

        <RedeemDialog open={openRedeem} setOpen={setOpenRedeem} tokenId={tokenId}/>
    </Card>
  )
}

RedeemToken.propTypes = {
    tokenId: PropTypes.number.isRequired
}
