import React from "react";

import './Communji.css'

const Communji = props =>{
    return(
        <section className="communji-section">
            <div className="communji-section-container">
                <div className="communji-info">
                    <div>
                        <h2>Boonji Communji</h2>
                        <p>When you become part of the Boonji Communji, you’re not simply buying a NFT, you are gaining membership access to a community of people who will all work together to increase benefits and value for one another. Your Boonji Avatar will signal to the universe you are here to spread imagination, dreams and desires propelling us all forward. <br/><br/>
                            To access members-only areas Boonji Communji Members will need to be signed into their Crypto Wallet.</p>
                    </div>                
                </div>
                <div className="communji-img">
                    <img src="./images/some12c2-p-1600.jpeg" alt=""></img>
                </div>
            </div>
            
        </section>
    )
}

export default Communji;