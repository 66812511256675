import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";

import { localStorageGet } from "../../utils/localStorage";
import { FifthTraitLine } from "./FifthTraitLine/FifthTraitLine";
import { selectFifthTrait } from "../../reducers/boonjiStudioReducer/boonjiStudioActions";

require("./FifthTrait.css");

export const FifthTrait = () => {
  const {
    halos,
    headphones,
    devilAngels,
    signatures,
    rocketMans,
    vulcanEars,
    necklaces,
    horns,
    crowns,
    words,
    fingerprints,
    spirals,
    ladies,
    formulas,
    puzzles,
    rocketBoonjies,
    walkingBoonjies,
    chains,
    //SB2
    background,
    body,
    helmet,
    snake,
    visor,
    wig,
    elephantEar,
    misc,
  } = useSelector((state) => state.fifthTrait);
  const { owned } = useSelector((state) => state.boonjiStudio);
  const { address } = useSelector((state) => state.walletProvider);
  const dispatch = useDispatch();

  // if we have a trait stored in storage, we check it
  useEffect(() => {
    if (address) {
      const storage = localStorageGet(address);
      if (storage.fifthTrait) {
        const tokenId = storage.fifthTrait.tokenId;
        if (owned.find((boonji) => boonji.tokenId === tokenId))
          dispatch(selectFifthTrait(storage.fifthTrait));
      }
    }
  }, [address]);

  return (
    <div className="fifthTrait">
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="left"
      >
        <Grid item>
          <div className="fifthTrait-title">Fifth Traits</div>
        </Grid>
        <Grid item>
          <div className="fifthTrait-subTitle">
            Add a 5th trait to create a Super Boonji
          </div>
        </Grid>

        <Grid item>
          <div className="fifthTrait-type">Brendan's Signature</div>
          <div className="fifthTrait-signature">
            Pick all traits from the same boonji and you will be able to pick
            Brendan's signature.
          </div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"signature"} traits={signatures} />
          </div>
        </Grid>

        <Grid item>
          <div className="fifthTrait-type">Halos</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"halo"} traits={halos} />
          </div>
        </Grid>

        <Grid item>
          <div className="fifthTrait-type">Headphones</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"headphone"} traits={headphones} />
          </div>
        </Grid>

        <Grid item>
          <div className="fifthTrait-type">Devil Angels</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"devilAngel"} traits={devilAngels} />
          </div>
        </Grid>

        <Grid item>
          <div className="fifthTrait-type">Rocket Man's glasses</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"rocketMan"} traits={rocketMans} />
          </div>
        </Grid>

        <Grid item>
          <div className="fifthTrait-type">Vulcan Ears</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"vulcanEar"} traits={vulcanEars} />
          </div>
        </Grid>

        <Grid item>
          <div className="fifthTrait-type">Necklaces</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"necklace"} traits={necklaces} />
          </div>
        </Grid>

        <Grid item>
          <div className="fifthTrait-type">Crowns</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"crown"} traits={crowns} />
          </div>
        </Grid>

        <Grid item>
          <div className="fifthTrait-type">Horns</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"horn"} traits={horns} />
          </div>
        </Grid>

        <Grid item>
          <div className="fifthTrait-type">Words</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"word"} traits={words} />
          </div>
        </Grid>

        <Grid item>
          <div className="fifthTrait-type">Fingerprints</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"fingerprint"} traits={fingerprints} />
          </div>
        </Grid>

        <Grid item>
          <div className="fifthTrait-type">Spirals</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"spiral"} traits={spirals} />
          </div>
        </Grid>

        <Grid item>
          <div className="fifthTrait-type">Ladies</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"lady"} traits={ladies} />
          </div>
        </Grid>

        <Grid item>
          <div className="fifthTrait-type">Walking Boonji</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"walkingBoonji"} traits={walkingBoonjies} />
          </div>
        </Grid>

        <Grid item>
          <div className="fifthTrait-type">Rocket Boonji</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"rocketBoonji"} traits={rocketBoonjies} />
          </div>
        </Grid>

        <Grid item>
          <div className="fifthTrait-type">Puzzle</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"puzzle"} traits={puzzles} />
          </div>
        </Grid>

        <Grid item>
          <div className="fifthTrait-type">Formula</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"formula"} traits={formulas} />
          </div>
        </Grid>

        <Grid item>
          <div className="fifthTrait-type">Chains</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"chain"} traits={chains} />
          </div>
        </Grid>

        {/* SB2 */}
        <Grid item>
          <div className="fifthTrait-type">Backgrounds</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"background"} traits={background} />
          </div>
        </Grid>
        <Grid item>
          <div className="fifthTrait-type">Bodies</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"body"} traits={body} />
          </div>
        </Grid>
        <Grid item>
          <div className="fifthTrait-type">Helmets</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"helmet"} traits={helmet} />
          </div>
        </Grid>
        <Grid item>
          <div className="fifthTrait-type">Snakes</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"sake"} traits={snake} />
          </div>
        </Grid>
        <Grid item>
          <div className="fifthTrait-type">Visors</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"visor"} traits={visor} />
          </div>
        </Grid>
        <Grid item>
          <div className="fifthTrait-type">Wigs</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"wig"} traits={wig} />
          </div>
        </Grid>
        <Grid item>
          <div className="fifthTrait-type">Elephant Ears</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"elephantEar"} traits={elephantEar} />
          </div>
        </Grid>
        <Grid item>
          <div className="fifthTrait-type">Miscellaneous</div>
          <div className="fifthTrait-carousel">
            <FifthTraitLine type={"misc"} traits={misc} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
