import Web3Modal from 'web3modal';
import {ethers, utils} from 'ethers';

// providers 
import WalletConnectProvider from "@walletconnect/web3-provider";
import Authereum from "authereum";
import {WalletLink} from "walletlink";

import {walletProviderTypes} from './walletProviderTypes';
import {parseChainId} from '../../utils/blockchain';
import {authStartSignUpUser, authStartLogOut} from '../authReducer/authActions';



import coinbaseIcon from '../../assets/blockchain/coinbaseLogo.png';
import { logError } from '../../utils/errors';
import { uiShowAlert } from '../uiReducer/uiActions';

const network = process.env.REACT_APP_NETWORK;
const infuraId = process.env.REACT_APP_INFURA_ID


const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider, 
        options: {
          infuraId: infuraId
        }
    },
    authereum: {
        package: Authereum 
    },
    'custom-coinbase': {
        display: {
            logo: coinbaseIcon,
            name: 'Coinbase',
            description: 'Scan with WalletLink to connect',
        },
        options: {
            chainId: (network === 'mainnet') ? 1 : 4,
            appName: 'boonji-project',
            networkUrl: `https://${network}.infura.io/v3/${infuraId}`
            
        },
        package: WalletLink,
        connector: async (_, options) => {
            const { appName, networkUrl, chainId } = options
            const walletLink = new WalletLink({
                appName
            });
            const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
            await provider.enable();
            return provider;
        },
    }
}

const web3Modal = new Web3Modal({
    network: network,
    cacheProvider: true,
    providerOptions: providerOptions,
    theme: "dark"
});


/**
 * Shows and sets (if choosen) the provider we are connecting to
 */
export const showWalletProviderModal = () => {
    return async (dispatch) => {
        try {
            web3Modal.clearCachedProvider();
            const instance = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(instance);
            const signer = provider.getSigner();
            const address = await signer.getAddress();
            const {chainId} = await provider.getNetwork();
            dispatch(setWalletProvider(provider, address, chainId));
            dispatch(setEventListeners(instance));
            await dispatch(authStartSignUpUser(address));
        } catch ( error ) {
            logError('Showing the Wallet provider modal', error)
            dispatch(uiShowAlert('error', 'Wallet Connector', 'We have a problem connecting your wallet. Please refresh and try again.'));
        }
    }
}

/**
 * connects (if exists) from the modal cache
 * @returns 
 */
export const connectWalletProviderCache = () => {
    return async (dispatch) => {
        if (web3Modal.cachedProvider){
            try {
                const instance = await web3Modal.connect();
                const provider = new ethers.providers.Web3Provider(instance);
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const {chainId} = await provider.getNetwork();
                dispatch(setWalletProvider(provider, address, chainId))
                dispatch(setEventListeners(instance));
                await dispatch(authStartSignUpUser(address));
            } catch ( error ) {
                // the user might reject connecting again, so we will clear cache
                logError('Connecting wallet from cache', error)
                web3Modal.clearCachedProvider();
            }
        }
    }
}

const setWalletProvider = (provider, address, network) => ({
    type: walletProviderTypes.setProvider,
    payload: {provider, address, network}
})

const setAddress = (address) => ({
    type: walletProviderTypes.setAddress,
    payload: address
})

const setNetworkId = (networkId) => ({
    type: walletProviderTypes.setNetworkId,
    payload: networkId
})

const disconnect = () => ({
    type: walletProviderTypes.setDisconnect
})

export const disconnectProvider = () => {
    return async (dispatch) => {
        await dispatch (disconnect());
            
        // we also log out the user
        await dispatch(authStartLogOut());
        
        // we clear the cache
        web3Modal.clearCachedProvider();
    }
}

// Events for the provider
const setEventListeners = (provider) => {
    return async (dispatch) => {
        // Subscribe to accounts change
        provider.on("accountsChanged", async (accounts) => {
            if (accounts.length > 0){
                const address = utils.getAddress(accounts[0]);
                await dispatch (setAddress(address));
                // we log out the current user, if any
                await dispatch(authStartLogOut());

                // we log in, by getting a stored token or requesting a new signature.
                await dispatch(authStartSignUpUser(address));
            }
        });
      
        // Subscribe to chainId change
        provider.on("chainChanged", async (chainId) => {            
            await dispatch (setNetworkId(parseChainId(chainId)));
        });

        // Subscribe to provider connection
        provider.on("connect", (info) => {
            // console.log(info);
        });
        
        // Subscribe to provider disconnection
        provider.on("disconnect", async (error) => {
            await dispatch (disconnect());
            
            // we also log out the user
            await dispatch(authStartLogOut());
            
            // we clear the cache
            web3Modal.clearCachedProvider();
        });
    }
}