import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, useWatch } from "react-hook-form";
import { Button,  Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { uiShowAlert } from 'reducers/uiReducer/uiActions';

export const AddressForm = (props) => {
    const {setFormData, formData, tokenId, handleRedeem} = props;
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const [selectedColor, setSelectedColor] = useState({background: 'transparent', color:'black'})
    const color = useWatch({
        control,
        name: "color",
      });
    
    const {redemption} = useSelector(state => state);
    const {colors} = redemption;
    
   

    const getSelectorValues = () => {
        const maxValue = redemption[`token${tokenId}`];
        const values = [];
        for (let i=0; i<maxValue; i++){
            values.push(i+1)
        }

        return values;
    }

    useEffect(() => {
        switch(color){
            case 'black':
                setSelectedColor({background:'black', color:'white'})
                break;
            case 'blue':
                    setSelectedColor({background:'blue', color:'white'})
                    break;
            case 'gold':
                    setSelectedColor({background:'gold', color:'black'})
                    break;
            case 'green':
                    setSelectedColor({background:'green', color:'white'})
                    break;
            case 'red':
                setSelectedColor({background:'red', color:'white'})
                break;
            case 'purple':
                setSelectedColor({background:'purple', color:'white'})
                break;
            case 'rose':
                setSelectedColor({background:'#FF007F', color:'white'})
                break;
            default:
                setSelectedColor({background:'white', color:'black'})
        } 
    }, [color])
    
    
    const onSubmit = async (data) => {
        if (tokenId === 1) {
            if (data.qty > colors[data.color]){
                dispatch(uiShowAlert('warning', 'Color not available', `Color ${data.color} is not available at this quantity. Reduce the quantity of change color.`))
                return;
            }
        }
        setFormData(data)
        handleRedeem(data);
    }

    
  return (
       <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
            container
            direction= 'row'
            justifyContent="center"
            alignItems={'center'}
            spacing={2}
        >
            <Grid item xs={12} md={6} >
                <Grid container direction='column' alignItems="center" spacing={1}>
                    <Grid item>
                        <select {...register("qty", { required: true })} style={{background: 'transparent', color:'black', width:'200px', borderColor: 'gray', height:'30px'}}>
                            <option value={""}>Quantity</option>
                            {getSelectorValues().map(val =>  (
                                <option key={val.toString()}value={val.toString()}>{val.toString()}</option>
                            ))}
                        </select>
                        {errors.qty ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    {(tokenId === 1) && (
                        <Grid item>
                            <select {...register("color", { required: true })} style={{background: selectedColor.background, color:selectedColor.color, width:'200px', borderColor: 'gray', height:'30px'}}>
                                <option key='1' value="">Color</option>
                                <option key='black' disabled={(colors.black === 0 ? true : false)} value="black">Black - {colors.black} left</option>
                                <option key='blue' disabled={(colors.blue === 0 ? true : false)} value="blue">Blue - {colors.blue} left</option>
                                {/* <option key='gold' disabled={(colors.gold === 0 ? true : false)} value="gold">Gold - {colors.gold} left</option> */}
                                {/* <option key='green' disabled={(colors.green === 0 ? true : false)} value="green">Green - {colors.green} left</option> */}
                                <option key='red' disabled={(colors.red === 0 ? true : false)} value="red">Red - {colors.red} left</option>
                                {/* <option key='purple' disabled={(colors.purple === 0 ? true : false)} value="purple">Purple - {colors.purple} left</option> */}
                                <option key='rose' disabled={(colors.rose === 0 ? true : false)} value="rose">Rose - {colors.rose} left</option>
                            </select>
                            {errors.color ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                        </Grid>
                    )}

                    <Grid item>
                        <input defaultValue={formData?.firstName} placeholder="First name" label="First Name" {...register("firstName", { required: true })} style={{background: 'transparent', color:'black', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.firstName ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    <Grid item>
                        <input defaultValue={formData?.lastName} placeholder="Last name" label="Last Name" {...register("lastName", { required: true })} style={{background: 'transparent', color:'black', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.lastName ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    <Grid item>
                        <input defaultValue={formData?.email} placeholder="email" label="Email" {...register("email", { required: true , pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ })} style={{background: 'transparent', color:'black', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.email ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    <Grid item>
                        <input defaultValue={formData?.addressLine1} placeholder="Address line 1" {...register("addressLine1", { required: true })} style={{background: 'transparent', color:'black', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.addressLine1 ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>


                    <Grid item>
                        <input defaultValue={formData?.addressLine2} placeholder="Address line 2"  {...register("addressLine2", { required: false })} style={{background: 'transparent', color:'black', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.addressLine2 ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>
                    
                </Grid>    
            </Grid>

            <Grid item xs={12} md={6}>
                <Grid container direction='column' alignItems="center"  spacing={1}>
                    
                    <Grid item>
                        <input defaultValue={formData?.city} placeholder="City" {...register("city", { required: true })} style={{background: 'transparent', color:'black', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.city ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    <Grid item>
                        <input defaultValue={formData?.state} placeholder="State" {...register("state", { required: true })} style={{background: 'transparent', color:'black', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.state ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    <Grid item>
                        <input defaultValue={formData?.zipCode} placeholder="Zip code" {...register("zipCode", { required: true })} style={{background: 'transparent', color:'black', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.zipCode ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    <Grid item>
                        <input defaultValue={formData?.country} placeholder="Country" {...register("country", { required: true })} style={{background: 'transparent', color:'black', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.country ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    <Grid item>
                        <input defaultValue={formData?.telephone} placeholder="Telephone" {...register("telephone", { required: true })} style={{background: 'transparent', color:'black', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.telephone ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                    <Grid item>
                        <input defaultValue={formData?.comments} placeholder="Comments" {...register("comments", { required: false })} style={{background: 'transparent', color:'black', width:'200px', borderColor: 'gray', height:'30px'}}/>
                        {errors.comments ? <span style={{color:'red'}}>&nbsp;!&nbsp;</span> : <span>&nbsp; &nbsp;</span>}
                    </Grid>

                </Grid>    

            </Grid>

            <Grid item width={'100%'} marginBottom={-2}>
                <Button fullWidth onClick={handleSubmit(onSubmit)} variant={'contained'} color='warning' >
                    REDEEM
                </Button>
            </Grid>
        </Grid>
    </form>
  )
}

AddressForm.propTypes = {
    setFormData: PropTypes.func,
    formData: PropTypes.object,
    tokenId: PropTypes.number.isRequired,
    handleRedeem: PropTypes.func.isRequired,
}