import React from "react";

import './PhaseUI.css'

const PhaseUI = props =>{

    return(
        <div>
            <div className="phase-ui-top">
                <div className="phase-ui-left">
                    <div className="roadmap-phase-number">{props.phase}</div>
                    <h3 className="road-map-h3">{props.planet}</h3>
                    <h2 className="road-map-h2 phase-title-planet">{props.title}</h2>
                </div>
                <div className="phase-ui-right">
                    <img src={props.img} alt=""></img>
                </div>                
            </div>

            <div className="phase-ui-bottom">
                <p className="new-line">{props.text}</p>
            </div>            
        </div>
    )
}

export default PhaseUI;