import React from "react";
import Phase1 from "./Phase1";
import PhaseUI from "../../../shared/UIElements/PhaseUI";

import './Phases.css'

const Phases = props =>{

    const phases = [
        {
            id:"phase2",
            phase:"PHASE 2",
            planet: "PLANET 1",
            title:"Kaido",
            img:"./images/kaido.png",
            text:"Boonji is landing on Kaido in early December at the Art Basel event in Miami, FL.  To celebrate, we’re going to kick this off with some MAJOR value for holders.  We’re going to raffle off 999 limited-edition, signed and numbered prints (retail value $4,000 each).  To qualify, you have to own a Boonji NFT and must be delisted on OpenSea OR listed at 3.3+ ETH before November 30. The raffle will be attached to individual token IDs, so the more NFTs you hold, the more chances you have to win. These will be distributed by Polygon Tokens. Tokens will be burned when redeemed; also available to trade on the secondary-market.\n\n"+
            "All attendees of the Art Basel event in Miami that are Boonji NFT holders will be entered into a live raffle, where 3 winners will be selected to receive tickets for Art Miami to see Brendan’s physical and digital artwork, and receive a personal tour of his studio during the first week of December."
        },
        {
            id:"phase3",
            phase:"PHASE 3",
            planet: "PLANET 2",
            title:"Meela",
            img:"./images/meela.png",
            text:"Boonji goes international!  Boonji will be landing on Meela in mid-December, when the 23-foot Boonji Spaceman goes up in London.\n\n"+
            "To celebrate, Brendan will be creating unique NFTs of Boonji exploring Planet Meela – there will be 11 different scenes, with 3 versions of each (33 NFTs in total).  These will be raffled to members of Boonji Communji that have their NFTs delisted from OpenSea (or listed at 3.3 ETH or more). \n\n"+
            "Launching Boonji Merch Store!"
        },
        {
            id:"phase4",
            phase:"PHASE 4",
            planet: "MEELA'S MOON",
            title:"Neeve",
            img:"./images/planet6.png",
            text:"Brendan will be exhibiting his artwork at Seven To Eight Gallery in mid-December in Hamburg, Germany! At this event, Boonji will be hosting a party. Anyone who owns a Boonji NFT is welcome to join for free.\n\n"+
            "In addition, 1 lucky Boonji NFT holder will receive a CUSTOM, BOONJI-FACED ROLEX.  To qualify, you must own 5 or more Boonji NFTs before the Moon Landing in mid-December."
        },
        {
            id:"phase5",
            phase:"PHASE 5",
            planet: "PLANET 3",
            title:"Luulee",
            img:"./images/planet4.png",
            text:"Everyone needs a companion.  Boonji finds a friend to join him on his journey into the unknown.  We will be introducing LuuLee as a companion drop in the first half of 2022."
        },
        {
            id:"phase6",
            phase:"PHASE 6",
            planet: "LUULEE'S MOON",
            title:"Sueño",
            img:"./images/planet3.png",
            text:"Family House Charity:  Brendan has a huge heart and believes that one of the most important aspects in life is giving back to those in need.  Brendan has always been a big supporter of Family House, an organization that supports families of children receiving treatment for cancer.\n\n" +
            "Any Boonji NFT holder who wishes to participate, can register their Boonji NFT to be featured as part of a massive Boonji Collage.  This will be donated to Family House by the Boonji Communji.\n\n"+
            "Steph Curry and Andre Iguoduala are heavily involved with the Family House Charity."
        },
        {
            id:"phase7",
            phase:"PHASE 7",
            planet: "PLANET 4",
            title:"Ohana",
            img:"./images/planet1.png",
            text:"Boonji Festival in Antigua in Summer 2022:  Art festival meets music festival. Lineup will be announced over the next few months. Your Boonji NFT will be your wristband that gives you access to the festival events.\n\n"+
            "10 lucky winners will win a free all-inclusive stay at Hodges Bay Resort.  In addition, they will receive a gift basket with Boonji merch plus special edition Dancing Boonji sculptures. Note: we’re creating a passport stamp to travel with Boonji around the galaxy. \n\n"+
            "Each contest that you enter will be a stamp in your passport.  If you’ve colleceted all the stamps along the way, by the time we reach Antigua you will be entered into a drawing to be one of the lucky winners of the Antigua bundle.\n\n"+
            "Last but not least, IRL Boonji Dance Party!"
        },
        {
            id:"phase8",
            phase:"PHASE 8",
            planet: "PLANET 5",
            title:"Bu",
            img:"./images/planet5.png",
            text:"Boonji Lands in Los Angeles, CA.\n\n"+
            "Brendan will be putting up another Boonji in Malibu, in Summer 2022.\n\n"+
            "To celebrate, Boonji will be throwing a party in LA.\n\n"+
            "Anyone with a Boonji NFT can join!\n\n"+
            "More cool giveaways TBD."
        },

    ]

    return(
        <section className="phases-section">
            <Phase1 /> 
             {phases.map(info =>{
                return <PhaseUI 
                        key={info.id}
                        phase={info.phase}
                        planet={info.planet}
                        title={info.title}
                        text={info.text}
                        img={info.img}
                    />
                })
            } 
        </section>
    )
}

export default Phases;