import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import ReactPlayer from 'react-player'
import {isMobile} from 'react-device-detect';
import DialogContent from '@mui/material/DialogContent';
import { CircularProgress, Grid } from '@mui/material';
import {ethers} from 'ethers';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { lightGreen } from '@mui/material/colors';
import animation from '../../../assets/superBoonji/preview/animation.mp4'


import boonjiNFTAbi from '../../../utils/BoonjiNft.json';
import superBoonjiNFTAbi from '../../../utils/SuperBoonji.json';
import {getMintPrice} from '../../../utils/api'
import { uiCloseConfirmation, uiCloseConfirmation2, uiShowAlert, uiShowconfirmation3 } from '../../../reducers/uiReducer/uiActions';
import { superBoonjiConfirmTx, superBoonjiAddTx, superBoonjiApprovedForAll } from '../../../reducers/boonjiStudioReducer/boonjiStudioActions';
import { setUsedFifthTrait, verifyFifthTraitReservation } from '../../../reducers/fifthTrait/fifthTraitActions';

import './Confirmation2.css';
import { logError } from '../../../utils/errors';
export const Confirmation2 = () => {
    const dispatch = useDispatch();
    const [isPlayingAnimation, setIsPlayingAnimation] = useState(false)

    const {provider, address} = useSelector(state => state.walletProvider);
    
    const {showConfirmation2} = useSelector(state => state.ui);
    const {preview} = useSelector(state => state.boonjiStudio);
    const {boonjies} = preview;
    const [isApproved, setIsApproved] = useState(false);
    const [approvedTx, setApprovedTx] = useState(null);
    const [isMinted, setIsMinted] = useState(false);
    const [mintedTx, setMintedTx] = useState(null);
    
    const handleOnClose = () => {

    }

    const approveForAll = async () => {
        const signer = provider.getSigner();
        const boonjiNFT = new ethers.Contract(process.env.REACT_APP_BOONJI_PROJECT_ADDRESS, boonjiNFTAbi.abi, signer);
        
        let approvedQuery = false;
        try {
            approvedQuery = await boonjiNFT.isApprovedForAll(address, process.env.REACT_APP_SUPER_BOONJI_ADDRESS);
        } catch ( error ) {
            // TODO should we show we couldn't verify the contract?

        }

        if (approvedQuery){
            await setIsApproved(true)
            await burnAndMint();
            }
        else {
            try {
                const approvalTx = await boonjiNFT.setApprovalForAll(process.env.REACT_APP_SUPER_BOONJI_ADDRESS, true);
                await approvalTx.wait();
                setIsApproved(true);
                setApprovedTx(approvalTx.hash);
                try {
                    await dispatch(superBoonjiApprovedForAll());
                } catch ( error ) {
                    // is not so bad we couldn't record this status change, 
                    // mint transaction is more important
                }

                await burnAndMint()
            } catch ( error ) {
                dispatch(uiShowAlert('error', 'Oops', 'We have been unable to approve your request. Please refresh and retry.'))
                dispatch(uiCloseConfirmation2())
            }
        }

    }

    
    const burnAndMint = async () => {
        try {
            // we verify we still have the fifth trait
            await dispatch(verifyFifthTraitReservation());

            const response = await getMintPrice();
            const mintPrice = response.message;
            const signer = provider.getSigner();
            const superBoonjiNFT = new ethers.Contract(process.env.REACT_APP_SUPER_BOONJI_ADDRESS, superBoonjiNFTAbi.abi, signer);    
            const tokenIds = boonjies.map(boonji => (Number(boonji.tokenId)));
            
            // we are marking the fifth trait as used. We cannot risk wainting for transaction
            // to be signed. Because user might take too much time to sign, the fifth trait released
            // and someone else uses it.
            // we will have to implement a system to make expire the transaction.
            await dispatch(setUsedFifthTrait());

            const mintAndBurnTx = await superBoonjiNFT.burnAndMint(tokenIds, {value: mintPrice});
            try {
                // transactionn has been signed. We will store the tx hash in the db.
                // and try to confirm it from here. If it fails, we will continue since the event
                // listener will fix it

                // we start the fucking animation
                setIsPlayingAnimation(true);
                await dispatch(superBoonjiAddTx(mintAndBurnTx.hash));
                
                
            } catch ( error ){
                // ignore it
            }
            await mintAndBurnTx.wait();
            setMintedTx(mintAndBurnTx.hash)
            setIsMinted(true);

            // transaction was confirmed, we update the db
            try {
                await dispatch(superBoonjiConfirmTx(mintAndBurnTx.hash));
            } catch ( error ) {
                // ignore because listener will fix it?
            }
            setIsAllSet(true);
            
        } catch ( error ) {
            logError(error)
            dispatch(uiShowAlert('error', 'Oops', 'We have been unable to burn and mint your tokens. No tokens where burned.'))
            dispatch(uiCloseConfirmation2())
        }
    }
    useEffect(() => {
        if (showConfirmation2){
            approveForAll()
        }
    }, [showConfirmation2])

    const [isAnimationReady, setIsAnimationReady] = useState(false)
    
    const animationReady = () => {
        setIsAnimationReady(true)
    }

    const [isAllSet, setIsAllSet] = useState(false)

    useEffect(() => {
      if (isAllSet && isAnimationReady){
        // once all done, we reset the studio
        dispatch(uiCloseConfirmation2())
        dispatch(uiCloseConfirmation())
        dispatch(uiShowconfirmation3());
      }
    }, [isAllSet, isAnimationReady])
    
    
    
  return (
    <div >
        <Dialog
            open={showConfirmation2}
            onClose={handleOnClose}
            className='confirmation2'
        >
            <DialogContent className='confirmation2-background'>
                <Grid 
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        <div className='confirmation2-title'>
                            Sign all blockchain transactions to complete the process.
                        </div>
                    </Grid>
                    <Grid item className='confirmation2-approve'>
                        <div>
                            Approve us to burn your tokens: {' '}
                            {
                                (isApproved) ? (
                                    <DoneOutlineIcon sx={{ color: lightGreen[500] }}/>
                                ) : (
                                    <CircularProgress color='secondary'/>
                                )
                            }
                        </div>
                    </Grid>

                    <Grid item className='confirmation2-approve'>
                        <div className='confirmation2-mint'>
                            Burn and Mint: {' '}
                            {
                                (isMinted) ? (
                                    <DoneOutlineIcon sx={{ color: lightGreen[500] }}/>
                                ) : (
                                    <CircularProgress color='secondary'/>
                                )
                            } 
                        </div>
                    </Grid>

                    <Grid item>
                        <ReactPlayer
                            url={animation}
                            playing={isPlayingAnimation}
                            width={(isMobile) ? '250px' : '400px'}
                            onPause={animationReady}
                            onEnded={animationReady}
                            onError={animationReady}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    </div>
  )
}
