import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { TwitterShareButton, TwitterIcon} from 'react-share';
import { useDispatch, useSelector } from 'react-redux';
import { setShowSuccessDialog } from 'reducers/redemptionReducer/redemptionActions';
import { Box } from '@mui/system';

export const SuccessDialog = () => {
    const {successDialog} = useSelector(state => state.redemption);
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(setShowSuccessDialog(0));
    }
  return (
    <Dialog
        open={(successDialog === 0) ? false : true}
        onClose={handleClose}
    >
        <DialogTitle style={{backgroundColor:'black'}}>Redeemed</DialogTitle>
        <DialogContent style={{backgroundColor:'black'}}>
            <Typography>
                Congratulations! Redeemption completed.
            </Typography>
            
            <Typography>
                You will be notified by email.
            </Typography>
        </DialogContent>

        <DialogActions style={{backgroundColor:'black'}}>
            <TwitterShareButton
                url={`https://opensea.io/assets/matic/0xc1562f802cf761bfaf2a4e0ab8acae56781ea162/${successDialog}`}
                via='boonjiproject'
                title={'I just redeemed a physical piece from Brendan Murphy through the Boonji Project'}
                related={['@boonjiproject']}
            >
                <Typography>
                    <Box  display='inline-flex' alignItems={'center'}>
                            Share it &nbsp; <TwitterIcon size={40} round/> 
                    </Box>
                </Typography>
            </TwitterShareButton>
        </DialogActions>
    </Dialog>
  )
}

