/**
 * returns the address in short format
 * @param {*} address 
 */
export const shortenAddress = (address) => {
    return address.slice(0,5).concat('...').concat(address.slice(-5))
}

export const parseChainId = (chainId) => {
    switch (chainId){
        case '0x1': // ethereum main
            return 1;
        case '0x4': // ethereum rinkeby
            return 4;
        case '0x38': // Binance main
            return 38;
        case '0x61': // Binance testnet
            return 61;
        case '0x90': // Polygon main
            return 90;
        default:
            return 1;
    }
}