import React from "react";

import './RoadMap.css'

const RoadMap = props =>{
    return(
        <section className="road-map-section">
            <h2>Journey Through Joa</h2>
            <h3>THE BOONJI PROJECT ROADMAP</h3>
            <p className="road-map-p">We believe there is more to find as we explore the Joa Galaxy. What surprises will we find? Will we find other moons or other planets?  For now, this is the mission that Boonji Spaceman volunteered as he took his step into the unknown. We will all be there to greet him as he lands in Antigua at the festival. As you travel with the Boonji Project throughout the Joa Galaxy, you will get a passport stamp for each planet. You can unlock all kinds of rewards with your passport stamps.</p>
            <div className="quote-container">
                <p className="quote">Every artist’s dream is for their work to be seen by as many people in the world as possible. Most of my career has been spent in a studio by myself – making things, improving things, trying to bring my ideas to life.  I believe in the power of Boonji and the growing Boonji Communji.  Please join me as I make my vision and dream a reality.  Let’s put up as many Boonji Spacemen as we can, knowing that every time we do we are bringing positive energy and mojo to the world!</p>
                <p className="author">- Brendan Murphy</p>
            </div>
        </section>
    )
}
export default RoadMap;